export default  {
  name: "SFA Module",
  icon: "fas fa-database",
  path: "",
  submenu: [
    {
      name: "Call Plan",
      path: "/callplan",
      submenu: [
        {
          name: "Call Plan Pattern",
          path: "/pattern-list"
        },        
        {
          name: "Call Plan",
          path: "/list"
        },
      ]
    },
    {
      name: "Division",
      path: "/division",
      submenu: [
        {
          name: "Division Master",
          path: "/master"
        },
        {
          name: "Division Branch",
          path: "/branch"
        },
      ]      
    },
    {
      name: "Promo",
      path: "/promo",
    },
    {
      name: "Salesman",
      path: "/salesman",
      submenu: [
          {
              name: "Salesman Division",
              path: "/salesman-division"
          },
          {
              name: "Salesman Division Product",
              path: "/division-product"
          }
      ]
    },
  ]
}