export default {
  url: process.env.REACT_APP_BASE_URL_SFA,
  children: [
    {
      url: "api",
      children: [ 
        {
          url: "division_master",
          children: [
            {
              url: "list",
              method: "POST",
              name: "getListSfaDivisionMaster"
            },
            {
              url: "detail",
              method: "POST",
              name: "getSfaDivisionMasterById"
            },
            {
              url: "manage",
              method: "POST",
              name: "manageSfaDivisionMaster"
            },
            {
              url: "delete",
              method: "POST",
              name: "deleteSfaDivisionMasterById"
            }
          ]
        },
        {
          url: "division_branch",
          children: [
            {
              url: "list",
              method: "POST",
              name: "getListSfaDivisionBranch"
            },
            {
              url: "detail",
              method: "POST",
              name: "getSfaDivisionBranchById"
            },
            {
              url: "manage",
              method: "POST",
              name: "manageSfaDivisionBranch"
            },
            {
              url: "delete",
              method: "POST",
              name: "deleteSfaDivisionBranchById"
            }
          ]
        },
        {
          url: "promo_stores",
          children: [
            {
              url: "list",
              method: "POST",
              name: "getListPromoStore"
            },
            {
              url: "detail",
              method: "POST",
              name: "getPromoStoreById"
            },
            {
              url: "manage",
              method: "POST",
              name: "managePromoStore"
            },
            {
              url: "delete",
              method: "POST",
              name: "deletePromoStoreById"
            }
          ]
        },
        {
          url: "salesman_division",
          children: [
            {
              url: "list",
              name: "getListSalesmanDivision",
              method: "POST",
            },
            {
              url: "detail",
              name: "getSalesmanDivisionById",
              method: "POST",
            },            
            {
              url: "manage",
              name: "manageSalesmanDivision",
              method: "POST",
            },
            {
              url: "delete",
              name: "deleteSalesmanDivision",
              method: "POST",
            },
          ]
        },
        {
          url: "salesman_division_product",
          children: [
            {
              url: "list",
              name: "getListSalesmanDivisionProduct",
              method: "POST",
            },
            {
              url: "detail",
              name: "getSalesmanDivisionProductById",
              method: "POST",
            },            
            {
              url: "manage",
              name: "manageSalesmanDivisionProduct",
              method: "POST",
            },
            {
              url: "delete",
              name: "deleteSalesmanDivisionProduct",
              method: "POST",
            },            
          ]
        },
        {
          url: "map",
          children: [
            {
              url: "call_plan",
              children: [
                {
                  url: "export",
                  name: "exportListCallPlan",
                  method: "POST"
                },
                {
                  url: "generate",
                  name: "generateCallPlan",
                  method: "POST"
                },
                {
                  url: "import",
                  name: "importCallPlan",
                  method: "POST"
                },              
                {
                  url: "list",
                  name: "getListCallPlan",
                  method: "POST"
                },            
              ]
            },
            {
              url: "call_plan_pattern",
              children: [
                {
                  url: "export",
                  name: "exportListCallPlanPattern",
                  method: "POST"
                },
                {
                  url: "import",
                  name: "importCallPlanPattern",
                  method: "POST"
                },                        
                {
                  url: "list",
                  name: "getListCallPlanPattern",
                  method: "POST"
                },
                {
                  url: "manage",
                  name: "manageCallPlanPattern",
                  method: "POST"
                },
                {
                  url: "sample",
                  name: "downloadCallPlanPatternTemplate",
                  method: "GET"
                }              
              ]
            }            
          ]
        }        
      ]
    }
  ]
}