import { lazy } from "react";

import SFARoutes from './SFA';

//Config
const ListConfigSlocPlant = lazy(() =>
  import("../Pages/Config/SlocPlant/ListSlocPlant")
);

const ListConfigSlocCompany = lazy(() =>
  import("../Pages/Config/SlocCompany/ListSlocCompany")
);

const ListConfigCompany = lazy(() =>
  import("../Pages/Config/ConfigCompany/ListConfigCompany")
);

const ListConfigSlocCustomerGroup = lazy(() =>
  import("../Pages/Config/SlocCustomerGroup/ListSlocCustomerGroup")
);

const ListConfigSlocSalesman = lazy(() =>
  import("../Pages/Config/SlocSalesman/ListSlocSalesman")
);

const ListConfigProductBatch = lazy(() =>
  import("../Pages/Config/ProductBatch/ListProductBatch")
);

const ListConfigAutoSOToDO = lazy(() =>
  import("../Pages/Config/AutoSOToDO/ListAutoSOtoDO")
);

const ListConfigSOBlockSalesOrderProduct = lazy(() =>
  import("../Pages/Config/BlockSO/SalesOrderProduct/ListSalesOrderProduct")
);

const ListConfigSOBlockSalesOrderChannel = lazy(() =>
  import("../Pages/Config/BlockSO/SalesOrderChannel/ListSalesOrderChannel")
);

const ListConfigSOBlockSalesOrderCustomer = lazy(() =>
  import("../Pages/Config/BlockSO/SalesOrderCustomer/ListSalesOrderCustomer")
);

const ListConfigTaxRegulator = lazy(() =>
  import("../Pages/Config/TaxRegulator/ListTaxRegulator")
);

const ListConfigOrderTypeToSloc = lazy(() =>
  import("../Pages/Config/OrderTypeToSloc/ListOrderTypeToSloc")
);

const ListConfigMappingItemCategory = lazy(() =>
  import("../Pages/Config/ItemCategory/ListItemCategory")
);

const ListConfigMappingConditionType = lazy(() =>
  import("../Pages/Config/ConditionType/ListConditionType")
);

const ListConfigBranchLocation = lazy(() =>
  import("../Pages/Config/BranchLocation/ListBranchLocation")
);

const ListConfigMappingProductIntra = lazy(() =>
  import("../Pages/Config/MappingProductIntra/ListMappingProductIntra")
);

const ListConfigDocumentPrint = lazy(() =>
  import("../Pages/Config/DocumentConfig/ListDocumentConfig")
);

const SyncLogisticSAP = lazy(() =>
  import("../Pages/Config/SyncDataLogistic/MasterData")
);

const ScheduleSyncSAP = lazy(() =>
  import("../Pages/Config/ScheduleSyncSAP/MasterData")
);

const ExtractData = lazy(() => import("../Pages/Config/ExtractData/Branch"));
const ExtractDataDetailError = lazy(() =>
  import("../Pages/Config/ExtractData/DetailError")
);
const ListMonitoringUploadSAP = lazy(() =>
  import("../Pages/Config/MonitoringUploadSAP/ListMonitoringUploadSAP")
);
const MonitoringUploadSAPDetailError = lazy(() =>
  import("../Pages/Config/MonitoringUploadSAP/DetailError")
);

const PasswordExtract = lazy(() =>
  import("../Pages/Config/PasswordExtract/ListPasswordExtract")
);

// Master Data
//Base
const listMasterCompany = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Company/ListCompany")
);

const listMasterCountry = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Country/ListCountry")
);

const listMasterSalesOrg = lazy(() =>
  import("../Pages/MasterData/BaseMaster/SalesOrg/ListSalesOrg")
);

const listMasterSalesOffice = lazy(() =>
  import("../Pages/MasterData/BaseMaster/SalesOffice/ListSalesOffice")
);

const listMasterSalesGroup = lazy(() =>
  import("../Pages/MasterData/BaseMaster/SalesGroup/ListSalesGroup")
);

const listMasterChannel = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Channel/ListChannel")
);

const listMasterCustomerGroup = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup/ListCustomerGroup")
);

const listMasterCustomerGroup1 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup1/ListCustomerGroup1")
);

const listMasterCustomerGroup2 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup2/ListCustomerGroup2")
);

const listMasterCustomerGroup3 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup3/ListCustomerGroup3")
);

const listMasterCustomerGroup4 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup4/ListCustomerGroup4")
);

const listMasterCustomerGroup5 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup5/ListCustomerGroup5")
);

const listMasterRegion = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Region/ListRegion")
);

const listMasterDistrict = lazy(() =>
  import("../Pages/MasterData/BaseMaster/District/ListDistrict")
);

const listMasterBranch = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Branch/ListBranch")
);

const listMasterCurrency = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Currency/ListCurrency")
);

const listMasterTermOfPayment = lazy(() =>
  import("../Pages/MasterData/BaseMaster/TermOfPayment/ListTermOfPayment")
);

const ListPriceList = lazy(() =>
  import("../Pages/MasterData/BaseMaster/PriceList/ListPriceList")
);

const ListMasterConditionType = lazy(() =>
  import("../Pages/MasterData/BaseMaster/ConditionType/ListConditionType")
);

const listMasterSalesman = lazy(() =>
  import("../Pages/MasterData/Sales/Salesman/ListSalesman")
);

const listMasterSalesmanBranch = lazy(() =>
  import("../Pages/MasterData/Sales/SalesmanBranch/ListSalesmanBranch")
);

const listMasterSalesmanGroup = lazy(() =>
  import("../Pages/MasterData/Sales/SalesmanGroup/ListSalesmanGroup")
);

const listMasterCustomer = lazy(() =>
  import("../Pages/MasterData/Customer/Customer/ListCustomer")
);

const listMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/ListCustomer")
);

const detailMasterCustomer = lazy(() =>
  import("../Pages/MasterData/Customer/Customer/DetailCustomer")
);

const editMasterCustomer = lazy(() =>
  import("../Pages/MasterData/Customer/Customer/EditCustomer")
);

const createMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/CreateCustomer")
);

const editMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/EditCustomer")
);

const detailMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/DetailCustomer")
);

const listMasterApprovalCreditLimit = lazy(() =>
  import(
    "../Pages/MasterData/Customer/ApprovalCreditLimit/ListApprovalCreditLimit"
  )
);

const listMasterIncoTerm = lazy(() =>
  import("../Pages/MasterData/Customer/IncoTerm/ListIncoTerm")
);

const listMasterRules = lazy(() =>
  import("../Pages/MasterData/Customer/Rules/ListRules")
);

const listMasterCheckRule = lazy(() =>
  import("../Pages/MasterData/Customer/CheckRule/ListCheckRule")
);

const listMasterRiskClass = lazy(() =>
  import("../Pages/MasterData/Customer/RiskClass/ListRiskClass")
);

const listMasterPriceGroup = lazy(() =>
  import("../Pages/MasterData/Pricing/PriceGroup/ListPriceGroup")
);

const listMasterCustomerSalesOrg = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByCustomer/ListSalesOrg")
);

const listMasterPricingCustomer = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByCustomer/ListCustomer")
);

const listMasterPricingByCustomer = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByCustomer/ListPricingByCustomer")
);

const listMasterPriceGroupSalesOrg = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByPriceGroup/ListSalesOrg")
);

const listMasterPricingPriceGroup = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByPriceGroup/ListPriceGroup")
);

const listMasterPricingByPriceGroup = lazy(() =>
  import(
    "../Pages/MasterData/Pricing/PricingByPriceGroup/ListPricingByPriceGroup"
  )
);

//Transportaion
const listMasterDriver = lazy(() =>
  import("../Pages/MasterData/Transportation/Driver/ListDriver")
);

const listMasterVehicle = lazy(() =>
  import("../Pages/MasterData/Transportation/Vehicle/ListVehicle")
);

const listMasterVehicleType = lazy(() =>
  import("../Pages/MasterData/Transportation/VehicleType/ListVehicleType")
);

const listMasterTransportationGroup = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/TransportationGroup/ListTransportationGroup"
  )
);

const listMasterShippingCondition = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/ShippingCondition/ListShippingCondition"
  )
);

const listMasterModeOfTransportation = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/ModeOfTransportation/ListModeOfTransportation"
  )
);

const listMasterShippingType = lazy(() =>
  import("../Pages/MasterData/Transportation/ShippingType/ListShippingType")
);

const listMasterTransportationZone = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/TransportationZone/ListTransportationZone"
  )
);

const listMasterProductUom = lazy(() =>
  import("../Pages/MasterData/Product/Uom/ListUom")
);

const listMasterProductCategoryBrand = lazy(() =>
  import("../Pages/MasterData/Product/CategoryBrand/ListCategoryBrand")
);

const listMasterProductCategory = lazy(() =>
  import("../Pages/MasterData/Product/ProductCategory/ListProductCategory")
);

const listMasterProductBrand = lazy(() =>
  import("../Pages/MasterData/Product/ProductBrand/ListProductBrand")
);

const listMasterProductPrincipal = lazy(() =>
  import("../Pages/MasterData/Product/ProductPrincipal/ListProductPrincipal")
);

const listMasterProductBranch = lazy(() =>
  import("../Pages/MasterData/Product/ProductBranch/ListBranch")
);

const listMasterProductToBranch = lazy(() =>
  import("../Pages/MasterData/Product/ProductBranch/ListProductToBranch")
);

const listMasterProductDivision = lazy(() =>
  import("../Pages/MasterData/Product/Division/ListDivision")
);

const listMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/ListProduct")
);

const detailMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/DetailProduct")
);

const editMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/EditProduct")
);

const createMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/CreateProduct")
);

const listMasterGroupCategory = lazy(() =>
  import("../Pages/MasterData/Product/ProductToCategory/ListCategory")
);

const listMasterProductToGroupCategory = lazy(() =>
  import("../Pages/MasterData/Product/ProductToCategory/ListProduct")
);

const listMasterRoute = lazy(() =>
  import("../Pages/MasterData/Transportation/Route/ListRoute")
);

const listMasterRouteDetermination = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/RouteDetermination/ListRouteDetermination"
  )
);

const listMasterBranchTzone = lazy(() =>
  import("../Pages/MasterData/Transportation/TzoneToBranch/ListBranch")
);

const listMasterTzoneToBranch = lazy(() =>
  import("../Pages/MasterData/Transportation/TzoneToBranch/ListTzone")
);

const listCheckInconsistency = lazy(() =>
  import("../Pages/Config/CheckInconsistency/ListCheckInconsistency")
);

const listAuthUser = lazy(() => import("../Pages/Authorization/User/ListUser"));

const createAuthUser = lazy(() =>
  import("../Pages/Authorization/User/CreateUser")
);

const detailAuthUser = lazy(() =>
  import("../Pages/Authorization/User/DetailUser")
);

const editAuthUser = lazy(() => import("../Pages/Authorization/User/EditUser"));

const listAuthRequestPassword = lazy(() =>
  import("../Pages/Authorization/RequestPassword/ListRequestPassword")
);

const CompareLogistic = lazy(() =>
  import("../Pages/Report/CompareData/Logistic/list")
);

const CompareLogisticDetail = lazy(() =>
  import("../Pages/Report/CompareData/Logistic/detail")
);

const CompareSales = lazy(() =>
  import("../Pages/Report/CompareData/Sales/list")
);

const CompareSalesDetail = lazy(() =>
  import("../Pages/Report/CompareData/Sales/detail")
);

const CompareSoDo = lazy(() => import("../Pages/Report/CompareData/SoDo/list"));

const routesIT = [
  ...SFARoutes,
  {
    path: "/config-sloc-master",
    component: ListConfigSlocPlant,
  },

  {
    path: "/config-sloc-company",
    component: ListConfigSlocCompany,
  },

  {
    path: "/config-company",
    component: ListConfigCompany,
  },

  {
    path: "/config-sloc-customergroup",
    component: ListConfigSlocCustomerGroup,
  },

  {
    path: "/config-sloc-salesman",
    component: ListConfigSlocSalesman,
  },
  {
    path: "/config-mapping-item-category",
    component: ListConfigMappingItemCategory,
  },
  {
    path: "/config-mapping-condition-type",
    component: ListConfigMappingConditionType,
  },
  {
    path: "/config-branch-location",
    component: ListConfigBranchLocation,
  },

  {
    path: "/config-mapping-product-intra",
    component: ListConfigMappingProductIntra,
  },
  {
    path: "/config-order-type-to-sloc",
    component: ListConfigOrderTypeToSloc,
  },
  {
    path: "/config-batch-product",
    component: ListConfigProductBatch,
  },
  {
    path: "/config-auto-so-to-do",
    component: ListConfigAutoSOToDO,
  },

  {
    path: "/so-block/so-block-sales-order-product",
    component: ListConfigSOBlockSalesOrderProduct,
  },

  {
    path: "/so-block/so-block-sales-order-channel",
    component: ListConfigSOBlockSalesOrderChannel,
  },

  {
    path: "/so-block/so-block-sales-order-customer",
    component: ListConfigSOBlockSalesOrderCustomer,
  },

  {
    path: "/config-tax-regulator",
    component: ListConfigTaxRegulator,
  },

  {
    path: "/config-document-print",
    component: ListConfigDocumentPrint,
  },
  {
    path: "/sync-data-sap",
    component: SyncLogisticSAP,
  },

  {
    path: "/schedule-sync-sap",
    component: ScheduleSyncSAP,
  },

  {
    exact: true,
    path: "/extract-data",
    component: ExtractData,
  },

  {
    path: "/extract-data/:id",
    component: ExtractDataDetailError,
  },

  {
    exact: true,
    path: "/monitoring-upload-sap",
    component: ListMonitoringUploadSAP,
  },

  {
    path: "/monitoring-upload-sap/:id",
    component: MonitoringUploadSAPDetailError,
  },

  {
    path: "/password-extract",
    component: PasswordExtract,
  },

  {
    path: "/master-base/master-country",
    component: listMasterCountry,
  },

  {
    path: "/master-base/master-company",
    component: listMasterCompany,
  },

  {
    path: "/master-base/master-sales-org",
    component: listMasterSalesOrg,
  },

  {
    path: "/master-base/master-sales-office",
    component: listMasterSalesOffice,
  },

  {
    path: "/master-base/master-sales-group",
    component: listMasterSalesGroup,
  },

  {
    path: "/master-base/master-channel",
    component: listMasterChannel,
  },

  {
    path: "/master-base/master-customer-group-0",
    component: listMasterCustomerGroup,
  },

  {
    path: "/master-base/master-customer-group-1",
    component: listMasterCustomerGroup1,
  },

  {
    path: "/master-base/master-customer-group-2",
    component: listMasterCustomerGroup2,
  },

  {
    path: "/master-base/master-customer-group-3",
    component: listMasterCustomerGroup3,
  },

  {
    path: "/master-base/master-customer-group-4",
    component: listMasterCustomerGroup4,
  },

  {
    path: "/master-base/master-customer-group-5",
    component: listMasterCustomerGroup5,
  },

  {
    path: "/master-base/master-region",
    component: listMasterRegion,
  },

  {
    path: "/master-base/master-district",
    component: listMasterDistrict,
  },

  {
    path: "/master-base/master-branch",
    component: listMasterBranch,
  },

  {
    path: "/master-base/master-term-of-payment",
    component: listMasterTermOfPayment,
  },

  {
    path: "/master-base/master-currency",
    component: listMasterCurrency,
  },

  {
    path: "/master-base/master-price-list",
    component: ListPriceList,
  },

  {
    path: "/master-base/master-condition-type",
    component: ListMasterConditionType,
  },

  {
    path: "/master-salesman/master-salesman-id",
    component: listMasterSalesman,
  },

  {
    path: "/master-salesman/master-salesman-branch",
    component: listMasterSalesmanBranch,
  },

  {
    path: "/master-salesman/master-salesman-group",
    component: listMasterSalesmanGroup,
  },

  {
    exact: true,
    path: "/master-customer/master-customer-id",
    component: listMasterCustomer,
  },

  {
    exact: true,
    path: "/master-customer/master-customer-noo",
    component: listMasterCustomerNoo,
  },

  {
    path: "/master-customer/edit/:id",
    component: editMasterCustomer,
  },

  {
    path: "/master-customer/master-customer-id/:id",
    component: detailMasterCustomer,
  },

  {
    path: "/master-customer/master-customer-noo/create",
    component: createMasterCustomerNoo,
  },

  {
    path: "/master-customer/master-customer-noo/edit/:id",
    component: editMasterCustomerNoo,
  },

  {
    path: "/master-customer/master-customer-noo/:id",
    component: detailMasterCustomerNoo,
  },
  {
    exact: true,
    path: "/master-customer/master-customer-approval-credit-limit",
    component: listMasterApprovalCreditLimit,
  },

  {
    exact: true,
    path: "/master-customer/master-inco-term",
    component: listMasterIncoTerm,
  },

  {
    exact: true,
    path: "/master-customer/master-rules",
    component: listMasterRules,
  },

  {
    exact: true,
    path: "/master-customer/master-check-rule",
    component: listMasterCheckRule,
  },

  {
    exact: true,
    path: "/master-customer/master-risk-class",
    component: listMasterRiskClass,
  },

  {
    path: "/master-pricing/master-price-group",
    component: listMasterPriceGroup,
  },

  {
    exact: true,
    path: "/master-pricing/pricing-by-customer",
    component: listMasterCustomerSalesOrg,
  },
  {
    path: "/master-pricing/pricing-by-customer/:id/:company/",
    component: listMasterPricingCustomer,
  },

  {
    path: "/master-pricing/pricing-by-customer/:id/:company/:branch/:customer",
    component: listMasterPricingByCustomer,
  },

  {
    exact: true,
    path: "/master-pricing/pricing-by-price-group",
    component: listMasterPriceGroupSalesOrg,
  },

  {
    path: "/master-pricing/pricing-by-price-group/:id/:company/:pricegroup",
    component: listMasterPricingByPriceGroup,
  },
  {
    path: "/master-pricing/pricing-by-price-group/:id/:company/",
    component: listMasterPricingPriceGroup,
  },

  {
    exact: true,
    path: "/master-product/master-product-id",
    component: listMasterProduct,
  },

  {
    path: "/master-product/master-product-id/create",
    component: createMasterProduct,
  },
  {
    path: "/master-product/master-product-id/edit/:id",
    component: editMasterProduct,
  },
  {
    path: "/master-product/master-product-id/:id",
    component: detailMasterProduct,
  },

  {
    exact: true,
    path: "/product-grouping/:group/",
    component: listMasterGroupCategory,
  },

  {
    path: "/product-grouping/:group/:id/:name",
    component: listMasterProductToGroupCategory,
  },

  {
    exact: true,
    path: "/master-product/master-product-branch",
    component: listMasterProductBranch,
  },

  {
    path: "/master-product/master-product-branch/:id/:company",
    component: listMasterProductToBranch,
  },

  {
    path: "/master-product/master-product-categorybrand",
    component: listMasterProductCategoryBrand,
  },

  {
    path: "/master-product/master-product-division",
    component: listMasterProductDivision,
  },

  {
    path: "/master-product/master-product-uom",
    component: listMasterProductUom,
  },

  {
    path: "/master-product/master-product-category",
    component: listMasterProductCategory,
  },

  {
    path: "/master-product/master-product-brand",
    component: listMasterProductBrand,
  },

  {
    path: "/master-product/master-product-principal",
    component: listMasterProductPrincipal,
  },

  {
    path: "/master-transportation/master-driver",
    component: listMasterDriver,
  },

  {
    path: "/master-transportation/master-vehicle-id",
    component: listMasterVehicle,
  },

  {
    path: "/master-transportation/master-vehicle-type",
    component: listMasterVehicleType,
  },

  {
    path: "/master-transportation/master-transportation-group",
    component: listMasterTransportationGroup,
  },

  {
    path: "/master-transportation/master-shipping-condition",
    component: listMasterShippingCondition,
  },

  {
    path: "/master-transportation/master-mode-of-transportation",
    component: listMasterModeOfTransportation,
  },

  {
    path: "/master-transportation/master-shipping-type",
    component: listMasterShippingType,
  },

  {
    path: "/master-transportation/master-transport-zone",
    component: listMasterTransportationZone,
  },

  {
    path: "/master-transportation/master-route-id",
    component: listMasterRoute,
  },

  {
    path: "/master-transportation/master-route-determination",
    component: listMasterRouteDetermination,
  },

  {
    exact: true,
    path: "/master-transportation/master-tzone-to-branch",
    component: listMasterBranchTzone,
  },

  {
    path: "/master-transportation/master-tzone-to-branch/:id/:company",
    component: listMasterTzoneToBranch,
  },
  {
    path: "/check-inconsistency",
    component: listCheckInconsistency,
  },

  {
    exact: true,
    path: "/auth-user",
    component: listAuthUser,
  },

  {
    exact: true,
    path: "/auth-reset-password",
    component: listAuthRequestPassword,
  },

  {
    path: "/auth-user/create",
    component: createAuthUser,
  },
  {
    path: "/auth-user/edit/:id",
    component: editAuthUser,
  },
  {
    path: "/auth-user/:id",
    component: detailAuthUser,
  },
  {
    path: "/compare-data/sales",
    component: CompareSales,
    exact: true,
  },
  {
    path: "/compare-data/sales/:customer/:customerName/:orderType/:branch/:date/:apiType", // type EDS or SAP
    component: CompareSalesDetail,
  },
  {
    path: "/compare-data/logistic",
    component: CompareLogistic,
    exact: true,
  },
  {
    path: "/compare-data/logistic/:branch/:sloc/:movementType/:postingDate/:apiType", // type EDS or SAP
    component: CompareLogisticDetail,
  },
  {
    path: "/compare-data/so-do",
    component: CompareSoDo,
    exact: true,
  },
];

export default routesIT;
