/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import Cookies from "js-cookie";
import MainService from "./base";
import axios from "axios";
import moment from "moment";

const handleGeneralError = (error) => console.log("General Error", error);

const handleGETRequest = async (
  api,
  { ...param },
  { ...query },
  noAuth = false
) => {
  const {
    result: { body: bodyResult },
  } = await MainService(api)
    .doRequest({ params: { ...param }, query: { ...query }, noAuth })
    .then((res) => res)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: { result: bodyResult },
        errorJS: errorGeneral,
      };
    });
  // console.log(result, 'ini result')
  return { result: bodyResult };
};

const handlePOSTRawRequest = async (
  api,
  param,
  raw,
  noAuth = false,
  asFormData = false
) => {
  const {
    result: { body: bodyResult },
  } = await MainService(api)
    .doRequest({
      noAuth,
      params: { ...param },
      body: raw,
      headers: { "Content-Type": "application/json" },
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((res) => res)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: { result: bodyResult },
        errorJS: errorGeneral,
      };
    });

  return { result: bodyResult };
};

const handleGetDownload = async (urls, id) => {
  const instance = axios.create({ responseType: "blob" });
  try {
    const response = await instance.get(urls, {
      params: { id },
      headers: {
        "nds-token": Cookies.get("token"),
      },
    });

    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    const pdfUrl = window.URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute(
      "download",
      `Shipment-Detail-${id}-${moment(new Date()).format("DDMMYYYY")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  } catch (errorGeneral) {
    handleGeneralError(errorGeneral);
  }
};

const handlePOSTBlobRequest = async (api, param, raw) => {
  const instance = axios.create({ responseType: "blob" }); // <-- Important do not delete
  instance
    .post(
      process.env.REACT_APP_BASE_URL_SALES + "/sap/compare-stock",
      {
        branch_id: raw.branch_id,
        sloc_id: raw.sloc_id,
      },
      {
        headers: {
          "nds-token": Cookies.get("token"),
        },
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Compare-Stock" +
          "-" +
          raw.branch_id +
          "-" +
          raw.sloc_id +
          "-" +
          moment(new Date()).format("DDMMYYYY") +
          "-" +
          ".xlsx"
      );
      // link.setAttribute('download', "Compare Stock" + ".xlsx"); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    })
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
    });
};

const handleDELETERawRequest = async (
  api,
  param,
  raw,
  noAuth = false,
  asFormData = false,
  isContentTypeTextPlain = true
) => {
  console.log("headers", isContentTypeTextPlain);
  const {
    result: { body: bodyResult },
  } = await MainService(api)
    .doRequest({
      noAuth,
      method: "DELETE",
      headers: isContentTypeTextPlain
        ? { "content-type": "text/plain;charset=UTF-8" }
        : isContentTypeTextPlain,
      params: { ...param },
      body: raw,
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((res) => res)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: { result: bodyResult },
        errorJS: errorGeneral,
      };
    });

  return { result: bodyResult };
};

const handlePATCHRawRequest = async (
  api,
  param,
  raw,
  noAuth = false,
  asFormData = false,
  isContentTypeTextPlain = true
) => {
  console.log("headers PATCH here", isContentTypeTextPlain);
  const {
    result: { body: bodyResult },
  } = await MainService(api)
    .doRequest({
      noAuth,
      method: "PATCH",
      headers: isContentTypeTextPlain
        ? { "content-type": "text/plain;charset=UTF-8" }
        : isContentTypeTextPlain,
      params: { ...param },
      body: raw,
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((res) => res)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: { result: bodyResult },
        errorJS: errorGeneral,
      };
    });

  return { result: bodyResult };
};

const handlePUTRawRequest = async (
  api,
  param,
  raw,
  noAuth = false,
  asFormData = false
) => {
  const {
    result: { body: bodyResult },
  } = await MainService(api)
    .doRequest({
      noAuth,
      params: { ...param },
      method: "PUT",
      headers: { "content-type": "text/plain;charset=UTF-8," },
      body: raw,
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((res) => res)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: { result: bodyResult },
        errorJS: errorGeneral,
      };
    });

  return { result: bodyResult };
};

const handlePOSTRequest = async (
  api,
  param,
  query,
  noAuth = false,
  asFormData = false
) => {
  const formData = new FormData();
  let actualBody = { ...query };

  if (asFormData) {
    // eslint-disable-next-line
    for (const key in query) {
      formData.append(key, query[key]);
    }
    actualBody = formData;
  }

  const {
    result: { body: bodyResult },
  } = await MainService(api)
    .doRequest({
      noAuth,
      params: { ...param },
      body: actualBody,
      headers: { "Content-Type": "application/json" },
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((res) => res)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: { result: bodyResult },
        errorJS: errorGeneral,
      };
    });

  return { result: bodyResult };
};

// # BEGIN SFA MODULE
// # CALL PLAN
export const exportListCallPlan = (payload) =>
  handlePOSTRawRequest("exportListCallPlan", false, payload);

export const generateCallPlan = (payload) =>
  handlePOSTRawRequest("generateCallPlan", false, payload);

export const importCallPlan = (payload) =>
  handlePOSTRequest("importCallPlan", false, payload, false, true);

export const getListCallPlan = (payload) =>
  handlePOSTRawRequest("getListCallPlan", false, payload);

// # CALL PLAN PATTERN
export const downloadCallPlanPatternTemplate = (query) =>
  handleGETRequest("downloadCallPlanPatternTemplate", false, query);

export const exportListCallPlanPattern = (payload) =>
  handlePOSTRawRequest("exportListCallPlanPattern", false, payload);

export const getListCallPlanPattern = (payload) =>
  handlePOSTRawRequest("getListCallPlanPattern", false, payload);

export const importCallPlanPattern = (payload) =>
  handlePOSTRequest("importCallPlanPattern", false, payload, false, true);

export const manageCallPlanPattern = (payload) =>
  handlePOSTRawRequest("manageCallPlanPattern", false, payload);

// ### DIVISION MASTER
export const getListSfaDivisionMaster = (payload) =>
  handlePOSTRawRequest("getListSfaDivisionMaster", false, payload);

export const getSfaDivisionMasterById = (payload) =>
  handlePOSTRawRequest("getSfaDivisionMasterById", false, payload);

export const manageSfaDivisionMaster = (payload) =>
  handlePOSTRawRequest("manageSfaDivisionMaster", false, payload);

export const deleteSfaDivisionMasterById = (payload) =>
  handlePOSTRawRequest("deleteSfaDivisionMasterById", false, payload);

// ### DIVISION BRANCH
export const getListSfaDivisionBranch = (payload) =>
  handlePOSTRawRequest("getListSfaDivisionBranch", false, payload);

export const getSfaDivisionBranchById = (payload) =>
  handlePOSTRawRequest("getSfaDivisionBranchById", false, payload);

export const manageSfaDivisionBranch = (payload) =>
  handlePOSTRawRequest("manageSfaDivisionBranch", false, payload);

export const deleteSfaDivisionBranchById = (payload) =>
  handlePOSTRawRequest("deleteSfaDivisionBranchById", false, payload);

// ### PROMO STORE
export const getListPromoStore = (payload) =>
  handlePOSTRawRequest("getListPromoStore", false, payload);

export const getPromoStoreById = (payload) =>
  handlePOSTRawRequest("getPromoStoreById", false, payload);

export const managePromoStore = (payload) =>
  handlePOSTRawRequest("managePromoStore", false, payload);

export const deletePromoStoreById = (payload) =>
  handlePOSTRawRequest("deletePromoStoreById", false, payload);

// ### SALESMAN DIVISION
export const getListSalesmanDivision = (payload) =>
  handlePOSTRawRequest("getListSalesmanDivision", false, payload);

export const getSalesmanDivisionById = (payload) =>
  handlePOSTRawRequest("getSalesmanDivisionById", false, payload);

export const manageSalesmanDivision = (payload) =>
  handlePOSTRawRequest("manageSalesmanDivision", false, payload);

export const deleteSalesmanDivision = (payload) =>
  handlePOSTRawRequest("deleteSalesmanDivision", false, payload);

// ### SALESMAN DIVISION PRODUCT
export const getListSalesmanDivisionProduct = (payload) =>
  handlePOSTRawRequest("getListSalesmanDivisionProduct", false, payload);

export const getSalesmanDivisionProductById = (payload) =>
  handlePOSTRawRequest("getSalesmanDivisionProductById", false, payload);

export const manageSalesmanDivisionProduct = (payload) =>
  handlePOSTRawRequest("manageSalesmanDivisionProduct", false, payload);

export const deleteSalesmanDivisionProduct = (payload) =>
  handlePOSTRawRequest("deleteSalesmanDivisionProduct", false, payload);
// ### SALESMAN DIVISION PRODUCT

// # END SFA MODULE

export const loginUser = (
  { email, password } // Login User
) => handlePOSTRequest("loginUser", false, { email, password });

export const requestResetPassword = (payload) =>
  handlePOSTRequest("requestResetPassword", false, payload, false, true);

export const logoutUser = () => handlePOSTRequest("logoutUser");

export const changePassword = (id, payload) =>
  handlePOSTRequest("changePassword", { id }, payload);

export const getListCompany = (
  payload // List Company
) => handlePOSTRawRequest("getListCompany", false, payload);

export const getListChannel = (
  payload // List Company
) => handlePOSTRawRequest("getListChannel", false, payload);

export const getListSalesOffice = (
  payload // List Company
) => handlePOSTRawRequest("getListSalesOffice", false, payload);

export const getListSalesDivision = (
  payload // List Company
) => handlePOSTRawRequest("getListSalesDivision", false, payload);

export const getListSalesDistrict = (
  payload // List Company
) => handlePOSTRawRequest("getListSalesDistrict", false, payload);

export const getListCustomerGroup = (
  payload // List Company
) => handlePOSTRawRequest("getListCustomerGroup", false, payload);

export const getListSalesmanGroup = (
  payload // List Company
) => handlePOSTRawRequest("getListSalesmanGroup", false, payload);

export const getListReasonCancelSales = (
  payload //List Reason Cancel Sales Module
) => handlePOSTRawRequest("getListReasonCancelSales", false, payload);

export const getListSalesGroup = (
  payload // List Company
) => handlePOSTRawRequest("getListSalesGroup", false, payload);

export const getListPriceGroup = (
  payload // List Company
) => handlePOSTRawRequest("getListPriceGroup", false, payload);

export const getListPrice = (
  payload // List Company
) => handlePOSTRawRequest("getListPrice", false, payload);

export const getListItemStatus = (
  payload // List Item Status
) => handlePOSTRawRequest("getListItemStatus", false, payload);

export const createItemStatus = (
  payload // Create Item Status
) => handlePOSTRawRequest("createItemStatus", false, payload);

export const editItemStatus = ({
  id,
  payload, // Edit Item Status
}) => handlePUTRawRequest("editItemStatus", { id }, payload);

//Master Data
export const getListSalesOrg = (
  payload // List Sales Org
) => handlePOSTRawRequest("getListSalesOrg", false, payload);

export const getListPlant = (
  payload // List Plant
) => handlePOSTRawRequest("getListPlant", false, payload);

export const getListDocType = (
  payload // List Document type
) => handlePOSTRawRequest("getListDocType", false, payload);

export const getListProduct = (
  payload // List Product
) => handlePOSTRawRequest("getListProduct", false, payload);

export const getDetailProduct = (
  { id, price_date } // Get Detail Product
) => handleGETRequest("getDetailProduct", { id }, { price_date });

export const getBulkDetailProduct = (
  payload // Get Bulk Detail Product
) => handlePOSTRawRequest("getBulkDetailProduct", false, payload);

export const getDetailProductWithPriceGroup = (
  { id, price_date, customer_id, sales_org_id, doc_type } // Get Detail Product
) =>
  handleGETRequest(
    "getDetailProductWithPriceGroup",
    { id },
    { price_date, customer_id, sales_org_id, doc_type }
  );

export const getBulkDetailProductWithPriceGroup = (
  payload // Get Bulk Detail Product
) => handlePOSTRawRequest("getBulkDetailProductWithPriceGroup", false, payload);

export const getListCustomer = (
  payload // Get List Customer
) => handlePOSTRawRequest("getListCustomer", false, payload);

export const getListCustomerLogistic = (
  payload // Get List Customer
) => handlePOSTRequest("getListCustomerLogistic", false, payload);

export const getListCustomerLogistic2 = (
  payload // Get List Customer
) => handlePOSTRequest("getListCustomerLogistic2", false, payload);

export const getDetailCustomerLogistic = (
  payload // Get Detail Customer
) =>
  handlePOSTRequest("getDetailCustomerLogistic", false, payload, false, true);

export const createCustomerLogistic = (
  payload // create customer
) => handlePOSTRequest("createCustomerLogistic", false, payload);

export const editCustomerLogistic = (
  id,
  payload // edit customer
) => handlePOSTRequest("editCustomerLogistic", { id }, payload);

export const updateCreditLimitCustomer = (
  id,
  payload // edit credit limit customer
) => handlePOSTRequest("updateCreditLimitCustomer", { id }, payload);

export const getListApprovalCreditLimit = (
  payload // Get List Approval Credit Limit
) => handlePOSTRequest("getListApprovalCreditLimit", false, payload);

export const getListSalesmanLogistic = (
  payload // Get List Salesman
) => handlePOSTRequest("getListSalesmanLogistic", false, payload);

export const getDetailSalesmanLogistic = (
  payload // Get Detail Salesman
) =>
  handlePOSTRequest("getDetailSalesmanLogistic", false, payload, false, true);

export const editSalesmanLogistic = (
  payload // edit Salesman
) => handlePOSTRequest("editSalesmanLogistic", false, payload, false, true);

export const statusSalesmanLogistic = (
  payload // status Salesman
) => handlePOSTRequest("statusSalesmanLogistic", false, payload, false, true);

export const createSalesmanLogistic = (
  payload // create Salesman
) => handlePOSTRequest("createSalesmanLogistic", false, payload, false, true);

export const approveCustomerLogistic = (
  payload // approve Customer
) => handlePOSTRequest("approveCustomerLogistic", false, payload, false, true);

export const approvalUpdateCreditLimitCustomer = (
  payload // approve update credit limit Customer
) =>
  handlePOSTRequest(
    "approvalUpdateCreditLimitCustomer",
    false,
    payload,
    false,
    true
  );

export const getDetailCustomer = (
  { id } // Get Detail Customer
) => handleGETRequest("getDetailCustomer", { id });

export const getListSalesman = (
  payload // Get List Salesman
) => handlePOSTRawRequest("getListSalesman", false, payload);

export const getDetailSalesman = (
  { id } // Get Detail Salesman
) => handleGETRequest("getDetailSalesman", { id });

export const getListRoute = (
  payload // Get List Shipment
) => handlePOSTRawRequest("getListRoute", false, payload);

export const getListRouteDetermination = (
  payload // Get List Route Master Data
) => handlePOSTRawRequest("getListRouteDetermination", false, payload);

export const getListTransportationZone = (
  payload // Get List tzone
) => handlePOSTRawRequest("getListTransportationZone", false, payload);

export const getListVehicle = (
  payload // Get List vehicle
) => handlePOSTRawRequest("getListVehicle", false, payload);

export const getListVehicleLogistic = (payload) =>
  handlePOSTRequest("getListVehicleLogistic", false, payload);

export const editVehicleLogistic = (id, payload) =>
  handlePOSTRequest("editVehicleLogistic", { id }, payload, false, true);

export const createVehicleLogistic = (payload) =>
  handlePOSTRequest("createVehicleLogistic", false, payload, false, true);

export const updateStatusVehicleLogistic = (payload) =>
  handlePOSTRequest("updateStatusVehicleLogistic", false, payload, false, true);

export const getListVehicleType = (payload) =>
  handlePOSTRequest("getListVehicleType", false, payload);

export const editVehicleType = (id, payload) =>
  handlePOSTRequest("editVehicleType", { id }, payload, false, true);

export const createVehicleType = (payload) =>
  handlePOSTRequest("createVehicleType", false, payload, false, true);

export const getListTransportationGroup = (payload) =>
  handlePOSTRequest("getListTransportationGroup", false, payload);

export const exportListTransportationGroup = (payload) =>
  handlePOSTRequest("exportListTransportationGroup", false, payload);

export const createTransportationGroup = (payload) =>
  handlePOSTRequest("createTransportationGroup", false, payload, false, true);

export const editTransportationGroup = (payload) =>
  handlePOSTRequest("editTransportationGroup", false, payload, false, true);

export const statusTransportationGroup = (payload) =>
  handlePOSTRequest("statusTransportationGroup", false, payload, false, true);

export const getListShippingCondition = (payload) =>
  handlePOSTRequest("getListShippingCondition", false, payload);

export const exportListShippingCondition = (payload) =>
  handlePOSTRequest("exportListShippingCondition", false, payload);

export const createShippingCondition = (payload) =>
  handlePOSTRequest("createShippingCondition", false, payload, false, true);

export const editShippingCondition = (payload) =>
  handlePOSTRequest("editShippingCondition", false, payload, false, true);

export const statusShippingCondition = (payload) =>
  handlePOSTRequest("statusShippingCondition", false, payload, false, true);

export const getListModeOfTransportation = (payload) =>
  handlePOSTRequest("getListModeOfTransportation", false, payload);

export const exportListModeOfTransportation = (payload) =>
  handlePOSTRequest("exportListModeOfTransportation", false, payload);

export const createModeOfTransportation = (payload) =>
  handlePOSTRequest("createModeOfTransportation", false, payload, false, true);

export const editModeOfTransportation = (payload) =>
  handlePOSTRequest("editModeOfTransportation", false, payload, false, true);

export const statusModeOfTransportation = (payload) =>
  handlePOSTRequest("statusModeOfTransportation", false, payload, false, true);

export const getListShippingType = (payload) =>
  handlePOSTRequest("getListShippingType", false, payload);

export const exportListShippingType = (payload) =>
  handlePOSTRequest("exportListShippingType", false, payload);

export const createShippingType = (payload) =>
  handlePOSTRequest("createShippingType", false, payload, false, true);

export const editShippingType = (payload) =>
  handlePOSTRequest("editShippingType", false, payload, false, true);

export const statusShippingType = (payload) =>
  handlePOSTRequest("statusShippingType", false, payload, false, true);

export const getListMasterRoute = (payload) =>
  handlePOSTRequest("getListMasterRoute", false, payload);

export const exportListMasterRoute = (payload) =>
  handlePOSTRequest("exportListMasterRoute", false, payload);

export const createMasterRoute = (payload) =>
  handlePOSTRequest("createMasterRoute", false, payload, false, true);

export const editMasterRoute = (payload) =>
  handlePOSTRequest("editMasterRoute", false, payload, false, true);

export const deleteMasterRoute = (payload) =>
  handlePOSTRequest("deleteMasterRoute", false, payload, false, true);

export const getListMasterRouteDetermination = (payload) =>
  handlePOSTRequest("getListMasterRouteDetermination", false, payload);

export const exportListMasterRouteDetermination = (payload) =>
  handlePOSTRequest("exportListMasterRouteDetermination", false, payload);

export const createMasterRouteDetermination = (payload) =>
  handlePOSTRequest(
    "createMasterRouteDetermination",
    false,
    payload,
    false,
    true
  );

export const editMasterRouteDetermination = (id, payload) =>
  handlePOSTRequest(
    "editMasterRouteDetermination",
    { id },
    payload,
    false,
    true
  );

export const deleteMasterRouteDetermination = (payload) =>
  handlePOSTRequest(
    "deleteMasterRouteDetermination",
    false,
    payload,
    false,
    true
  );

export const updateStatusVehicleType = (payload) =>
  handlePOSTRequest("updateStatusVehicleType", false, payload, false, true);

export const getListTzoneToBranch = (payload) =>
  handlePOSTRequest("getListTzoneToBranch", false, payload);

export const addTzoneToBranch = (payload) =>
  handlePOSTRequest("addTzoneToBranch", false, payload);

export const removeTzoneToBranch = (payload) =>
  handlePOSTRequest("removeTzoneToBranch", false, payload);

export const getListCurrency = (payload) =>
  handlePOSTRequest("getListCurrency", false, payload);

export const getListIncoterm = (payload) =>
  handlePOSTRequest("getListIncoterm", false, payload);

export const exportListIncoterm = (payload) =>
  handlePOSTRequest("exportListIncoterm", false, payload);

export const createIncoterm = (payload) =>
  handlePOSTRequest("createIncoterm", false, payload, false, true);

export const editIncoterm = (payload) =>
  handlePOSTRequest("editIncoterm", false, payload, false, true);

export const deleteIncoterm = (payload) =>
  handlePOSTRequest("deleteIncoterm", false, payload, false, true);

export const getListTop = (payload) =>
  handlePOSTRequest("getListTop", false, payload);

export const getListMop = (payload) =>
  handlePOSTRequest("getListMop", false, payload);

export const getListPricingByPriceGroup = (payload) =>
  handlePOSTRequest("getListPricingByPriceGroup", false, payload);

export const addPricingByPriceGroup = (payload) =>
  handlePOSTRequest("addPricingByPriceGroup", false, payload);

export const editPricingByPriceGroup = (payload) =>
  handlePOSTRequest("editPricingByPriceGroup", false, payload);

export const activeInactivePricingByPriceGroup = (payload) =>
  handlePOSTRequest(
    "activeInactivePricingByPriceGroup",
    false,
    payload,
    false,
    true
  );

export const getListPriceGroupID = (payload) =>
  handlePOSTRequest("getListPriceGroupID", false, payload);

export const getListPricingByPriceCustomer = (payload) =>
  handlePOSTRequest("getListPricingByPriceCustomer", false, payload);

export const getListPricingCustomer = (payload) =>
  handlePOSTRequest("getListPricingCustomer", false, payload);

export const addPricingByPriceCustomer = (payload) =>
  handlePOSTRequest("addPricingByPriceCustomer", false, payload);

export const editPricingByPriceCustomer = (payload) =>
  handlePOSTRequest("editPricingByPriceCustomer", false, payload);

export const activeInactivePricingByPriceCustomer = (payload) =>
  handlePOSTRequest(
    "activeInactivePricingByPriceCustomer",
    false,
    payload,
    false,
    true
  );

export const getListRiskClass = (payload) =>
  handlePOSTRequest("getListRiskClass", false, payload);

export const exportListRiskClass = (payload) =>
  handlePOSTRequest("exportListRiskClass", false, payload);

export const createRiskClass = (payload) =>
  handlePOSTRequest("createRiskClass", false, payload, false, true);

export const editRiskClass = (payload) =>
  handlePOSTRequest("editRiskClass", false, payload, false, true);

export const deleteRiskClass = (payload) =>
  handlePOSTRequest("deleteRiskClass", false, payload, false, true);

export const getListRules = (payload) =>
  handlePOSTRequest("getListRules", false, payload);

export const exportListRules = (payload) =>
  handlePOSTRequest("exportListRules", false, payload);

export const createRules = (payload) =>
  handlePOSTRequest("createRules", false, payload, false, true);

export const editRules = (payload) =>
  handlePOSTRequest("editRules", false, payload, false, true);

export const deleteRules = (payload) =>
  handlePOSTRequest("deleteRules", false, payload, false, true);

export const getListCheckRule = (payload) =>
  handlePOSTRequest("getListCheckRule", false, payload);

export const exportListCheckRule = (payload) =>
  handlePOSTRequest("exportListCheckRule", false, payload);

export const createCheckRule = (payload) =>
  handlePOSTRequest("createCheckRule", false, payload, false, true);

export const editCheckRule = (payload) =>
  handlePOSTRequest("editCheckRule", false, payload, false, true);

export const deleteCheckRule = (payload) =>
  handlePOSTRequest("deleteCheckRule", false, payload, false, true);

// Sales Quotation
export const createQuotation = (payload) =>
  handlePOSTRawRequest("createQuotation", false, payload);

export const saveAsDraftQuotation = (payload) =>
  handlePOSTRawRequest("saveAsDraftQuotation", false, payload);

export const publishDraftQuotation = ({ id }) =>
  handlePUTRawRequest("publishDraftQuotation", { id });

export const getListQuotation = (payload) =>
  handlePOSTRawRequest("getListQuotation", false, payload);

export const exportListQuotation = (payload) =>
  handlePOSTRawRequest("exportListQuotation", false, payload);

export const getDetailQuotation = ({ id }) =>
  handleGETRequest("getDetailQuotation", { id });

export const cancelQuotation = (payload) =>
  handleDELETERawRequest("cancelQuotation", false, payload);

export const getDocumentFlow = ({ id }) =>
  handleGETRequest("getDocumentFlow", { id });

export const getDocumentFlowSQ = ({ id }) =>
  handleGETRequest("getDocumentFlowSQ", { id });

export const getDocumentNpl = ({ id }) =>
  handleGETRequest("getDocumentNpl", { id });

export const editQuotation = ({ id, payload }) =>
  handlePUTRawRequest("editQuotation", { id }, payload);

// Sales Order
export const getListSalesOrder = (payload) =>
  handlePOSTRawRequest("getListSalesOrder", false, payload);

export const getListApprovalSalesOrder = (payload) =>
  handlePOSTRawRequest("getListApprovalSalesOrder", false, payload);

export const getDetailSalesOrder = ({ id }) =>
  handleGETRequest("getDetailSalesOrder", { id });

export const cancelSalesOrder = (payload) =>
  handleDELETERawRequest("cancelSalesOrder", false, payload);

export const pushSalesOrder = (payload) =>
  handlePOSTRawRequest("pushSalesOrder", false, payload);

export const approveSalesOrder = ({ id }) =>
  handlePUTRawRequest("approveSalesOrder", { id });

export const rejectSalesOrder = ({ id }) =>
  handlePUTRawRequest("rejectSalesOrder", { id });

// Delivery Order
export const getListDeliveryOrder = (payload) =>
  handlePOSTRawRequest("getListDeliveryOrder", false, payload);

export const getListDeliveryOrderShipment = (payload) =>
  handlePOSTRawRequest("getListDeliveryOrderShipment", false, payload);

export const getDetailDeliveryOrder = ({ id }) =>
  handleGETRequest("getDetailDeliveryOrder", { id });

export const cancelDeliveryOrder = (payload) =>
  handleDELETERawRequest("cancelDeliveryOrder", false, payload);

export const cancelDeliveryOrderBulk = (payload) =>
  handleDELETERawRequest("cancelDeliveryOrderBulk", false, payload);

// Shipment
export const getListShipment = (payload) =>
  handlePOSTRawRequest("getListShipment", false, payload);

// Collection
export const getListCollection = (payload) =>
  handlePOSTRawRequest("getListCollection", false, payload);

// Undelivered
export const getListUndelivered = (payload) =>
  handlePOSTRawRequest("getListUndelivered", false, payload);

export const createShipment = (payload) =>
  handlePOSTRawRequest("createShipment", false, payload);

export const editShipment = ({ id, payload }) =>
  handlePUTRawRequest("editShipment", { id }, payload);

export const getDetailShipment = ({ id }) =>
  handleGETRequest("getDetailShipment", { id });

export const downloadShipment = (url, id) => handleGetDownload(url, id);

export const getDetailUndelivered = ({ id }) =>
  handleGETRequest("getDetailUndelivered", { id });

export const getDetailBPB = ({ id }) =>
  handleGETRequest("getDetailBPB", { id });

export const getDetailBPBbulk = (payload) =>
  handlePOSTRawRequest("getDetailBPBbulk", false, payload);

export const printBPBbulk = (payload) =>
  handlePOSTRawRequest("printBPBbulk", false, payload);

export const printBPB = ({ id }) => handleGETRequest("printBPB", { id });

export const getDetailBSTF = ({ id }) =>
  handleGETRequest("getDetailBSTF", { id });

export const printBSTF = ({ id }) => handleGETRequest("printBSTF", { id });

export const getDetailHPH = ({ id }) =>
  handleGETRequest("getDetailHPH", { id });

export const printHPH = ({ id }) => handleGETRequest("printHPH", { id });

export const cancelShipment = (payload) => {
  // const headersOptions = true;
  return handleDELETERawRequest(
    "cancelShipment",
    false,
    payload,
    false,
    false,
    false
  );
};

export const retryPGI = (payload) => {
  return handlePATCHRawRequest("retryPGI", false, payload, false, false, false);
};

export const finishConfirmDeliveredItem = ({ id }) =>
  handlePUTRawRequest("finishConfirmDeliveredItem", { id });

export const updateConfirmDeliveredItem = ({ id, payload }) =>
  handlePUTRawRequest("updateConfirmDeliveredItem", { id }, payload);

export const getConfirmDeliveredItem = ({ id }) =>
  handleGETRequest("getConfirmDeliveredItem", { id });

export const createGoodsIssue = (payload) =>
  handlePOSTRawRequest("createGoodsIssue", false, payload);

export const createBulkGoodsIssue = (payload) =>
  handlePOSTRawRequest("createBulkGoodsIssue", false, payload);

export const createGoodsReturn = (payload) =>
  handlePOSTRawRequest("createGoodsReturn", false, payload);

export const createBulkGoodsReturn = (payload) =>
  handlePOSTRawRequest("createBulkGoodsReturn", false, payload);

export const cancelGoodsReturn = ({ id, payload }) =>
  handlePUTRawRequest("cancelGoodsReturn", { id }, payload);

export const createSwapDO = (payload) =>
  handlePOSTRawRequest("createSwapDO", false, payload);

export const createBulkSwapDO = (payload) =>
  handlePOSTRawRequest("createBulkSwapDO", false, payload);

export const cancelSwapDO = ({ id, payload }) =>
  handlePUTRawRequest("cancelSwapDO", { id }, payload);

// Billing
export const getListBilling = (payload) =>
  handlePOSTRawRequest("getListBilling", false, payload);

export const getDetailBilling = ({ id }) =>
  handleGETRequest("getDetailBilling", { id });

export const getInvoiceBilling = ({ id }) =>
  handleGETRequest("getInvoiceBilling", { id });

export const printInvoiceBillingbulk = (payload) =>
  handlePOSTRawRequest("printInvoiceBillingbulk", false, payload);

export const printInvoiceBilling = ({ id }) =>
  handleGETRequest("printInvoiceBilling", { id });

export const getDetailInvoiceBulk = (payload) =>
  handlePOSTRawRequest("getDetailInvoiceBulk", false, payload);

//Collection
export const getDetailCollection = ({ id }) =>
  handleGETRequest("getDetailCollection", { id });

export const createCollection = (payload) =>
  handlePOSTRawRequest("createCollection", false, payload);

export const confirmUndelivered = (payload) =>
  handlePOSTRawRequest("confirmUndelivered", false, payload);

//Logistik
//master data
export const getListSloc = (
  { branch_id, type } // get list stock realTime
) => handlePOSTRequest("getListSloc", false, { branch_id, type });

export const getListBranchLogistic = (payload) =>
  handlePOSTRequest("getListBranchLogistic", false, payload);

export const editBranch = (id, payload) =>
  handlePOSTRequest("editBranch", { id }, payload);

export const createBranch = (payload) =>
  handlePOSTRequest("createBranch", false, payload);

export const statusBranch = (payload) =>
  handlePOSTRequest("statusBranch", false, payload, false, true);

export const getDetailBranch = (payload) =>
  handlePOSTRequest("getDetailBranch", false, payload);

export const getListBranchMaster = (payload) =>
  handlePOSTRequest("getListBranchMaster", false, payload);

export const getListBranchLocation = (payload) =>
  handlePOSTRequest("getListBranchLocation", false, payload);

export const createBranchLocation = (payload) =>
  handlePOSTRequest("createBranchLocation", false, payload, false, true);

export const deleteBranchLocation = (payload) =>
  handlePOSTRequest("deleteBranchLocation", false, payload, false, true);

export const getListMappingProductIntra = (payload) =>
  handlePOSTRequest("getListMappingProductIntra", false, payload);

export const createMappingProductIntra = (payload) =>
  handlePOSTRequest("createMappingProductIntra", false, payload, false, true);

export const editMappingProductIntra = (id, payload) =>
  handlePOSTRequest("editMappingProductIntra", { id }, payload, false, true);

export const deleteMappingProductIntra = (payload) =>
  handlePOSTRequest("deleteMappingProductIntra", false, payload, false, true);

//Master Data Company
export const getListCompanyLogistic = (
  payload // List Company
) => handlePOSTRawRequest("getListCompanyLogistic", false, payload);

export const createCompany = (payload) =>
  handlePOSTRequest("createCompany", false, payload);

export const editCompany = (payload) =>
  handlePOSTRequest("editCompany", false, payload);

export const deleteCompany = (payload) =>
  handlePOSTRequest("deleteCompany", false, payload, false, true);

export const getListProductLogistic = (
  payload // List Product
) => handlePOSTRawRequest("getListProductLogistic", false, payload);

export const getConvertionProductUom = (payload) =>
  handlePOSTRequest("getConvertionProductUom", false, payload);

export const getDetailProductLogistic = (
  payload // List Product
) => handlePOSTRequest("getDetailProductLogistic", false, payload, false, true);

export const createProduct = (
  payload // create Product
) => handlePOSTRequest("createProduct", false, payload);

export const editProduct = (
  payload // edit Product
) => handlePOSTRequest("editProduct", false, payload);

export const statusProduct = (
  payload // status Product
) => handlePOSTRequest("statusProduct", false, payload, false, true);

export const getListGroupPrincipal = (
  payload // List Group Principal
) => handlePOSTRequest("getListGroupPrincipal", false, payload);

export const createGroupPrincipal = (
  payload // Create Group Principal
) => handlePOSTRequest("createGroupPrincipal", false, payload);

export const deleteGroupPrincipal = (
  payload // Delete Group Principal
) => handlePOSTRequest("deleteGroupPrincipal", false, payload, false, true);

export const editGroupPrincipal = (
  id,
  payload // edit Group Principal
) => handlePOSTRequest("editGroupPrincipal", { id }, payload);

export const getListProductByPrincipal = (
  payload // List Product by Principal
) => handlePOSTRawRequest("getListProductByPrincipal", false, payload);

export const removeProductByPrincipal = (
  payload // remove Product by Principal
) => handlePOSTRequest("removeProductByPrincipal", false, payload, false, true);

export const addProductByPrincipal = (
  payload // add Product by Principal
) => handlePOSTRawRequest("addProductByPrincipal", false, payload);

export const getListGroupBrand = (
  payload // List Group Brand
) => handlePOSTRequest("getListGroupBrand", false, payload);

export const getListProductByBrand = (
  payload // List Product
) => handlePOSTRawRequest("getListProductByBrand", false, payload);

export const getListGroupCategory = (
  payload // List Group Category
) => handlePOSTRequest("getListGroupCategory", false, payload);

export const getListProductByCategory = (
  payload // List Product Category
) => handlePOSTRawRequest("getListProductByCategory", false, payload);

export const getListGroupNPL = (
  payload // List Group NPL
) => handlePOSTRequest("getListGroupNPL", false, payload);

export const createGroupNPL = (
  payload // Create Group NPL
) => handlePOSTRequest("createGroupNPL", false, payload);

export const deleteGroupNPL = (
  payload // Delete Group NPL
) => handlePOSTRequest("deleteGroupNPL", false, payload, false, true);

export const editGroupNPL = (
  id,
  payload // edit Group NPL
) => handlePOSTRequest("editGroupNPL", { id }, payload);

export const getListProductByNPL = (
  payload // List Product by NPL
) => handlePOSTRawRequest("getListProductByNPL", false, payload);

export const removeProductByNPL = (
  payload // remove Product by NPL
) => handlePOSTRequest("removeProductByNPL", false, payload, false, true);

export const addProductByNPL = (
  payload // add Product by NPL
) => handlePOSTRawRequest("addProductByNPL", false, payload);

export const getListGroupRetailPrice = (
  payload // List Group RetailPrice
) => handlePOSTRequest("getListGroupRetailPrice", false, payload);

export const createGroupRetailPrice = (
  payload // Create Group RetailPrice
) => handlePOSTRequest("createGroupRetailPrice", false, payload);

export const deleteGroupRetailPrice = (
  payload // Delete Group RetailPrice
) => handlePOSTRequest("deleteGroupRetailPrice", false, payload, false, true);

export const editGroupRetailPrice = (
  id,
  payload // edit Group RetailPrice
) => handlePOSTRequest("editGroupRetailPrice", { id }, payload);

export const getListProductByRetailPrice = (
  payload // List Product by RetailPrice
) => handlePOSTRawRequest("getListProductByRetailPrice", false, payload);

export const removeProductByRetailPrice = (
  payload // remove Product by RetailPrice
) =>
  handlePOSTRequest("removeProductByRetailPrice", false, payload, false, true);

export const addProductByRetailPrice = (
  payload // add Product by RetailPrice
) => handlePOSTRawRequest("addProductByRetailPrice", false, payload);

export const getListGroupGramation = (
  payload // List Group Gramation
) => handlePOSTRequest("getListGroupGramation", false, payload);

export const createGroupGramation = (
  payload // Create Group Gramation
) => handlePOSTRequest("createGroupGramation", false, payload);

export const deleteGroupGramation = (
  payload // Delete Group Gramation
) => handlePOSTRequest("deleteGroupGramation", false, payload, false, true);

export const editGroupGramation = (
  id,
  payload // edit Group Gramation
) => handlePOSTRequest("editGroupGramation", { id }, payload);

export const getListProductByGramation = (
  payload // List Product by Gramation
) => handlePOSTRawRequest("getListProductByGramation", false, payload);

export const removeProductByGramation = (
  payload // remove Product by Gramation
) => handlePOSTRequest("removeProductByGramation", false, payload, false, true);

export const addProductByGramation = (
  payload // add Product by Gramation
) => handlePOSTRawRequest("addProductByGramation", false, payload);

export const getListGroupDivisionSales = (
  payload // List Group DivisionSales
) => handlePOSTRequest("getListGroupDivisionSales", false, payload);

export const createGroupDivisionSales = (
  payload // Create Group DivisionSales
) => handlePOSTRequest("createGroupDivisionSales", false, payload);

export const deleteGroupDivisionSales = (
  payload // Delete Group DivisionSales
) => handlePOSTRequest("deleteGroupDivisionSales", false, payload, false, true);

export const editGroupDivisionSales = (
  id,
  payload // edit Group DivisionSales
) => handlePOSTRequest("editGroupDivisionSales", { id }, payload);

export const getListProductByDivisionSales = (
  payload // List Product by DivisionSales
) => handlePOSTRawRequest("getListProductByDivisionSales", false, payload);

export const removeProductByDivisionSales = (
  payload // remove Product by DivisionSales
) =>
  handlePOSTRequest(
    "removeProductByDivisionSales",
    false,
    payload,
    false,
    true
  );

export const addProductByDivisionSales = (
  payload // add Product by DivisionSales
) => handlePOSTRawRequest("addProductByDivisionSales", false, payload);

export const getListGroupOthers = (
  payload // List Group Others
) => handlePOSTRequest("getListGroupOthers", false, payload);

export const createGroupOthers = (
  payload // Create Group Others
) => handlePOSTRequest("createGroupOthers", false, payload);

export const deleteGroupOthers = (
  payload // Delete Group Others
) => handlePOSTRequest("deleteGroupOthers", false, payload, false, true);

export const editGroupOthers = (
  id,
  payload // edit Group Others
) => handlePOSTRequest("editGroupOthers", { id }, payload);

export const getListProductByOthers = (
  payload // List Product by Others
) => handlePOSTRawRequest("getListProductByOthers", false, payload);

export const removeProductByOthers = (
  payload // remove Product by Others
) => handlePOSTRequest("removeProductByOthers", false, payload);

export const addProductByOthers = (
  payload // add Product by Others
) => handlePOSTRawRequest("addProductByOthers", false, payload);

export const getListProductBranch = (
  payload // List Product
) => handlePOSTRawRequest("getListProductBranch", false, payload);

export const getListProductToBranch = (payload) =>
  handlePOSTRequest("getListProductToBranch", false, payload);

export const exportListProductToBranch = (payload) =>
  handlePOSTRequest("exportListProductToBranch", false, payload);

export const addProductToBranch = (payload) =>
  handlePOSTRequest("addProductToBranch", false, payload);

export const removeProductToBranch = (payload) =>
  handlePOSTRequest("removeProductToBranch", false, payload);

export const getListProductCategoryBrand = (
  payload // List Product
) => handlePOSTRawRequest("getListProductCategoryBrand", false, payload);

export const getListProductUom = (
  payload // List Product
) => handlePOSTRawRequest("getListProductUom", false, payload);

export const getListProductDivision = (payload) =>
  handlePOSTRawRequest("getListProductDivision", false, payload);

// Master data Country

export const getListCountry = (payload) =>
  handlePOSTRequest("getListCountry", false, payload);

export const createCountry = (payload) =>
  handlePOSTRequest("createCountry", false, payload);

export const editCountry = (payload) =>
  handlePOSTRequest("editCountry", false, payload);

export const deleteCountry = (payload) =>
  handlePOSTRequest("deleteCountry", false, payload, false, true);

export const getListRegion = (
  payload // List Company
) => handlePOSTRawRequest("getListRegion", false, payload);

export const getListTermOfPayment = (
  payload // List Company
) => handlePOSTRawRequest("getListTermOfPayment", false, payload);

export const getListDriver = (
  payload // List Driver
) => handlePOSTRawRequest("getListDriver", false, payload);

export const editDriver = (id, payload) =>
  handlePOSTRequest("editDriver", { id }, payload, false, true);

export const createDriver = (payload) =>
  handlePOSTRequest("createDriver", false, payload, false, true);

export const updateStatusDriver = (payload) =>
  handlePOSTRequest("updateStatusDriver", false, payload, false, true);

export const getListReasonReturn = (payload) =>
  handlePOSTRequest("getListReasonReturn", false, payload);

export const getListConfigPrintDoc = (
  { document_type } // get list config line item
) => handlePOSTRequest("getListConfigPrintDoc", false, { document_type });

export const getItemReceiver = (
  { trans_type, supplying_branch, receiving_branch, product_sender } // get item receiver
) =>
  handlePOSTRequest("getItemReceiver", false, {
    trans_type,
    supplying_branch,
    receiving_branch,
    product_sender,
  });

export const getListMovType = (
  { company_id } // get list stock realTime
) => handlePOSTRequest("getListMovType", false, { company_id });

//Inventory
export const getListStockRealTime = (
  { page, limit, company_id, branch_id, sloc_id, product_id } // get list stock realTime
) =>
  handlePOSTRequest("getListStockRealTime", false, {
    page,
    limit,
    company_id,
    branch_id,
    sloc_id,
    product_id,
  });

export const exportListStockCompare = (
  { branch_id, sloc_id } // get list stock realTime
) =>
  handlePOSTBlobRequest("getListStockCompare", false, {
    branch_id,
    sloc_id,
  });

export const exportListStockRealTime = (
  { company_id, branch_id, sloc_id, product_id } // download list stock realTime as xls
) =>
  handlePOSTRequest("exportListStockRealTime", false, {
    company_id,
    branch_id,
    sloc_id,
    product_id,
  });

export const exportListStockRealTimeWithSap = (
  { company_id, branch_id, sloc_id, product_id, ...rest } // download list stock realTime as xls
) =>
  handlePOSTRequest("exportStockRealTimeWithSap", false, {
    company_id,
    branch_id,
    sloc_id,
    product_id,
    ...rest,
  });

export const getListStockInTransit = (payload) =>
  handlePOSTRequest("getListStockInTransit", false, payload);

export const getListMaterialDoc = (payload) =>
  handlePOSTRequest("getListMaterialDoc", false, payload, false, true);

export const exportListMaterialDoc = (payload) =>
  handlePOSTRequest("exportListMaterialDoc", false, payload, false, true);

export const getListStockMutasiGudang = (payload) =>
  handlePOSTRequest("getListStockMutasiGudang", false, payload);

export const exportListStockMutasiGudang = (payload) =>
  handlePOSTRequest("exportListStockMutasiGudang", false, payload);

export const getListAvailabilityOverview = (payload) =>
  handlePOSTRequest("getListAvailabilityOverview", false, payload);
export const searchListAvailabilityOverview = (payload) =>
  handlePOSTRequest("searchListAvailabilityOverview", false, payload);

export const exportListAvailabilityOverview = (payload) =>
  handlePOSTRequest("exportListAvailabilityOverview", false, payload);

export const getListPOsto = (payload) =>
  handlePOSTRequest("getListPOsto", false, payload);

export const getDetailPOsto = ({ po_trans_num, receiving_branch }) =>
  handlePOSTRequest("getDetailPOsto", false, {
    po_trans_num,
    receiving_branch,
  });

//cron executor

export const SAPGetPOSTO = (payload) =>
  handlePOSTRequest("SAPGetPOSTO", false, payload, false, true);

export const SAPGetDOSTO = (payload) =>
  handlePOSTRequest("SAPGetDOSTO", false, payload, false, true);

export const SAPGetGRSTO = (payload) =>
  handlePOSTRequest("SAPGetGRSTO", false, payload, false, true);

export const SAPGetPOPrincipal = (payload) =>
  handlePOSTRequest("SAPGetPOPrincipal", false, payload, false, true);

export const SAPSyncData = (payload) =>
  handlePOSTRequest("SAPSyncData", false, payload, false, true);

export const SAPSyncDataNewEndPoint = (payload) =>
  handlePOSTRawRequest("SAPSyncDataNewEndpoint", false, payload, false, true);

export const getListScheduleSyncSAP = (payload) =>
  handlePOSTRequest("getListScheduleSyncSAP", false, payload);

export const createScheduleSyncSAP = (payload) =>
  handlePOSTRequest("createScheduleSyncSAP", false, payload, false, true);

export const editScheduleSyncSAP = (id, payload) =>
  handlePOSTRequest("editScheduleSyncSAP", { id }, payload, false, true);

export const getListExtractData = (payload) =>
  handlePOSTRequest("getListExtractData", false, payload);

export const getListExtractDataNew = (payload) => {
  console.log("payload", payload);
  return handlePOSTRawRequest("getListExtractDataNew", false, {
    ...payload,
    page: String(payload.page),
    offset: String(payload.page),
    limit: String(payload.limit),
  });
};

export const getListUploadMonitoringSAP = (payload) =>
  handlePOSTRequest("getListUploadMonitoringSAP", false, payload);

export const getDetailUploadMonitoringSAP = (payload) =>
  handlePOSTRequest("getDetailUploadMonitoringSAP", false, payload);

export const getDetailErrorExtractData = (payload) =>
  handlePOSTRequest("getDetailErrorExtractData", false, payload);

export const downloadExtractData = (payload) =>
  handlePOSTRequest("downloadExtractData", false, payload, false, true);

export const downloadTPRData = (payload) =>
  handlePOSTRequest("downloadTPRData", false, payload, false, true);

export const downloadExtractDataNew = (payload) =>
  handlePOSTRequest("downloadExtractDataNew", false, payload, false, true);

export const getListApprovalPOsto = (payload) =>
  handlePOSTRequest("getListApprovalPOsto", false, payload);

export const cancelPOsto = ({ po_trans_num, remarks }) =>
  handlePOSTRequest("cancelPOsto", false, {
    po_trans_num,
    remarks,
  });

export const createPOsto = (payload) =>
  handlePOSTRequest("createPOsto", false, payload);

export const releasePOsto = (payload) =>
  handlePOSTRequest("releasePOsto", false, payload, false, true);

export const unreleasePOsto = (payload) =>
  handlePOSTRequest("unreleasePOsto", false, payload, false, true);

export const approvePOsto = (payload) =>
  handlePOSTRequest("approvePOsto", false, payload, false, true);

export const unapprovePOsto = (payload) =>
  handlePOSTRequest("unapprovePOsto", false, payload, false, true);

export const editPOsto = (id, payload) =>
  handlePOSTRequest("editPOsto", { id }, payload);

export const getListDOsto = (payload) =>
  handlePOSTRequest("getListDOsto", false, payload);

export const createDOsto = (payload) =>
  handlePOSTRequest("createDOsto", false, payload);

export const editDOsto = (id, payload) =>
  handlePOSTRequest("editDOsto", { id }, payload);

export const getDetailDOsto = ({ do_trans_num }) =>
  handlePOSTRequest("getDetailDOsto", false, {
    do_trans_num,
  });

export const getDeliveryNote = ({ do_trans_num }) =>
  handlePOSTRequest("getDeliveryNote", false, {
    do_trans_num,
  });

export const cancelDOsto = ({ do_trans_num, remarks }) =>
  handlePOSTRequest("cancelDOsto", false, {
    do_trans_num,
    remarks,
  });

export const getListGIsto = (payload) =>
  handlePOSTRequest("getListGIsto", false, payload);

export const getDetailGIsto = ({ gi_trans_num }) =>
  handlePOSTRequest("getDetailGIsto", false, {
    gi_trans_num,
  });

export const cancelGIsto = ({ gi_trans_num, remarks }) =>
  handlePOSTRequest("cancelGIsto", false, {
    gi_trans_num,
    remarks,
  });

export const createGIsto = ({
  do_trans_num,
  origin_branch,
  destination_branch,
  document_date,
  posting_date,
  remarks,
}) =>
  handlePOSTRequest("createGIsto", false, {
    do_trans_num,
    origin_branch,
    destination_branch,
    document_date,
    posting_date,
    remarks,
  });

export const getListGRsto = (payload) =>
  handlePOSTRequest("getListGRsto", false, payload);

export const getDetailGRsto = ({ gr_trans_num }) =>
  handlePOSTRequest("getDetailGRsto", false, {
    gr_trans_num,
  });

export const getLPBGRIntraPlant = ({ gr_trans_num }) =>
  handlePOSTRequest("getLPBGRIntraPlant", false, { gr_trans_num });

export const cancelGRsto = ({ gr_trans_num, remarks }) =>
  handlePOSTRequest("cancelGRsto", false, {
    gr_trans_num,
    remarks,
  });

export const createGRsto = (payload) =>
  handlePOSTRequest("createGRsto", false, payload);

export const exportListStockInTransit = (
  { company_id, receiving_branch, supplying_branch, product_id, filters } // download list stock realTime as xls
) =>
  handlePOSTRequest("exportListStockInTransit", false, {
    company_id,
    receiving_branch,
    supplying_branch,
    product_id,
    filters,
  });

export const getListAvailablePOPrincipal = (payload) =>
  handlePOSTRequest("getListAvailablePOPrincipal", false, payload);

export const getDetailAvailablePOPrincipal = ({
  gi_number,
  po_number,
  branch_id,
}) =>
  handlePOSTRequest("getDetailAvailablePOPrincipal", false, {
    gi_number,
    po_number,
    branch_id,
  });

export const getListGRPrincipal = (payload) =>
  handlePOSTRequest("getListGRPrincipal", false, payload);

export const getDetailGRPrincipal = ({ gr_trans_num }) =>
  handlePOSTRequest("getDetailGRPrincipal", false, { gr_trans_num });

export const createGRPrincipal = (payload) =>
  handlePOSTRequest("createGRPrincipal", false, payload);

export const getLPBGRPrincipal = ({ gr_trans_num }) =>
  handlePOSTRequest("getLPBGRPrincipal", false, { gr_trans_num });

export const getLRBGRPrincipal = ({ return_trans_num }) =>
  handlePOSTRequest("getLRBGRPrincipal", false, { return_trans_num });

export const cancelGRPrincipal = ({ gr_trans_num, remarks }) =>
  handlePOSTRequest("cancelGRPrincipal", false, { gr_trans_num, remarks });

export const getListGRPrincipalReturn = (payload) =>
  handlePOSTRequest("getListGRPrincipalReturn", false, payload);

export const getDetailGRPrincipalReturn = ({ return_trans_num }) =>
  handlePOSTRequest("getDetailGRPrincipalReturn", false, { return_trans_num });

export const createGRPrincipalReturn = (payload) =>
  handlePOSTRequest("createGRPrincipalReturn", false, payload);

export const cancelGRPrincipalReturn = ({ return_trans_num, remarks }) =>
  handlePOSTRequest("cancelGRPrincipalReturn", false, {
    return_trans_num,
    remarks,
  });

export const getListPOPrincipal = (payload) =>
  handlePOSTRequest("getListPOPrincipal", false, payload);

export const getListIntraChannel = (payload) =>
  handlePOSTRequest("getListIntraChannel", false, payload);

export const getListGIGRIntraChannel = (payload) =>
  handlePOSTRequest("getListGIGRIntraChannel", false, payload);

export const createIntraChannel = (payload) =>
  handlePOSTRequest("createIntraChannel", false, payload);

export const getDetailIntraChannel = ({ trans_num }) =>
  handlePOSTRequest("getDetailIntraChannel", false, { trans_num });

export const getDetailGIGRIntraChannel = ({ trans_num, transaction_type }) =>
  handlePOSTRequest("getDetailGIGRIntraChannel", false, {
    trans_num,
    transaction_type,
  });

export const cancelIntraChannel = ({ trans_num, remarks }) =>
  handlePOSTRequest("cancelIntraChannel", false, { trans_num, remarks });

export const approveIntraChannel = ({ trans_num, is_approved }) =>
  handlePOSTRequest("approveIntraChannel", false, { trans_num, is_approved });

export const getListIntraSloc = (payload) =>
  handlePOSTRequest("getListIntraSloc", false, payload);

export const getListGIGRIntraSloc = (payload) =>
  handlePOSTRequest("getListGIGRIntraSloc", false, payload);

export const createIntraSloc = (payload) =>
  handlePOSTRequest("createIntraSloc", false, payload);

export const getDetailIntraSloc = ({ trans_num }) =>
  handlePOSTRequest("getDetailIntraSloc", false, { trans_num });

export const getDetailGIGRIntraSloc = ({ trans_num, transaction_type }) =>
  handlePOSTRequest("getDetailGIGRIntraSloc", false, {
    trans_num,
    transaction_type,
  });

export const cancelIntraSloc = ({ trans_num, remarks }) =>
  handlePOSTRequest("cancelIntraSloc", false, { trans_num, remarks });

export const approveIntraSloc = ({ trans_num, is_approved }) =>
  handlePOSTRequest("approveIntraSloc", false, { trans_num, is_approved });

export const getListGIFromStorage = (payload) =>
  handlePOSTRequest("getListGIFromStorage", false, payload);

export const createGIFromStorage = (payload) =>
  handlePOSTRequest("createGIFromStorage", false, payload);

export const getDetailGIFromStorage = ({ trans_num }) =>
  handlePOSTRequest("getDetailGIFromStorage", false, { trans_num });

export const cancelGIFromStorage = (payload) =>
  handlePOSTRequest("cancelGIFromStorage", false, payload, false, true);

export const getListPlaceInStorage = (payload) =>
  handlePOSTRequest("getListPlaceInStorage", false, payload);

export const createPlaceInStorage = (payload) =>
  handlePOSTRequest("createPlaceInStorage", false, payload);

export const getDetailPlaceInStorage = ({ trans_num }) =>
  handlePOSTRequest("getDetailPlaceInStorage", false, { trans_num });

export const getBPBCanvas = ({ trans_num }) =>
  handlePOSTRequest("getBPBCanvas", false, { trans_num });

export const cancelPlaceInStorage = (payload) =>
  handlePOSTRequest("cancelPlaceInStorage", false, payload, false, true);

export const getListStockOpname = (payload) =>
  handlePOSTRequest("getListStockOpname", false, payload);

export const createStockOpname = (payload) =>
  handlePOSTRequest("createStockOpname", false, payload);

export const editStockOpname = (id, payload) =>
  handlePOSTRequest("editStockOpname", { id }, payload);

export const getDetailStockOpname = ({ trans_num }) =>
  handlePOSTRequest("getDetailStockOpname", false, { trans_num });

export const approveStockOpname = ({ trans_num, is_approved }) =>
  handlePOSTRequest("approveStockOpname", false, { trans_num, is_approved });

export const getListStockReservation = (payload) =>
  handlePOSTRequest("getListStockReservation", false, payload);

export const createStockReservation = (payload) =>
  handlePOSTRequest("createStockReservation", false, payload);

export const cancelStockReservation = (payload) =>
  handlePOSTRequest("cancelStockReservation", false, payload, false, true);

export const editStockReservation = (id, payload) =>
  handlePOSTRequest("editStockReservation", { id }, payload);

export const getDetailStockReservation = ({ trans_num }) =>
  handlePOSTRequest("getDetailStockReservation", false, { trans_num });

export const approveStockReservation = ({ trans_num, is_approved }) =>
  handlePOSTRequest("approveStockReservation", false, {
    trans_num,
    is_approved,
  });

export const unapproveStockReservation = ({ trans_num }) =>
  handlePOSTRequest("unapproveStockReservation", false, {
    trans_num,
  });

export const createStockMutasi = (payload) =>
  handlePOSTRequest("createStockMutasi", false, payload);

export const cancelStockMutasi = (payload) =>
  handlePOSTRequest("cancelStockMutasi", false, payload, false, true);

export const getListSwapHandling = (payload) =>
  handlePOSTRequest("getListSwapHandling", false, payload);

export const getDetailSwapHandling = ({ trans_num }) =>
  handlePOSTRequest("getDetailSwapHandling", false, { trans_num });

export const getListAlihStatus = (payload) =>
  handlePOSTRequest("getListAlihStatus", false, payload);

export const getDetailAlihStatus = ({ trans_num }) =>
  handlePOSTRequest("getDetailAlihStatus", false, { trans_num });

export const getListGIDisposal = (payload) =>
  handlePOSTRequest("getListGIDisposal", false, payload);

export const createGIDisposal = (payload) =>
  handlePOSTRequest("createGIDisposal", false, payload);

export const getDetailGIDisposal = ({ trans_num }) =>
  handlePOSTRequest("getDetailGIDisposal", false, { trans_num });

export const cancelGIDisposal = (payload) =>
  handlePOSTRequest("cancelGIDisposal", false, payload, false, true);

export const getListReportSwapHandling = (payload) =>
  handlePOSTRequest("getListReportSwapHandling", false, payload, false, true);

export const exportListReportSwapHandling = (payload) =>
  handlePOSTRequest(
    "exportListReportSwapHandling",
    false,
    payload,
    false,
    true
  );

//Nadine
export const getListPromo = (payload) =>
  handlePOSTRawRequest("getListPromo", false, payload);

export const getListConditionType = (payload) =>
  handlePOSTRawRequest("getListConditionType", false, payload);

export const getDetailPromo = ({ id }) =>
  handleGETRequest("getDetailPromo", { id });

export const getItemBs = ({ id, plant }) =>
  handleGETRequest("getItemBs", { id, plant });

export const createPromo = (payload) =>
  handlePOSTRequest("createPromo", false, payload);

export const saveDraftPromo = (payload) =>
  handlePOSTRequest("saveDraftPromo", false, payload);

export const updatePromo = ({ id, payload }) =>
  handlePOSTRequest("updatePromo", { id }, payload);

export const updateStatusPromo = ({ id, payload }) =>
  handlePUTRawRequest("updateStatusPromo", { id }, payload);

export const createPromoSimulation = (payload) =>
  handlePOSTRawRequest("createPromoSimulation", false, payload);

export const savePromoSimulation = (payload) =>
  handlePOSTRawRequest("savePromoSimulation", false, payload);

export const getListPromoSimulation = (payload) =>
  handlePOSTRawRequest("getListPromoSimulation", false, payload);

export const uploadSurkom = (payload) =>
  handlePOSTRequest("uploadSurkom", false, payload, false, true);

export const uploadCustomer = (payload) =>
  handlePOSTRequest("uploadCustomer", false, payload, false, true);

export const uploadProduct = (payload) =>
  handlePOSTRequest("uploadProduct", false, payload, false, true);

export const uploadPromoSimulation = (payload) =>
  handlePOSTRequest("uploadPromoSimulation", false, payload, false, true);

export default getListSalesOrg;

//Auth
//User
export const getListUser = (
  payload // List User
) => handlePOSTRawRequest("getListUser", false, payload);

export const getListRequestPassword = (
  payload // List User
) => handlePOSTRequest("getListRequestPassword", false, payload);

export const approvalResetPassword = (
  payload // List User
) => handlePOSTRequest("approvalResetPassword", false, payload, false, true);

export const createUser = (
  payload // Create User
) => handlePOSTRawRequest("createUser", false, payload);

export const getDetailUser = (payload) =>
  handlePOSTRequest("getDetailUser", false, payload, false, true);

export const editUser = (id, payload) =>
  handlePOSTRequest("editUser", { id }, payload);

export const getListMenu = () => handlePOSTRawRequest("getListMenu", false);

export const getListMenuUser = (payload) =>
  handlePOSTRequest("getListMenuUser", false, payload, false, true);

//config
export const getListInconsistency = (
  payload // List Inconsistency
) => handlePOSTRawRequest("getListInconsistency", false, payload);

//Sloc Plant
export const getListSlocBranch = (
  payload // List Sloc plant
) => handlePOSTRawRequest("getListSlocBranch", false, payload);
export const editSlocBranch = (
  payload // List Sloc plant
) => handlePOSTRequest("editSlocBranch", false, payload);

export const getListSlocCompany = (
  payload // List Sloc company
) => handlePOSTRawRequest("getListSlocCompany", false, payload);

export const getDetailSlocCompany = (
  payload // Detail Sloc company
) => handlePOSTRawRequest("getDetailSlocCompany", false, payload);

export const editSlocCompany = (
  payload // Edit Sloc company
) => handlePOSTRawRequest("editSlocCompany", false, payload);

export const createSlocCompany = (
  payload // create Sloc company
) => handlePOSTRawRequest("createSlocCompany", false, payload);

export const statusSlocCompany = (
  payload // status Sloc company
) => handlePOSTRawRequest("statusSlocCompany", false, payload);

export const getListConfigCompany = (
  payload // List Config company
) => handlePOSTRawRequest("getListConfigCompany", false, payload);

export const getDetailConfigCompany = (
  payload // Detail Config company
) => handlePOSTRawRequest("getDetailConfigCompany", false, payload);

export const editConfigCompany = (
  payload // Edit Config company
) => handlePOSTRawRequest("editConfigCompany", false, payload);

export const createConfigCompany = (
  payload // create Config company
) => handlePOSTRawRequest("createConfigCompany", false, payload);

export const statusConfigCompany = (
  payload // status Config company
) => handlePOSTRawRequest("statusConfigCompany", false, payload);

export const getListOrderTypeToSloc = (
  payload // List Sloc plant
) => handlePOSTRawRequest("getListOrderTypeToSloc", false, payload);

export const createOrderTypeToSloc = (
  payload // Create Sloc plant
) => handlePOSTRawRequest("createOrderTypeToSloc", false, payload);
// update Sloc plant
export const editOrderTypeToSloc = ({ id, payload }) =>
  handlePUTRawRequest("editOrderTypeToSloc", { id }, payload);

//Sloc Customer Group
export const getListSlocCustomerGroup = (
  payload // List Sloc Customer Group
) => handlePOSTRawRequest("getListSlocCustomerGroup", false, payload);

export const getListSlocCustomerGroupLogistic = (
  payload // List Sloc Customer Group
) => handlePOSTRawRequest("getListSlocCustomerGroupLogistic", false, payload);

export const createSlocCustomerGroupLogistic = (
  payload // Create Sloc Customer Group
) => handlePOSTRawRequest("createSlocCustomerGroupLogistic", false, payload);
export const editSlocCustomerGroupLogistic = (
  payload // edit Sloc Customer Group
) => handlePOSTRawRequest("editSlocCustomerGroupLogistic", false, payload);

//Sloc Salesman
export const getListSlocSalesman = (
  payload // List Sloc Salesman
) => handlePOSTRawRequest("getListSlocSalesman", false, payload);

export const createSlocSalesman = (
  payload // create Sloc Salesman
) => handlePOSTRawRequest("createSlocSalesman", false, payload);

export const editSlocSalesman = (
  { id, payload } // Edit Sloc Salesman
) => handlePUTRawRequest("editSlocSalesman", { id }, payload);

//Product Batch
export const getListProductBatch = (
  payload // List Product Batch
) => handlePOSTRawRequest("getListProductBatch", false, payload);

//Auto SO to Do
export const getListAutoSOToDO = (
  payload // List Auto SO to Do
) => handlePOSTRawRequest("getListAutoSOToDO", false, payload);

export const createAutoSOToDO = (
  payload // Create Auto SO to Do
) => handlePOSTRawRequest("createAutoSOToDO", false, payload);

export const editAutoSOToDO = (
  { id, payload } // Edit Auto SO to Do
) => handlePUTRawRequest("editAutoSOToDO", { id }, payload);

//Tax Regulator
export const getListTaxRegulator = (
  payload // List Tax Regulator
) => handlePOSTRawRequest("getListTaxRegulator", false, payload);

//SO Block
export const getListSalesOrderProduct = (
  payload // List Block SO Product
) => handlePOSTRawRequest("getListSalesOrderProduct", false, payload);

export const getListSalesOrderChannel = (
  payload // List Block SO Channel
) => handlePOSTRawRequest("getListSalesOrderChannel", false, payload);

export const getListSalesOrderCustomer = (
  payload // List Block SO Customer
) => handlePOSTRawRequest("getListSalesOrderCustomer", false, payload);

export const getListPasswordExtract = (payload) =>
  handlePOSTRequest("getListPasswordExtract", false, payload);

export const createPasswordExtract = (payload) =>
  handlePOSTRequest("createPasswordExtract", false, payload, false, true);

export const deletePasswordExtract = (payload) =>
  handlePOSTRequest("deletePasswordExtract", false, payload, false, true);

export const editPasswordExtract = (id, payload) =>
  handlePOSTRequest("editPasswordExtract", { id }, payload, false, true);

//Export Master Data
export const exportListSalesOrg = (payload) =>
  handlePOSTRequest("exportListSalesOrg", false, payload);

export const createSalesOrg = (payload) =>
  handlePOSTRequest("createSalesOrg", false, payload);

export const editSalesOrg = (payload) =>
  handlePOSTRequest("editSalesOrg", false, payload);

export const deleteSalesOrg = (payload) =>
  handlePOSTRequest("deleteSalesOrg", false, payload, false, true);

export const exportListCompany = (payload) =>
  handlePOSTRequest("exportListCompany", false, payload);

export const exportListCountry = (payload) =>
  handlePOSTRequest("exportListCountry", false, payload);

export const exportListSalesOffice = (payload) =>
  handlePOSTRequest("exportListSalesOffice", false, payload);

export const createSalesOffice = (payload) =>
  handlePOSTRequest("createSalesOffice", false, payload);

export const editSalesOffice = (payload) =>
  handlePOSTRequest("editSalesOffice", false, payload);

export const deleteSalesOffice = (payload) =>
  handlePOSTRequest("deleteSalesOffice", false, payload);

export const exportListSalesGroup = (payload) =>
  handlePOSTRequest("exportListSalesGroup", false, payload);

export const getListMasterSalesGroup = (payload) =>
  handlePOSTRequest("getListMasterSalesGroup", false, payload);

export const createSalesGroup = (payload) =>
  handlePOSTRequest("createSalesGroup", false, payload);

export const editSalesGroup = (payload) =>
  handlePOSTRequest("editSalesGroup", false, payload);

export const deleteSalesGroup = (payload) =>
  handlePOSTRequest("deleteSalesGroup", false, payload);

export const exportListChannel = (payload) =>
  handlePOSTRequest("exportListChannel", false, payload);

export const createChannel = (payload) =>
  handlePOSTRequest("createChannel", false, payload);

export const editChannel = (payload) =>
  handlePOSTRequest("editChannel", false, payload);

export const deleteChannel = (payload) =>
  handlePOSTRequest("deleteChannel", false, payload, false, true);

export const exportListCustomerGroup = (payload) =>
  handlePOSTRequest("exportListCustomerGroup", false, payload);

export const createCustomerGroup = (payload) =>
  handlePOSTRequest("createCustomerGroup", false, payload);

export const editCustomerGroup = (payload) =>
  handlePOSTRequest("editCustomerGroup", false, payload);

export const deleteCustomerGroup = (payload) =>
  handlePOSTRequest("deleteCustomerGroup", false, payload, false, true);

export const exportListRegion = (payload) =>
  handlePOSTRequest("exportListRegion", false, payload);

export const createRegion = (payload) =>
  handlePOSTRequest("createRegion", false, payload);

export const editRegion = (payload) =>
  handlePOSTRequest("editRegion", false, payload);

export const deleteRegion = (payload) =>
  handlePOSTRequest("deleteRegion", false, payload, false, true);

export const exportListDistrict = (payload) =>
  handlePOSTRequest("exportListDistrict", false, payload);

export const getListMasterDistrict = (payload) =>
  handlePOSTRequest("getListMasterDistrict", false, payload);

export const createDistrict = (payload) =>
  handlePOSTRequest("createDistrict", false, payload);

export const editDistrict = (payload) =>
  handlePOSTRequest("editDistrict", false, payload);

export const deleteDistrict = (payload) =>
  handlePOSTRequest("deleteDistrict", false, payload, false, true);

export const exportListBranch = (payload) =>
  handlePOSTRequest("exportListBranch", false, payload);

export const exportListTermOfPayment = (payload) =>
  handlePOSTRequest("exportListTermOfPayment", false, payload);

export const deleteTermOfPayment = (payload) =>
  handlePOSTRequest("deleteTermOfPayment", false, payload, false, true);

export const createTermOfPayment = (payload) =>
  handlePOSTRequest("createTermOfPayment", false, payload);

export const editTermOfPayment = (payload) =>
  handlePOSTRequest("editTermOfPayment", false, payload);

export const exportListPriceList = (payload) =>
  handlePOSTRequest("exportListPriceList", false, payload);

export const createPriceList = (payload) =>
  handlePOSTRequest("createPriceList", false, payload);

export const editPriceList = (payload) =>
  handlePOSTRequest("editPriceList", false, payload);

export const deletePriceList = (payload) =>
  handlePOSTRequest("deletePriceList", false, payload, false, true);

export const exportListCurrency = (payload) =>
  handlePOSTRequest("exportListCurrency", false, payload);

export const createCurrency = (payload) =>
  handlePOSTRequest("createCurrency", false, payload);

export const editCurrency = (payload) =>
  handlePOSTRequest("editCurrency", false, payload);

export const deleteCurrency = (payload) =>
  handlePOSTRequest("deleteCurrency", false, payload);

export const exportListCustomer = (payload) =>
  handlePOSTRequest("exportListCustomer", false, payload);

export const exportListApprovalCreditLimit = (payload) =>
  handlePOSTRequest("exportListApprovalCreditLimit", false, payload);

//Master Data Uom
export const exportListProductUom = (payload) =>
  handlePOSTRequest("exportListProductUom", false, payload);

export const createProductUom = (payload) =>
  handlePOSTRequest("createProductUom", false, payload);

export const editProductUom = (payload) =>
  handlePOSTRequest("editProductUom", false, payload);

export const deleteProductUom = (payload) =>
  handlePOSTRequest("deleteProductUom", false, payload, false, true);

export const exportListProductCategoryBrand = (payload) =>
  handlePOSTRequest("exportListProductCategoryBrand", false, payload);

//master product category
export const getListProductCategory = (payload) =>
  handlePOSTRequest("getListProductCategory", false, payload);

export const exportListProductCategory = (payload) =>
  handlePOSTRequest("exportListProductCategory", false, payload);

export const createProductCategory = (payload) =>
  handlePOSTRequest("createProductCategory", false, payload);

export const editProductCategory = (payload) =>
  handlePOSTRequest("editProductCategory", false, payload);

export const deleteProductCategory = (payload) =>
  handlePOSTRequest("deleteProductCategory", false, payload, false, true);

//master product Brand
export const getListProductBrand = (payload) =>
  handlePOSTRequest("getListProductBrand", false, payload);

export const exportListProductBrand = (payload) =>
  handlePOSTRequest("exportListProductBrand", false, payload);

export const createProductBrand = (payload) =>
  handlePOSTRequest("createProductBrand", false, payload);

export const editProductBrand = (payload) =>
  handlePOSTRequest("editProductBrand", false, payload);

export const deleteProductBrand = (payload) =>
  handlePOSTRequest("deleteProductBrand", false, payload, false, true);

//master product Principal
export const getListProductPrincipal = (payload) =>
  handlePOSTRequest("getListProductPrincipal", false, payload);

export const exportListProductPrincipal = (payload) =>
  handlePOSTRequest("exportListProductPrincipal", false, payload);

export const createProductPrincipal = (payload) =>
  handlePOSTRequest("createProductPrincipal", false, payload);

export const editProductPrincipal = (id, payload) =>
  handlePOSTRequest("editProductPrincipal", { id }, payload);

export const deleteProductPrincipal = (payload) =>
  handlePOSTRequest("deleteProductPrincipal", false, payload, false, true);

export const exportListProductDivision = (payload) =>
  handlePOSTRequest("exportListProductDivision", false, payload);

export const createProductDivision = (payload) =>
  handlePOSTRequest("createProductDivision", false, payload);

export const editProductDivision = (payload) =>
  handlePOSTRequest("editProductDivision", false, payload);

export const deleteProductDivision = (payload) =>
  handlePOSTRequest("deleteProductDivision", false, payload, false, true);

export const exportListProductMaster = (payload) =>
  handlePOSTRequest("exportListProductMaster", false, payload);

export const exportListProductBranch = (payload) =>
  handlePOSTRequest("exportListProductBranch", false, payload);

export const exportListSalesman = (payload) =>
  handlePOSTRequest("exportListSalesman", false, payload);

export const exportListSalesmanGroup = (payload) =>
  handlePOSTRequest("exportListSalesmanGroup", false, payload);

export const createSalesmanGroup = (payload) =>
  handlePOSTRequest("createSalesmanGroup", false, payload);

export const editSalesmanGroup = (payload) =>
  handlePOSTRequest("editSalesmanGroup", false, payload);

export const deleteSalesmanGroup = (payload) =>
  handlePOSTRequest("deleteSalesmanGroup", false, payload, false, true);

//master price group
export const getListMasterPriceGroup = (payload) =>
  handlePOSTRequest("getListMasterPriceGroup", false, payload);

export const exportListPriceGroup = (payload) =>
  handlePOSTRequest("exportListPriceGroup", false, payload);

export const createPriceGroup = (payload) =>
  handlePOSTRequest("createPriceGroup", false, payload);

export const editPriceGroup = (payload) =>
  handlePOSTRequest("editPriceGroup", false, payload);

export const activeInactivePriceGroup = (payload) =>
  handlePOSTRequest("activeInactivePriceGroup", false, payload, false, true);

export const exportListPricingByPriceGroup = (payload) =>
  handlePOSTRequest("exportListPricingByPriceGroup", false, payload);

export const exportListPricingByPriceCustomer = (payload) =>
  handlePOSTRequest("exportListPricingByPriceCustomer", false, payload);

export const exportListDriver = (payload) =>
  handlePOSTRequest("exportListDriver", false, payload);

export const exportListVehicle = (payload) =>
  handlePOSTRequest("exportListVehicle", false, payload);

export const exportListVehicleType = (payload) =>
  handlePOSTRequest("exportListVehicleType", false, payload);

export const exportListTransportationZone = (payload) =>
  handlePOSTRequest("exportListTransportationZone", false, payload);

export const createTransportationZone = (payload) =>
  handlePOSTRequest("createTransportationZone", false, payload);

export const editTransportationZone = (payload) =>
  handlePOSTRequest("editTransportationZone", false, payload);

export const deleteTransportationZone = (payload) =>
  handlePOSTRequest("deleteTransportationZone", false, payload, false, true);

export const exportListRouteDetermination = (payload) =>
  handlePOSTRequest("exportListRouteDetermination", false, payload);

export const exportListTzoneToBranch = (payload) =>
  handlePOSTRequest("exportListTzoneToBranch", false, payload);

export const exportListProductByPrincipal = (payload) =>
  handlePOSTRequest("exportListProductByPrincipal", false, payload);

export const exportListProductByBrand = (payload) =>
  handlePOSTRequest("exportListProductByBrand", false, payload);

export const exportListProductByCategory = (payload) =>
  handlePOSTRequest("exportListProductByCategory", false, payload);

export const exportListProductByNPL = (payload) =>
  handlePOSTRequest("exportListProductByNPL", false, payload);

export const exportListProductByRetailPrice = (payload) =>
  handlePOSTRequest("exportListProductByRetailPrice", false, payload);

export const exportListProductByGramation = (payload) =>
  handlePOSTRequest("exportListProductByGramation", false, payload);

export const exportListProductByDivisionSales = (payload) =>
  handlePOSTRequest("exportListProductByDivisionSales", false, payload);

export const exportListProductByOthers = (payload) =>
  handlePOSTRequest("exportListProductByOthers", false, payload);

export const exportListGroupPrincipal = (payload) =>
  handlePOSTRequest("exportListGroupPrincipal", false, payload);

export const exportListGroupBrand = (payload) =>
  handlePOSTRequest("exportListGroupBrand", false, payload);

export const exportListGroupCategory = (payload) =>
  handlePOSTRequest("exportListGroupCategory", false, payload);

export const exportListGroupNPL = (payload) =>
  handlePOSTRequest("exportListGroupNPL", false, payload);

export const exportListGroupRetailPrice = (payload) =>
  handlePOSTRequest("exportListGroupRetailPrice", false, payload);

export const exportListGroupGramation = (payload) =>
  handlePOSTRequest("exportListGroupGramation", false, payload);

export const exportListGroupDivisionSales = (payload) =>
  handlePOSTRequest("exportListGroupDivisionSales", false, payload);

export const exportListGroupOthers = (payload) =>
  handlePOSTRequest("exportListGroupOthers", false, payload);

export const getListConditionTypeMaster = (payload) =>
  handlePOSTRequest("getListConditionTypeMaster", false, payload);

export const exportListConditionTypeMaster = (payload) =>
  handlePOSTRequest("exportListConditionTypeMaster", false, payload);

export const createConditionTypeMaster = (payload) =>
  handlePOSTRequest("createConditionTypeMaster", false, payload, false, true);

export const editConditionTypeMaster = (payload) =>
  handlePOSTRequest("editConditionTypeMaster", false, payload, false, true);

export const statusConditionTypeMaster = (payload) =>
  handlePOSTRequest("statusConditionTypeMaster", false, payload, false, true);

export const getCompareSalesList = async (payload) => {
  const res = await handlePOSTRawRequest(
    "getCompareSalesList",
    false,
    payload,
    false,
    true
  );
  return res;
};

export const getCompareSalesDetailEds = async (payload) => {
  const res = await handlePOSTRawRequest(
    "getCompareSalesDetailEds",
    false,
    payload,
    false,
    true
  );
  return res;
};

export const getCompareSalesDetailSap = async (payload) => {
  const res = await handlePOSTRawRequest(
    "getCompareSalesDetailSap",
    false,
    payload,
    false,
    true
  );
  return res;
};

export const getCompareLogisticList = async (payload) => {
  const res = await handlePOSTRawRequest(
    "getCompareLogisticList",
    false,
    payload,
    false,
    true
  );
  return res;
};

export const getCompareLogisticDetailEds = async (payload) => {
  const res = await handlePOSTRawRequest(
    "getCompareLogisticDetailEds",
    false,
    payload,
    false,
    true
  );
  return res;
};

export const getCompareLogisticDetailSap = async (payload) => {
  const res = await handlePOSTRawRequest(
    "getCompareLogisticDetailSap",
    false,
    payload,
    false,
    true
  );
  return res;
};

export const getCompareDoSoList = async (payload) => {
  const res = await handlePOSTRawRequest(
    "getCompareDoSoList",
    false,
    payload,
    false,
    true
  );
  return res;
};

export const cancelBillingById = async (payload) => {
  const res = await handlePOSTRawRequest(
    "cancelBillingById",
    false,
    payload,
    false,
    true
  );
  return res;
};
