import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces, Trans } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

import SidebarRun from "./Sidebar.run";
import SidebarUserBlock from "./SidebarUserBlock";

import SFAMenu from "../../menu/SFA";
import { Menu, listMenuData, ALLOWED_TO_ALL_MENUS } from "../../menu/Menu.js";
import Cookies from "js-cookie";
import { getLoginData } from "../../utils";

import { IS_PRODUCTION } from "../../config";

const routeActive = (paths) => {
  paths = Array.isArray(paths) ? paths : [paths];
  return paths.some((p) => window.location.pathname.indexOf(p) > -1);
};

const getSubRoutes = (item) => item.submenu.map(({ path }) => path);

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>
      <Trans i18nKey={item.translate}>{item.heading}</Trans>
    </span>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive, isOpen, handler }, index) => {
  const isHaveSubmenu = item.submenu;
  return (
    <>
      {isHaveSubmenu ? (
        <SidebarSubItem
          item={item}
          isOpen={isOpen[item.name]}
          handler={handler}
          isActive={routeActive(getSubRoutes(item))}
        >
          <SidebarSubHeader item={item} />
          {item.submenu.map((subitem, index) => {
            return (
              <li
                className={`${
                  routeActive(subitem.path) ? "active" : ""
                } sub-subNav`}
                key={item.name + index}
              >
                <div className="wrp_nav">
                  <Link to={item.path + subitem.path} title={subitem.name}>
                    {item.label && (
                      <Badge
                        tag="div"
                        className="float-right"
                        color={item.label.color}
                      >
                        {item.label.value}
                      </Badge>
                    )}
                    {item.icon && <em className={item.icon}></em>}
                    <div className="wrp_activeNav">
                      <div className="activeNav">
                        <Trans i18nKey={item.translate}>{subitem.name}</Trans>
                      </div>
                    </div>
                  </Link>
                </div>
              </li>
            );
          })}
        </SidebarSubItem>
      ) : (
        <li
          className={isActive ? "active noChild" : ""}
          style={{ backgroundColor: IS_PRODUCTION ? "#2cbecb" : "#982597" }}
        >
          <Link to={item.path} title={item.name}>
            {item.label && (
              <Badge tag="div" className="float-right" color={item.label.color}>
                {item.label.value}
              </Badge>
            )}
            {item.icon && <em className={item.icon}></em>}

            {item.icon ? (
              <span>
                <Trans i18nKey={item.translate}>{item.name}</Trans>
              </span>
            ) : (
              <div className="activeNav">
                <Trans i18nKey={item.translate}>{item.name}</Trans>
              </div>
            )}
          </Link>
        </li>
      )}
    </>
  );
};

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => {
  const renderItemNav = () => (
    <>
      <span>
        <Trans i18nKey={item.translate}>{item.name}</Trans>
      </span>
      {
        <em
          className={`colapseMenu fa ${
            isOpen ? "fa-angle-up" : "fa-angle-down"
          }`}
        ></em>
      }
    </>
  );
  return (
    <li className={isActive ? "active" : ""}>
      <div
        className={`nav-item ${item.icon && "nav-item-heading"}`}
        onClick={handler}
      >
        {item.label && (
          <Badge tag="div" className="float-right" color={item.label.color}>
            {item.label.value}
          </Badge>
        )}
        {item.icon && <em className={item.icon}></em>}
        {item.icon ? (
          renderItemNav()
        ) : (
          <div className="subNav_navItem_hasChild">{renderItemNav()}</div>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <ul
          id={item.path}
          className={`sidebar-nav sidebar-subnav ${
            item.icon ? "subnav_hasChild" : ""
          }`}
          style={{ backgroundColor: IS_PRODUCTION ? "#2cbecb" : "#982597" }}
        >
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.name}</li>
);

class Sidebar extends Component {
  state = {
    collapse: {},
    sidebarMenu: [],
  };

  componentDidMount() {
    let userData = Cookies.get("userData");
    if (userData) userData = JSON.parse(userData);
    // Turn off printing user profile into the console
    // console.log("userData", userData);
    let isAdmin = userData.role !== 3;
    if (userData.role === 2 && userData.is_marketing) {
      isAdmin = false;
    }

    let menu = Cookies.get("menu");
    if (menu) menu = JSON.parse(menu);
    const tempMenu = [];
    Object.keys(menu).map((item) => {
      const indexParent = listMenuData.findIndex((e) => e.id === menu[item].i);
      if (indexParent > -1) {
        const storeMenu = {
          name: listMenuData[indexParent].name,
          icon: listMenuData[indexParent].icon,
          path: listMenuData[indexParent].path,
        };
        if (menu[item] && menu[item].s) {
          const subMenu = menu[item].s;
          storeMenu.submenu = [];
          Object.keys(subMenu).map((itemSubMenu) => {
            const indexSubMenu = listMenuData.findIndex(
              (e) => e.id === subMenu[itemSubMenu].i
            );
            if (indexSubMenu > -1) {
              const storeSubMenu = {
                name: listMenuData[indexSubMenu].name,
                path: listMenuData[indexSubMenu].path,
              };

              if (subMenu[itemSubMenu] && subMenu[itemSubMenu].s) {
                const subSubMenu = subMenu[itemSubMenu].s;
                storeSubMenu.submenu = [];
                Object.keys(subSubMenu).map((itemSubSubMenu) => {
                  const indexSubSubMenu = listMenuData.findIndex(
                    (e) => e.id === subSubMenu[itemSubSubMenu].i
                  );
                  if (indexSubSubMenu > -1) {
                    const storeSubSubMenu = {
                      name: listMenuData[indexSubSubMenu].name,
                      path: listMenuData[indexSubSubMenu].path,
                    };
                    storeSubMenu.submenu.push(storeSubSubMenu);
                  }
                  return null;
                });
                if (storeSubMenu.submenu.length < 1) {
                  delete storeSubMenu.submenu;
                }
              }
              if (
                !(
                  storeSubMenu.name === "New Promotion Wizard" &&
                  userData.role === 3
                )
              ) {
                storeMenu.submenu.push(storeSubMenu);
              }
            }

            return null;
          });
        }
        tempMenu.push(storeMenu);
      }

      return null;
    });
    let updateMenu = [];
    if (isAdmin) {
      const userData = getLoginData();

      updateMenu = [...tempMenu, ...Menu];
      if (userData.role === 2 && userData.is_stand_alone) {
        updateMenu[5].submenu.splice(12, 4);
        updateMenu[3].submenu[2].submenu.splice(5, 1);
      }
    } else {
      updateMenu = [...tempMenu, ...ALLOWED_TO_ALL_MENUS];

      if (userData.is_stand_alone) {
        const indexModule = updateMenu.findIndex(
          (x) => x.name === "Logistic Module"
        );
        if (indexModule > 0) {
          const indexSubModule = updateMenu[indexModule].submenu.findIndex(
            (x) => x.name === "Intra Plant"
          );
          if (indexSubModule > 0) {
            const indexSubSubModule = updateMenu[indexModule].submenu[
              indexSubModule
            ].submenu.findIndex((x) => x.name === "Sync GR from SAP");
            if (indexSubSubModule > 0) {
              updateMenu[indexModule].submenu[indexSubModule].submenu.splice(
                indexSubSubModule,
                1
              );
            }
          }
        }
        const indexModuleConfig = updateMenu.findIndex(
          (x) => x.name === "Config"
        );
        if (indexModuleConfig > 0) {
          updateMenu.splice(indexModuleConfig, 1);
        }
      }
      const indexModuleAuth = updateMenu.findIndex(
        (x) => x.name === "Authorization"
      );
      if (indexModuleAuth > 0) {
        updateMenu.splice(indexModuleAuth, 1);
      }
    }
    this.setState(
      {
        sidebarMenu: [...updateMenu, SFAMenu],
      },
      () => {
        SidebarRun(this.navigator, this.closeSidebar);
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList();

        // Listen for routes changes in order to hide the sidebar on mobile
        this.props.history.listen(this.closeSidebar);
      }
    );

    // pass navigator to access router api
  }

  closeSidebar = () => {
    this.props.actions.changeSetting("asideToggled", false);
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {};
    this.state.sidebarMenu
      .filter(({ heading }) => !heading)
      .forEach((item) => {
        collapse[item.name] = this.routeActive(
          item.submenu ? item.submenu.map(({ path }) => path) : item.path
        );
        item.submenu &&
          item.submenu.map((subitem) => {
            if (subitem.submenu) {
              collapse[subitem.name] = this.routeActive(
                subitem.submenu
                  ? subitem.submenu.map(({ path }) => path)
                  : subitem.path
              );
            }
            return null;
          });
        // }
      });
    this.setState({ collapse });
  };

  navigator = (route) => {
    this.props.history.push(route.replace("#", "")); // remove '#' in case of use HashRouter
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p) => this.props.location.pathname.indexOf(p) > -1);
  }

  toggleItemCollapse(stateName) {
    // eslint-disable-next-line
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false,
          },
        });
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName],
      },
    });
  }

  toggleSubItemCollapse(stateName) {
    const updateCollapse = { ...this.state.collapse };
    updateCollapse[stateName] = !updateCollapse[stateName];
    this.setState({
      collapse: {
        ...updateCollapse,
      },
    });
  }

  getSubRoutes = (item) => item.submenu.map(({ path }) => path);

  /** map menu config to string to determine which element to render */
  itemType = (item) => {
    if (item.heading) return "heading";
    if (!item.submenu) return "menu";
    if (item.submenu) {
      let valueReturn = "submenu";
      item.submenu.map((subitem) => {
        if (subitem.submenu) {
          valueReturn = "subSubmenu";
        }
        return null;
      });
      return valueReturn;
    }
  };
  render() {
    // console.log("IS_PRODUCTION sidebar", IS_PRODUCTION);
    return (
      <aside className="aside-container">
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav
            data-sidebar-anyclick-close=""
            className="sidebar"
            style={{ backgroundColor: IS_PRODUCTION ? "#2cbecb" : "#982597" }}
          >
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* START user info */}
              <li className="has-user-block">
                <SidebarUserBlock />
              </li>
              {/* END user info */}

              {/* Iterates over all sidebar items */}
              {this.state.sidebarMenu.length > 0 &&
                this.state.sidebarMenu.map((item, i) => {
                  // heading
                  if (this.itemType(item) === "heading")
                    return <SidebarItemHeader item={item} key={i} />;
                  else {
                    if (this.itemType(item) === "menu")
                      return (
                        <SidebarItem
                          isActive={this.routeActive(item.path)}
                          item={item}
                          key={i}
                        />
                      );
                    if (this.itemType(item) === "submenu")
                      return [
                        <SidebarSubItem
                          item={item}
                          isOpen={this.state.collapse[item.name]}
                          handler={this.toggleItemCollapse.bind(
                            this,
                            item.name
                          )}
                          isActive={this.routeActive(this.getSubRoutes(item))}
                          key={i}
                        >
                          <SidebarSubHeader item={item} key={i} />
                          {item.submenu.map((subitem, i) => (
                            <SidebarItem
                              key={i}
                              item={subitem}
                              isActive={this.routeActive(subitem.path)}
                            />
                          ))}
                        </SidebarSubItem>,
                      ];

                    if (this.itemType(item) === "subSubmenu")
                      return [
                        <SidebarSubItem
                          item={item}
                          isOpen={this.state.collapse[item.name]}
                          handler={this.toggleItemCollapse.bind(
                            this,
                            item.name
                          )}
                          isActive={this.routeActive(this.getSubRoutes(item))}
                          key={i}
                        >
                          <SidebarSubHeader item={item} key={i} />
                          {item.submenu.map((subitem, index) => {
                            return (
                              <React.Fragment key={subitem.name + index}>
                                {subitem.submenu ? (
                                  <SidebarItem
                                    key={i}
                                    item={subitem}
                                    isActive={this.routeActive(subitem.path)}
                                    isOpen={this.state.collapse}
                                    handler={this.toggleSubItemCollapse.bind(
                                      this,
                                      subitem.name
                                    )}
                                  />
                                ) : (
                                  <SidebarItem
                                    key={i}
                                    item={subitem}
                                    isActive={this.routeActive(subitem.path)}
                                  />
                                )}
                              </React.Fragment>
                            );
                          })}
                        </SidebarSubItem>,
                      ];
                  }
                  return null; // unrecognized item
                })}
            </ul>
            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    );
  }
}

Sidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(withRouter(Sidebar)));
