export const listPermissionActionData = () => [
  // Quotation
  {
    id: "A227",
    name: "Quotation-List",
  },
  {
    id: "A228",
    name: "Quotation-Detail",
  },
  {
    id: "A229",
    name: "Quotation-Create",
  },
  {
    id: "A230",
    name: "Quotation-Edit",
  },
  {
    id: "A231",
    name: "Quotation-Draft",
  },
  {
    id: "A232",
    name: "Quotation-Cancel",
  },
  // Sales Order
  {
    id: "A233",
    name: "SalesOrder-List",
  },
  {
    id: "A234",
    name: "SalesOrder-Detail",
  },
  {
    id: "A235",
    name: "SalesOrder-Cancel",
  },
  {
    id: "A236",
    name: "SalesOrder-PushDOManual",
  },
  //Delivery Order
  {
    id: "A237",
    name: "DeliveryOrder-List ",
  },
  {
    id: "A238",
    name: "DeliveryOrder-Detail",
  },
  {
    id: "A239",
    name: "DeliveryOrder-Cancel",
  },
  // Shipment
  {
    id: "A240",
    name: "Shipment-List",
  },
  {
    id: "A241",
    name: "Shipment-Detail",
  },
  {
    id: "A242",
    name: "Shipment-Create",
  },
  {
    id: "A243",
    name: "Shipment-Edit",
  },
  {
    id: "A244",
    name: "Shipment-PGI",
  },
  {
    id: "A245",
    name: "Shipment-Cancel",
  },
  {
    id: "A246",
    name: "Shipment-ConfirmDelivered",
  },
  {
    id: "A247",
    name: "Shipment-PrintBPB",
  },
  {
    id: "A248",
    name: "Shipment-PrintBSTF",
  },
  {
    id: "A249",
    name: "Shipment-PrintHPH",
  },
  // Billing
  {
    id: "A250",
    name: "Billing-List",
  },
  {
    id: "A251",
    name: "Billing-Detail",
  },
  {
    id: "A252",
    name: "Billing-PrintInvoice",
  },
  {
    id: "A253",
    name: "Billing-PrintSuratJalan",
  },

  //  Collection
  {
    id: "A254",
    name: "Collection-List",
  },
  {
    id: "A255",
    name: "Collection-Detail",
  },
  {
    id: "A256",
    name: "Collection-ConfirmCash",
  },
  // Undelivered
  {
    id: "A257",
    name: "Undelivered-List",
  },
  {
    id: "A258",
    name: "Undelivered-Detail",
  },
  {
    id: "A259",
    name: "Undelivered-ConfirmUndelivered",
  },
  // SO Approval
  {
    id: "A260",
    name: "SOApproval-List",
  },
  {
    id: "A261",
    name: "SOApproval-Detail",
  },
  {
    id: "A262",
    name: "SOApproval-ApproveSOCreditlimit",
  },
  {
    id: "A263",
    name: "SOApproval-ApproveSOminBlock",
  },
  {
    id: "A264",
    name: "SOApproval-ApproveSOBlockAvaibility",
  },
  {
    id: "A265",
    name: "SOApproval-ApproveSOBlockbyStsCust",
  },

  //Inventory
  {
    id: "A1",
    name: "StockRealTime-Download",
  },
  {
    id: "A2",
    name: "MaterialInTransit-Download",
  },
  {
    id: "A3",
    name: "MaterialDoc-Download",
  },
  {
    id: "A4",
    name: "AvailabilityOverview-Download",
  },
  {
    id: "A5",
    name: "SwapHandling-Download",
  },
  {
    id: "A6",
    name: "MutasiStockGudang-Download",
  },
  //GR From Principal GR
  {
    id: "A7",
    name: "GRFromPrincipalGR-List",
  },
  {
    id: "A8",
    name: "GRFromPrincipalGR-Detail",
  },
  {
    id: "A9",
    name: "GRFromPrincipalGR-Create",
  },
  {
    id: "A225",
    name: "GRFromPrincipalGR-SyncPOFromSAP",
  },
  {
    id: "A10",
    name: "GRFromPrincipalGR-Cancel",
  },
  {
    id: "A11",
    name: "GRFromPrincipalGR-PrintLPB",
  },

  //GR From Principal GR Return
  {
    id: "A12",
    name: "GRFromPrincipalGRReturn-List",
  },
  {
    id: "A13",
    name: "GRFromPrincipalGRReturn-Detail",
  },
  {
    id: "A14",
    name: "GRFromPrincipalGRReturn-Create",
  },
  {
    id: "A15",
    name: "GRFromPrincipalGRReturn-Cancel",
  },
  {
    id: "A16",
    name: "GRFromPrincipalGRReturn-PrintLRB",
  },

  //Intra Plant PO
  {
    id: "A17",
    name: "IntraPlantPO-List",
  },
  {
    id: "A18",
    name: "IntraPlantPO-Detail",
  },
  {
    id: "A19",
    name: "IntraPlantPO-Create",
  },
  {
    id: "A20",
    name: "IntraPlantPO-BulkRelease",
  },
  {
    id: "A21",
    name: "IntraPlantPO-Release",
  },
  {
    id: "A22",
    name: "IntraPlantPO-Edit",
  },
  {
    id: "A23",
    name: "IntraPlantPO-Cancel",
  },
  {
    id: "A24",
    name: "IntraPlantPO-Approve",
  },

  //Intra Plant Approval

  {
    id: "A25",
    name: "IntraPlantApproval-List",
  },
  {
    id: "A26",
    name: "IntraPlantApproval-Detail",
  },
  {
    id: "A27",
    name: "IntraPlantApproval-BulkApprove",
  },
  {
    id: "A28",
    name: "IntraPlantApproval-Approve",
  },
  //Intra Plant DO
  {
    id: "A29",
    name: "IntraPlantDO-List",
  },
  {
    id: "A30",
    name: "IntraPlantDO-Detail",
  },
  {
    id: "A31",
    name: "IntraPlantDO-Create",
  },
  {
    id: "A32",
    name: "IntraPlantDO-SyncPOSTOfromSAP",
  },
  {
    id: "A33",
    name: "IntraPlantDO-Edit",
  },
  {
    id: "A34",
    name: "IntraPlantDO-Cancel",
  },
  {
    id: "A35",
    name: "IntraPlantDO-PGI",
  },
  {
    id: "A36",
    name: "IntraPlantDO-Print",
  },
  //Intra Plant GI
  {
    id: "A37",
    name: "IntraPlantGI-List",
  },
  {
    id: "A38",
    name: "IntraPlantGI-Detail",
  },
  {
    id: "A39",
    name: "IntraPlantGI-GR",
  },
  {
    id: "A40",
    name: "IntraPlantGI-Cancel",
  },
  //Intra Plant GR
  {
    id: "A41",
    name: "IntraPlantGR-List",
  },
  {
    id: "A42",
    name: "IntraPlantGR-Detail",
  },
  {
    id: "A43",
    name: "IntraPlantGR-Create",
  },
  {
    id: "A267",
    name: "IntraPlantGR-PrintLPB",
  },
  {
    id: "A44",
    name: "IntraPlantGR-SyncDOSTOfromSAP",
  },
  //Intra Channel Request
  {
    id: "A47",
    name: "IntraChannelRequest-List",
  },
  {
    id: "A48",
    name: "IntraChannelRequest-Detail",
  },
  {
    id: "A49",
    name: "IntraChannelRequest-Create",
  },
  {
    id: "A50",
    name: "IntraChannelRequest-Cancel",
  },
  {
    id: "A51",
    name: "IntraChannelRequest-Release",
  },
  //Intra Channel Approval
  {
    id: "A52",
    name: "IntraChannelApproval-List",
  },
  {
    id: "A53",
    name: "IntraChannelApproval-Detail",
  },
  {
    id: "A54",
    name: "IntraChannelApproval-Approve",
  },

  //Intra Sloc Request
  {
    id: "A59",
    name: "IntraSlocRequest-List",
  },
  {
    id: "A60",
    name: "IntraSlocRequest-Detail",
  },
  {
    id: "A61",
    name: "IntraSlocRequest-Create",
  },
  {
    id: "A62",
    name: "IntraSlocRequest-Approve",
  },
  {
    id: "A63",
    name: "IntraSlocRequest-Cancel",
  },

  //Stock Mutasi Swap Handling
  {
    id: "A68",
    name: "StockMutasiSwaphandling-List",
  },
  {
    id: "A69",
    name: "StockMutasiSwaphandling-Detail",
  },
  {
    id: "A70",
    name: "StockMutasiSwaphandling-Create",
  },
  {
    id: "A71",
    name: "StockMutasiSwaphandling-Cancel",
  },

  //Stock Mutasi Alih Status / Transfer to BS
  {
    id: "A466",
    name: "StockMutasiAlihStatus-List",
  },
  {
    id: "A467",
    name: "StockMutasiAlihStatus-Detail",
  },
  {
    id: "A468",
    name: "StockMutasiAlihStatus-Create",
  },
  {
    id: "A469",
    name: "StockMutasiAlihStatus-Cancel",
  },

  //BS Back To UU
  {
    id: "A470",
    name: "BSBackToUU-List",
  },
  {
    id: "A471",
    name: "BSBackToUU-Detail",
  },
  {
    id: "A472",
    name: "BSBackToUU-Create",
  },
  {
    id: "A473",
    name: "BSBackToUU-Cancel",
  },

  //BS Reservation
  {
    id: "A474",
    name: "BSReservation-List",
  },
  {
    id: "A475",
    name: "BSReservation-Detail",
  },
  {
    id: "A476",
    name: "BSReservation-Create",
  },
  {
    id: "A477",
    name: "BSReservation-Edit",
  },
  {
    id: "A478",
    name: "BSReservation-Cancel",
  },
  {
    id: "A479",
    name: "BSReservation-Approve",
  },

  //Stock Mutasi GI From Storage / reserve to transit
  {
    id: "A454",
    name: "StockMutasiGIFromStorage-List",
  },
  {
    id: "A455",
    name: "StockMutasiGIFromStorage-Detail",
  },
  {
    id: "A456",
    name: "StockMutasiGIFromStorage-Create",
  },
  {
    id: "A457",
    name: "StockMutasiGIFromStorage-Cancel",
  },

  //Stock Mutasi Place In Storage /transit to uu
  {
    id: "A458",
    name: "StockMutasiPlaceInStorage-List",
  },
  {
    id: "A459",
    name: "StockMutasiPlaceInStorage-Detail",
  },
  {
    id: "A460",
    name: "StockMutasiPlaceInStorage-Create",
  },
  {
    id: "A461",
    name: "StockMutasiPlaceInStorage-Cancel",
  },

  //Stock Mutasi Transfer to GS
  {
    id: "A462",
    name: "TransferToGS-List",
  },
  {
    id: "A463",
    name: "TransferToGS-Detail",
  },
  {
    id: "A464",
    name: "TransferToGS-Create",
  },
  {
    id: "A465",
    name: "TransferToGS-Cancel",
  },

  //Stock Adjustment
  {
    id: "A84",
    name: "StockAdjustment-List",
  },
  {
    id: "A85",
    name: "StockAdjustment-Detail",
  },
  {
    id: "A86",
    name: "StockAdjustment-Create",
  },
  {
    id: "A87",
    name: "StockAdjustment-Edit",
  },
  {
    id: "A88",
    name: "StockAdjustment-Approve",
  },

  //Stock Reservation
  {
    id: "A89",
    name: "StockReservation-List",
  },
  {
    id: "A90",
    name: "StockReservation-Detail",
  },
  {
    id: "A91",
    name: "StockReservation-Create",
  },
  {
    id: "A92",
    name: "StockReservation-Edit",
  },
  {
    id: "A93",
    name: "StockReservation-Cancel",
  },
  {
    id: "A94",
    name: "StockReservation-Approve",
  },
  //GI Disposal
  {
    id: "A95",
    name: "GIDisposal-List",
  },
  {
    id: "A96",
    name: "GIDisposal-Detail",
  },
  {
    id: "A97",
    name: "GIDisposal-Create",
  },
  {
    id: "A98",
    name: "GIDisposal-Cancel",
  },
  // Master Customer
  {
    id: "A142",
    name: "MasterCustomer-List",
  },
  {
    id: "A143",
    name: "MasterCustomer-Detail",
  },
  {
    id: "A286",
    name: "MasterDataCustomer-Download",
  },
  {
    id: "A144",
    name: "MasterCustomer-Edit",
  },
  // Master Customer NOO
  {
    id: "A99",
    name: "MasterCustomerNOO-List",
  },
  {
    id: "A100",
    name: "MasterCustomerNOO-Detail",
  },
  {
    id: "A101",
    name: "MasterCustomerNOO-Edit",
  },
  {
    id: "A226",
    name: "MasterCustomerNOO-Create",
  },
  {
    id: "A102",
    name: "MasterCustomerNOO-Approve",
  },

  {
    id: "A287",
    name: "MasterDataCustomerNOO-Download",
  },

  //Customer Approval Credit Limit
  {
    id: "A103",
    name: "CustomerApprovalCreditLimit-List",
  },
  {
    id: "A104",
    name: "CustomerApprovalCreditLimit-Detail",
  },
  {
    id: "A105",
    name: "CustomerApprovalCreditLimit-Approve",
  },
  {
    id: "A288",
    name: "CustomerApprovalCreditLimit-Download",
  },

  //Master Rules
  {
    id: "A384",
    name: "MasterDataRules-Download",
  },

  //Master Check Rule
  {
    id: "A390",
    name: "MasterDataCheckRule-Download",
  },

  //Master Risk Class
  {
    id: "A396",
    name: "MasterDataRiskClass-Download",
  },

  //Master Inco Term
  {
    id: "A402",
    name: "MasterDataIncoTerm-Download",
  },

  // Master Salesman
  {
    id: "A195",
    name: "MasterSalesman-List",
  },
  {
    id: "A196",
    name: "MasterSalesman-Edit",
  },

  {
    id: "A302",
    name: "MasterSalesman-Download",
  },

  {
    id: "A352",
    name: "MasterSalesman-Create",
  },

  {
    id: "A363",
    name: "MasterSalesman-ActiveInactive",
  },

  // Transportation Driver
  {
    id: "A198",
    name: "TransportationDriver-List",
  },
  {
    id: "A199",
    name: "TransportationDriver-Detail",
  },
  {
    id: "A200",
    name: "TransportationDriver-Create",
  },
  {
    id: "A201",
    name: "TransportationDriver-Edit",
  },
  {
    id: "A202",
    name: "TransportationDriver-Activate/Unactivate",
  },

  {
    id: "A304",
    name: "TransportationDriver-Download",
  },

  // Transportation Vehicle
  {
    id: "A203",
    name: "TransportationVehicle-List",
  },
  {
    id: "A204",
    name: "TransportationVehicle-Detail",
  },
  {
    id: "A205",
    name: "TransportationVehicle-Create",
  },
  {
    id: "A206",
    name: "TransportationVehicle-Edit",
  },
  {
    id: "A207",
    name: "TransportationVehicle-Activate/Unactivate",
  },

  {
    id: "A305",
    name: "TransportationVehicle-Download",
  },
  // Transportation Vehicle Type
  {
    id: "A208",
    name: "TransportationVehicleType-List",
  },
  {
    id: "A209",
    name: "TransportationVehicleType-Detail",
  },
  {
    id: "A210",
    name: "TransportationVehicleType-Create",
  },
  {
    id: "A211",
    name: "TransportationVehicleType-Edit",
  },
  {
    id: "A212",
    name: "TransportationVehicleType-Activate/Unactivate",
  },
  {
    id: "A306",
    name: "TransportationVehicleType-Download",
  },

  {
    id: "A408",
    name: "MasterDataShippingType-Download",
  },

  {
    id: "A414",
    name: "MasterDataModeOfTransportation-Download",
  },

  {
    id: "A420",
    name: "MasterDataShippingCondition-Download",
  },

  {
    id: "A426",
    name: "MasterDataTransportationGroup-Download",
  },
  //Transportation Tzone to Branch
  {
    id: "A217",
    name: "TransportationTzoneToBranch-List",
  },
  {
    id: "A218",
    name: "TransportationTzoneToBranch-Detail",
  },
  {
    id: "A219",
    name: "TransportationTzoneToBranch-AddTzone",
  },
  {
    id: "A220",
    name: "TransportationTzoneToBranch-RemoveTzone",
  },

  {
    id: "A309",
    name: "TransportationTzoneToBranch-Download",
  },
  //master country
  {
    id: "A268",
    name: "MasterDataCountry-Download",
  },

  {
    id: "A312",
    name: "MasterDataCountry-Create",
  },

  {
    id: "A330",
    name: "MasterDataCountry-Edit",
  },

  //master company
  {
    id: "A269",
    name: "MasterDataCompany-Download",
  },
  {
    id: "A313",
    name: "MasterDataCompany-Create",
  },
  {
    id: "A331",
    name: "MasterDataCompany-Edit",
  },

  //master sales org
  {
    id: "A270",
    name: "MasterDataSalesOrg-Download",
  },
  {
    id: "A314",
    name: "MasterDataSalesOrg-Create",
  },
  {
    id: "A332",
    name: "MasterDataSalesOrg-Edit",
  },

  //master sales office
  {
    id: "A271",
    name: "MasterDataSalesOffice-Download",
  },

  {
    id: "A315",
    name: "MasterDataSalesOffice-Create",
  },

  {
    id: "A333",
    name: "MasterDataSalesOffice-Edit",
  },

  //master sales group
  {
    id: "A272",
    name: "MasterDataSalesGroup-Download",
  },

  {
    id: "A316",
    name: "MasterDataSalesGroup-Create",
  },

  {
    id: "A334",
    name: "MasterDataSalesGroup-Edit",
  },

  //master channel
  {
    id: "A273",
    name: "MasterDataChannel-Download",
  },

  {
    id: "A317",
    name: "MasterDataChannel-Create",
  },

  {
    id: "A335",
    name: "MasterDataChannel-Edit",
  },

  //master customer group
  {
    id: "A274",
    name: "MasterDataCustomerGroup-Download",
  },

  {
    id: "A318",
    name: "MasterDataCustomerGroup-Create",
  },

  {
    id: "A336",
    name: "MasterDataCustomerGroup-Edit",
  },

  //master customer group 1
  {
    id: "A275",
    name: "MasterDataCustomerGroup1-Download",
  },

  {
    id: "A319",
    name: "MasterDataCustomerGroup1-Create",
  },
  {
    id: "A337",
    name: "MasterDataCustomerGroup1-Edit",
  },

  //master customer group 2
  {
    id: "A276",
    name: "MasterDataCustomerGroup2-Download",
  },
  {
    id: "A320",
    name: "MasterDataCustomerGroup2-Create",
  },
  {
    id: "A338",
    name: "MasterDataCustomerGroup2-Edit",
  },

  //master customer group 3
  {
    id: "A277",
    name: "MasterDataCustomerGroup3-Download",
  },
  {
    id: "A321",
    name: "MasterDataCustomerGroup3-Create",
  },
  {
    id: "A339",
    name: "MasterDataCustomerGroup3-Edit",
  },

  //master customer group 4
  {
    id: "A278",
    name: "MasterDataCustomerGroup4-Download",
  },
  {
    id: "A322",
    name: "MasterDataCustomerGroup4-Create",
  },
  {
    id: "A340",
    name: "MasterDataCustomerGroup4-Edit",
  },

  //master customer group5
  {
    id: "A279",
    name: "MasterDataCustomerGroup5-Download",
  },

  {
    id: "A323",
    name: "MasterDataCustomerGroup5-Create",
  },

  {
    id: "A341",
    name: "MasterDataCustomerGroup5-Edit",
  },

  //master region
  {
    id: "A280",
    name: "MasterDataRegion-Download",
  },

  {
    id: "A324",
    name: "MasterDataRegion-Create",
  },

  {
    id: "A342",
    name: "MasterDataRegion-Edit",
  },

  //master District
  {
    id: "A281",
    name: "MasterDataDistrict-Download",
  },

  {
    id: "A325",
    name: "MasterDataDistrict-Create",
  },

  {
    id: "A343",
    name: "MasterDataDistrict-Edit",
  },

  //master Branch
  {
    id: "A282",
    name: "MasterDataBranch-Download",
  },

  {
    id: "A326",
    name: "MasterDataBranch-Create",
  },

  {
    id: "A344",
    name: "MasterDataBranch-Edit",
  },

  //master Term Of Payment
  {
    id: "A283",
    name: "MasterDataTermOfPayment-Download",
  },

  {
    id: "A327",
    name: "MasterDataTermOfPayment-Create",
  },

  {
    id: "A345",
    name: "MasterDataTermOfPayment-Edit",
  },

  //master Price List
  {
    id: "A284",
    name: "MasterDataPriceList-Download",
  },

  {
    id: "A328",
    name: "MasterDataPriceList-Create",
  },

  {
    id: "A346",
    name: "MasterDataPriceList-Edit",
  },

  //master Currency
  {
    id: "A285",
    name: "MasterDataCurrency-Download",
  },

  {
    id: "A329",
    name: "MasterDataCurrency-Create",
  },

  {
    id: "A347",
    name: "MasterDataCurrency-Edit",
  },

  //master Condition Type
  {
    id: "A378",
    name: "MasterDataConditionType-Download",
  },

  //master Product Uom
  {
    id: "A389",
    name: "MasterDataProductUom-Download",
  },

  {
    id: "A348",
    name: "MasterDataProductUom-Create",
  },

  {
    id: "A359",
    name: "MasterDataProductUom-Edit",
  },

  //master Product Category & Brand
  {
    id: "A290",
    name: "MasterDataProductCategoryBrand-Download",
  },

  //master Product Division
  {
    id: "A291",
    name: "MasterDataProductDivision-Download",
  },

  {
    id: "A349",
    name: "MasterDataProductDivision-Create",
  },

  {
    id: "A360",
    name: "MasterDataProductDivision-Edit",
  },

  //master Product
  {
    id: "A292",
    name: "MasterDataProduct-Download",
  },

  {
    id: "A350",
    name: "MasterDataProduct-Create",
  },

  {
    id: "A361",
    name: "MasterDataProduct-Edit",
  },

  {
    id: "A361",
    name: "MasterDataProduct-Status",
  },

  //master Product Branch
  {
    id: "A293",
    name: "MasterDataProductBranch-Download",
  },

  {
    id: "A351",
    name: "MasterDataProductBranch-Create",
  },

  {
    id: "A362",
    name: "MasterDataProductBranch-Remove",
  },

  //master Salesman Group
  {
    id: "A303",
    name: "MasterDataSalesmanGroup-Download",
  },

  {
    id: "A353",
    name: "MasterDataSalesmanGroup-Create",
  },

  {
    id: "A364",
    name: "MasterDataSalesmanGroup-Edit",
  },

  //master Transportation Zona
  {
    id: "A307",
    name: "MasterDataTransportationZone-Download",
  },

  {
    id: "A354",
    name: "MasterDataTransportationZone-Create",
  },

  {
    id: "A365",
    name: "MasterDataTransportationZone-Edit",
  },

  //master Transportation route
  {
    id: "A308",
    name: "MasterDataRoute-Download",
  },

  {
    id: "A355",
    name: "MasterDataRoute-Create",
  },

  {
    id: "A366",
    name: "MasterDataRoute-Edit",
  },

  //master Transportation route
  {
    id: "A450",
    name: "MasterDataRouteDetermination-Download",
  },

  {
    id: "A372",
    name: "MasterDataPriceGroup-Download",
  },

  //Price By Customer
  {
    id: "A310",
    name: "MasterDataPriceByCustomer-Download",
  },
  {
    id: "A357",
    name: "MasterDataPriceByCustomer-Create",
  },
  {
    id: "A368",
    name: "MasterDataPriceByCustomer-Edit",
  },
  {
    id: "A368",
    name: "MasterDataPriceByCustomer-Status",
  },

  //Price By Price Group
  {
    id: "A311",
    name: "MasterDataPriceByPriceGroup-Download",
  },
  {
    id: "A358",
    name: "MasterDataPriceByPriceGroup-Create",
  },
  {
    id: "A369",
    name: "MasterDataPriceByPriceGroup-Edit",
  },
  {
    id: "A369",
    name: "MasterDataPriceByPriceGroup-Status",
  },

  //Product Grouping
  { id: "A294", name: "ProductGroupingPrincipal-Download" },
  { id: "A295", name: "ProductGroupingBrand-Download" },
  { id: "A296", name: "ProductGroupingCategory-Download" },
  { id: "A297", name: "ProductGroupingNPL-Download" },
  { id: "A298", name: "ProductGroupingRetailPrice-Download" },
  { id: "A299", name: "ProductGroupingGramation-Download" },
  { id: "A300", name: "ProductGroupingDivision-Download" },
  { id: "A301", name: "ProductGroupingOthers-Download" },
];
