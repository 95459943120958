import React from "react";
import { Modal } from "reactstrap";
import "./loading.module.scss";

const Loading = ({ isLoading, text}) => {
  return (
    <Modal isOpen={isLoading}>
      <>
        <div className="col-md-12 pt-4 mt-3 whirl ringed" />
        <div className="col-md-12 text-center mt-3">{text}</div>
      </>
    </Modal>
  );
};

Loading.defaultProps = {
  isLoading: false,
  text: "Loading",};

export default Loading;
