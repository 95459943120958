import Cookies from "js-cookie";

export * from "./misc";

export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";

export const IS_IT_USER = (() => {
  const user = Cookies.get("userData") || "{}";
  const userData = JSON?.parse(user);
  const isIT =
    userData?.role === 1 || userData?.role === 2 || userData?.role === 3;
  return isIT;
})();

export const API_TYPE = {
  SAP: "SAP",
  EDS: "EDS",
};

export const QZ_PRINTER_NAME = () =>
  localStorage.getItem("printerName") ||
  process.env.REACT_APP_QZ_PRINTER_NAME ||
  "Adobe PDF";

export const VERSION = "Version: 1.35";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 75, 100];
export const SORT_AZ = "asc";
export const SORT_ZA = "desc";
export const SEARCH_KEYWORD_LENGTH = 3;
export const MAX_LIMIT = 9999;

export const getTitleInvoiceByDocType = (type) => {
  if (type === "ZRP1") return "RETUR BARANG";
  if (type === "ZWE1") return "RETUR BARANG";
  if (type === "ZRP2") return "RETUR BARANG";

  return "INVOICE";
};
