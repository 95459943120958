import QueryString from "query-string";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { listPermissionActionData } from "./ActionPermission";
import { terbilang as getTerbilang } from "utilities/formatter/string";

export function checkRole() {
  let role = false;
  let userData = Cookies.get("userData");
  if (userData) {
    userData = JSON.parse(userData);
    role = userData.role;
  }
  return role;
}

export function concatString(...str) {
  return str.join(' - ')
}

export function getLoginData() {
  let userData = false;
  userData = Cookies.get("userData");
  if (userData) {
    userData = JSON.parse(userData);
  } else {
    userData = false;
  }
  return userData;
}

export function getPermissionData() {
  let pad = false;
  pad = Cookies.get("pad");
  if (pad) {
    pad = JSON.parse(pad);
  } else {
    pad = false;
  }
  return pad;
}

export function getPemissionAccessData() {
  let pad = false;
  pad = Cookies.get("pad");
  if (pad) {
    pad = JSON.parse(pad).split(",");
  } else {
    pad = false;
  }
  return pad;
}

export function tempPayloadLogistic(data) {
  const dataFilter = data ? [...data] : [];
  let payload = {
    page: 1,
    limit: 10,
    filters: [...dataFilter],
  };
  return payload;
}

export function getFilterbyRole(paramComp, paramBranch) {
  let userData = false;
  userData = Cookies.get("userData");
  let dataFilter = [];
  if (userData) {
    userData = JSON.parse(userData);
    const { role, company_id, branch_id } = userData;
    if (role === 2) {
      if (paramComp) {
        dataFilter.push({ field: paramComp, keyword: company_id });
      }
    } else if (role === 3) {
      if (paramComp) {
        dataFilter.push({ field: paramComp, keyword: company_id });
      }
      if (paramBranch) {
        dataFilter.push({ field: paramBranch, keyword: branch_id });
      }
    }
  }

  return dataFilter;
}

export const filterUniqueOptions = (options, paramKeyObj) => {
  const filterUniqueID = [];
  const keyObj = paramKeyObj ? paramKeyObj : "value";
  options.map((item) => {
    const indexItemID = filterUniqueID.findIndex(
      (x) => x[keyObj] === item[keyObj]
    );
    if (indexItemID < 0) {
      filterUniqueID.push(item);
    }
    return null;
  });
  return filterUniqueID;
};

export function convertDate(date) {
  const tempDate = new Intl.DateTimeFormat("en-US").format(date);
  const [month, day, year] = tempDate.split("/");
  const newDate = [day, month, year].join("/");
  return newDate;
}

export function copyToClipboard(
  string,
  callbackSuccess = () => {},
  callbackFail = () => {}
) {
  const textArea = document.createElement("textarea");

  textArea.value = string;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
    callbackSuccess();
  } catch (err) {
    callbackFail();
  }

  document.body.removeChild(textArea);
}

export function parseURL(string, options) {
  const parsed = QueryString.parseUrl(string, { ...options });
  return parsed.query;
}

export function currencyMask(amount, currency, separator) {
  let number = amount;
  const dataCurrency = currency ? currency + " " : "";

  if (isNaN(number)) {
    number = 0;
  }

  number = Number(number);
  const hasDecimal = number % 1 !== 0 ? true : false;
  let currencyMaskValue = "";
  if (hasDecimal) {
    const integerNumber = parseInt(number, 10);

    const decimal = (number - integerNumber).toFixed(3) * 1000;
    const formatNumber = integerNumber
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    currencyMaskValue = dataCurrency.concat(formatNumber) + "." + decimal;
  } else {
    const formatNumber = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    currencyMaskValue = dataCurrency.concat(formatNumber);
  }

  return currencyMaskValue;
}

// export function terbilang(bilangan) {
//   bilangan = bilangan.toString();
//   let angka = [
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//   ];
//   let kata = [
//     "",
//     "Satu",
//     "Dua",
//     "Tiga",
//     "Empat",
//     "Lima",
//     "Enam",
//     "Tujuh",
//     "Delapan",
//     "Sembilan",
//   ];
//   let tingkat = ["", "Ribu", "Juta", "Milyar", "Triliun"];

//   let panjang_bilangan = bilangan.length;

//   /* pengujian panjang bilangan */
//   if (panjang_bilangan > 15) {
//     const String = "Diluar Batas";
//     return String;
//   }

//   /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
//   for (let i = 1; i <= panjang_bilangan; i++) {
//     angka[i] = bilangan.substr(-i, 1);
//   }

//   let i = 1;
//   let j = 0;
//   let String = "";

//   /* mulai proses iterasi terhadap array angka */
//   while (i <= panjang_bilangan) {
//     let subString = "";
//     let kata1 = "";
//     let kata2 = "";
//     let kata3 = "";

//     /* untuk Ratusan */
//     if (angka[i + 2] !== "0") {
//       if (angka[i + 2] === "1") {
//         kata1 = "Seratus";
//       } else {
//         kata1 = kata[angka[i + 2]] + " Ratus";
//       }
//     }

//     /* untuk Puluhan atau Belasan */
//     if (angka[i + 1] !== "0") {
//       if (angka[i + 1] === "1") {
//         if (angka[i] === "0") {
//           kata2 = "Sepuluh";
//         } else if (angka[i] === "1") {
//           kata2 = "Sebelas";
//         } else {
//           kata2 = kata[angka[i]] + " Belas";
//         }
//       } else {
//         kata2 = kata[angka[i + 1]] + " Puluh";
//       }
//     }

//     /* untuk Satuan */
//     if (angka[i] !== "0") {
//       if (angka[i + 1] !== "1") {
//         kata3 = kata[angka[i]];
//       }
//     }

//     /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
//     if (angka[i] !== "0" || angka[i + 1] !== "0" || angka[i + 2] !== "0") {
//       subString = kata1 + " " + kata2 + " " + kata3 + " " + tingkat[j] + " ";
//     }

//     /* gabungkan variabe sub String (untuk Satu blok 3 angka) ke variabel String */
//     String = subString + String;
//     i = i + 3;
//     j = j + 1;
//   }

//   /* mengganti Satu Ribu jadi Seribu jika diperlukan */
//   if (angka[5] === "0" && angka[6] === "0") {
//     String = String.replace("Satu Ribu", "Seribu");
//   }

//   return String + "Rupiah";
// }

export const terbilang = (bilangan) => getTerbilang(bilangan) + " Rupiah";

export function renderMonthName(data) {
  const dataMonth = [
    { id: 1, name: "Jan", fullname: "January" },
    { id: 2, name: "Feb", fullname: "February" },
    { id: 3, name: "Mar", fullname: "March" },
    { id: 4, name: "Apr", fullname: "April" },
    { id: 5, name: "May", fullname: "May" },
    { id: 6, name: "Jun", fullname: "June" },
    { id: 7, name: "Jul", fullname: "July" },
    { id: 8, name: "Aug", fullname: "August" },
    { id: 9, name: "Sep", fullname: "September" },
    { id: 10, name: "Oct", fullname: "October" },
    { id: 11, name: "Nov", fullname: "November" },
    { id: 12, name: "Dec", fullname: "December" },
  ];
  const index = dataMonth.findIndex((x) => x.id === parseInt(data, 10));

  return dataMonth[index].name;
}

export function convertTimeZone(utcDate, diffHour) {
  let hour = diffHour;
  if (!hour) {
    hour = 7;
  }
  // console.log(hour)
  const dateToTime = new Date(utcDate).getTime() + hour * 60 * 60 * 1000;
  // console.log(new Date(utcDate).getTime())
  // console.log((hour * 60 * 60 * 1000))
  // console.log(dateToTime)

  const convertedTimeZone = new Date(dateToTime).toISOString();
  // console.log(convertedTimeZone)
  // console.log(utcDate)
  const year = convertedTimeZone.substr(0, 4);
  const month = convertedTimeZone.substr(5, 2);
  const monthName = renderMonthName(month);
  const day = convertedTimeZone.substr(8, 2);
  return `${day} ${monthName} ${year}`;
}

export function convertTimeZoneWithHours(utcDate, diffHour) {
  let hour = diffHour || 7;  // Default to 7 if no hour is provided
  
  const dateToTime = new Date(utcDate).getTime() + hour * 60 * 60 * 1000;
  const convertedTimeZone = new Date(dateToTime).toISOString();

  const year = convertedTimeZone.substr(0, 4);
  const month = convertedTimeZone.substr(5, 2);
  const monthName = renderMonthName(month);
  const day = convertedTimeZone.substr(8, 2);

  // Extract time in hours and minutes
  const hours = convertedTimeZone.substr(11, 2);
  const minutes = convertedTimeZone.substr(14, 2);

  // Return formatted date and time
  return `${day} ${monthName} ${year} ${hours}:${minutes}`;
}

export function convertToUtc(date, diffHour) {
  let hour = diffHour;
  if (!hour) {
    hour = 7;
  }
  const gmt = parseInt(new Date().toString().substr(28, 3), 10);
  hour = hour - gmt;
  const dateToTime = new Date(date).getTime() - hour * 60 * 60 * 1000;
  const convertedTimeZone = new Date(dateToTime).toISOString();
  // console.log(convertedTimeZone)
  return convertedTimeZone;
}

export function adjustTimeZone(date, diffHour) {
  let hour = diffHour;
  if (!hour) {
    hour = 7;
  }
  const dateToTime = new Date(date).getTime() - hour * 60 * 60 * 1000;

  const newDate = format(new Date(dateToTime), "yyyy-MM-dd");
  const newTime = format(new Date(dateToTime), "HH:mm:ss");
  const newFormat = newDate + "T" + newTime;
  return newFormat;
}

export function convertToDiffHour(date, diffHour) {
  let hour = diffHour;
  if (!hour) {
    hour = 7;
  }
  // console.log(date)
  const dateToTime = new Date(date).getTime() + hour * 60 * 60 * 1000;
  const hourNow = new Date().getHours();
  const minuteNow = new Date().getMinutes();
  const secondNow = new Date().getSeconds();
  const newFormat = new Date(
    format(new Date(dateToTime), "yyyy-MM-dd") +
      ` ${hourNow}:${minuteNow}:${secondNow}`
  );
  return newFormat;
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileName = (fullPath) => {
  let filename = "";
  if (fullPath) {
    let startIndex =
      fullPath.indexOf("\\") >= 0
        ? fullPath.lastIndexOf("\\")
        : fullPath.lastIndexOf("/");
    filename = fullPath.substring(startIndex);
    if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
      filename = filename.substring(1);
    }
  }
  return filename;
};

export const sortByKey = (array, key) => {
  return array.sort((a, b) => {
    let x = a[key];
    let y = b[key];

    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const checkActionPermission = (actionName) => {
  let isValidActionPermission = false;
  if (getLoginData().role === 3) {
    const indexAction = listPermissionActionData().findIndex(
      (e) => e.name === actionName
    );
    const idPermissionAction = listPermissionActionData()[indexAction].id;
    const userActionPermssion = getPemissionAccessData();

    if (userActionPermssion) {
      isValidActionPermission =
        userActionPermssion.includes(idPermissionAction);
    }
  } else {
    isValidActionPermission = true;
  }

  return isValidActionPermission;
};

export const checkStandAlone = () => {
  return getLoginData().is_stand_alone;
};

export const formatDateString = (dateString) => {
  // Parse tanggal dari string
  const date = new Date(dateString);

  // Dapatkan hari, bulan (dimulai dari 0, jadi tambahkan 1), dan tahun
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2); // Ambil 2 digit terakhir tahun

  // Gabungkan menjadi format "DDMMYY"
  return `${day}${month}${year}`;
}