import { lazy } from "react";

const CallPlan = lazy(() => import("../../Pages/SFA/CallPlan"))
const CallPlanPattern = lazy(() => import("../../Pages/SFA/CallPlanPattern"))
const DivisionBranch = lazy(() => import("../../Pages/SFA/Division/DivisionBranch"))
const DivisionMaster = lazy(() => import("../../Pages/SFA/Division/DivisionMaster"))
const Promo = lazy(() => import("../../Pages/SFA/Promo"))
const SalesmanDivision = lazy(() => import("../../Pages/SFA/SalesmanDivision"))
const SalesmanDivisionProduct = lazy(() => import("../../Pages/SFA/SalesmanDivisionProduct"))

export default [
  {
    path: "/callplan/list",
    component: CallPlan,
  },
  {
    path: "/callplan/pattern-list",
    component: CallPlanPattern,
  },  
  {
    path: "/division/master",
    component: DivisionMaster,
  },
  {
    path: "/division/branch",
    component: DivisionBranch,
  },
  {
    path: "/promo",
    component: Promo,
  },  
  {
    path: "/salesman/salesman-division",
    component: SalesmanDivision,
  },
  {
    path: "/salesman/division-product",
    component: SalesmanDivisionProduct,
  }  
]