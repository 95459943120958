import { lazy } from "react";

const ListQuotation = lazy(() => import("../Pages/Quotation/ListQuotation"));
const DetailQuotation = lazy(() =>
  import("../Pages/Quotation/DetailQuotation")
);
const CreateQuotation = lazy(() =>
  import("../Pages/Quotation/CreateQuotation")
);
const EditQuotation = lazy(() => import("../Pages/Quotation/EditQuotation"));

const DraftQuotation = lazy(() => import("../Pages/Quotation/DraftQuotation"));

const ListSalesOrder = lazy(() => import("../Pages/SalesOrder/ListSalesOrder"));
const DetailSalesOrder = lazy(() =>
  import("../Pages/SalesOrder/DetailSalesOrder")
);

const ListDeliveryOrder = lazy(() =>
  import("../Pages/DeliveryOrder/ListDeliveryOrder")
);

const DetailDeliveryOrder = lazy(() =>
  import("../Pages/DeliveryOrder/DetailDeliveryOrder")
);

const EditDeliveryOrder = lazy(() =>
  import("../Pages/DeliveryOrder/EditDeliveryOrder")
);
const ListShipment = lazy(() => import("../Pages/Shipment/ListShipment"));

const CreateShipment = lazy(() => import("../Pages/Shipment/CreateShipment"));
const UpdateShipment = lazy(() => import("../Pages/Shipment/UpdateShipment"));
const DetailShipment = lazy(() => import("../Pages/Shipment/DetailShipment"));
const ConfirmDeliveredItem = lazy(() =>
  import("../Pages/Shipment/DetailShipment/ConfirmDeliveredItem")
);

const ListBilling = lazy(() => import("../Pages/Billing/ListBilling"));
const DetailBilling = lazy(() => import("../Pages/Billing/DetailBilling"));

const ListCollection = lazy(() => import("../Pages/Collection/ListCollection"));
const DetailCollection = lazy(() =>
  import("../Pages/Collection/DetailCollection")
);

const ListUndelivered = lazy(() =>
  import("../Pages/Undelivered/ListUndelivered")
);

const DetailUndelivered = lazy(() =>
  import("../Pages/Undelivered/DetailUndelivered")
);

const ListApproval = lazy(() => import("../Pages/Approval/ListApproval"));
const DetailApproval = lazy(() => import("../Pages/Approval/DetailApproval"));

const NewPromotionWizard = lazy(() =>
  import("../Pages/Nadine/CreatePromoWizard")
);
const EditPromotionWizard = lazy(() =>
  import("../Pages/Nadine/CreatePromoWizard/edit")
);
const DraftPromotionWizard = lazy(() =>
  import("../Pages/Nadine/CreatePromoWizard/draft")
);

const ListPromo = lazy(() => import("../Pages/Nadine/ListPromotion"));
const DetailPromo = lazy(() => import("../Pages/Nadine/DetailPromotion"));
const SimulatePromo = lazy(() => import("../Pages/Nadine/CreateSimulation"));

const ListStockRealTime = lazy(() =>
  import("../Pages/Inventory/StockRealTime/ListStockRealTime")
);
const ListInTransit = lazy(() =>
  import("../Pages/Inventory/InTransit/ListInTransit")
);
const ListMaterialDoc = lazy(() =>
  import("../Pages/Inventory/MaterialDoc/ListMaterialDoc")
);
const ListAvailabilityOverview = lazy(() =>
  import("../Pages/Inventory/AvailabilityOverview/ListAvailabilityOverview")
);
const ListMutasiStockGudang = lazy(() =>
  import("../Pages/Inventory/MutasiStockGudang/ListMutasiStockGudang")
);

const ListGRprincipal = lazy(() => import("../Pages/GRPrincipal/ListGR"));
const DetailGRprincipal = lazy(() => import("../Pages/GRPrincipal/DetailGR"));
const CreateGRprincipal = lazy(() => import("../Pages/GRPrincipal/CreateGR"));
const ListGRreturn = lazy(() => import("../Pages/GRPrincipal/ListGRreturn"));
const DetailGRreturn = lazy(() =>
  import("../Pages/GRPrincipal/DetailGRreturn")
);
const CreateGRreturn = lazy(() =>
  import("../Pages/GRPrincipal/CreateGRreturn")
);

const ListIntraPlant_PO_STO = lazy(() =>
  import("../Pages/IntraPlant/PO_STO/ListPO_STO")
);

const CreateIntraPlant_PO_STO = lazy(() =>
  import("../Pages/IntraPlant/PO_STO/CreatePO_STO")
);

const EditIntraPlant_PO_STO = lazy(() =>
  import("../Pages/IntraPlant/PO_STO/EditPO_STO")
);

const DetailIntraPlant_PO_STO = lazy(() =>
  import("../Pages/IntraPlant/PO_STO/DetailPO_STO")
);

const ListIntraPlant_Approval = lazy(() =>
  import("../Pages/IntraPlant/Approval/ListApproval")
);

const DetailIntraPlant_Approval = lazy(() =>
  import("../Pages/IntraPlant/Approval/DetailApproval")
);

const ListIntraPlant_DO_STO = lazy(() =>
  import("../Pages/IntraPlant/DO_STO/ListDO_STO")
);

const CreateIntraPlant_DO_STO = lazy(() =>
  import("../Pages/IntraPlant/DO_STO/CreateDO_STO")
);

const EditIntraPlant_DO_STO = lazy(() =>
  import("../Pages/IntraPlant/DO_STO/EditDO_STO")
);

const DetailIntraPlant_DO_STO = lazy(() =>
  import("../Pages/IntraPlant/DO_STO/DetailDO_STO")
);

const ListIntraPlant_GI = lazy(() => import("../Pages/IntraPlant/GI/ListGI"));

const DetailIntraPlant_GI = lazy(() =>
  import("../Pages/IntraPlant/GI/DetailGI")
);

const ListIntraPlant_GR = lazy(() => import("../Pages/IntraPlant/GR/ListGR"));

const DetailIntraPlant_GR = lazy(() =>
  import("../Pages/IntraPlant/GR/DetailGR")
);

const CreateIntraPlant_GR = lazy(() =>
  import("../Pages/IntraPlant/GR/CreateGR")
);

const SyncGRIntraPlant = lazy(() =>
  import("../Pages/IntraPlant/SyncGRfromSAP")
);

const ListPO_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/PO_STO/ListPO_STO")
);

const CreatePO_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/PO_STO/CreatePO_STO")
);

const DetailPO_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/PO_STO/DetailPO_STO")
);

const ListApproval_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/Approval/ListApproval")
);

const DetailApproval_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/Approval/DetailApproval")
);

const ListGI_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/GI/ListGI")
);

const DetailGI_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/GI/DetailGI")
);

const ListGR_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/GR/ListGR")
);

const DetailGR_IntraChannel = lazy(() =>
  import("../Pages/IntraChannel/GR/DetailGR")
);

const ListStockOpname = lazy(() =>
  import("../Pages/StockOpname/ListStockOpname")
);

const CreateStockOpname = lazy(() =>
  import("../Pages/StockOpname/CreateStockOpname")
);

const EditStockOpname = lazy(() =>
  import("../Pages/StockOpname/EditStockOpname")
);

const DetailStockOpname = lazy(() =>
  import("../Pages/StockOpname/DetailStockOpname")
);

const ListStockReservation = lazy(() =>
  import("../Pages/StockReservation/ListStockReservation")
);

const CreateStockReservation = lazy(() =>
  import("../Pages/StockReservation/CreateStockReservation")
);

const EditStockReservation = lazy(() =>
  import("../Pages/StockReservation/EditStockReservation")
);

const DetailStockReservation = lazy(() =>
  import("../Pages/StockReservation/DetailStockReservation")
);

const ListBSReservation = lazy(() => import("../Pages/BSReservation/ListBSReservation"));

const CreateBSReservation = lazy(() =>
  import("../Pages/BSReservation/CreateBSReservation")
);

const EditBSReservation = lazy(() => import("../Pages/BSReservation/EditBSReservation"));

const DetailBSReservation = lazy(() =>
  import("../Pages/BSReservation/DetailBSReservation")
);

const ListGIDisposal = lazy(() => import("../Pages/GIDisposal/ListGIDisposal"));

const CreateGIDisposal = lazy(() =>
  import("../Pages/GIDisposal/CreateGIDisposal")
);

const DetailGIDisposal = lazy(() =>
  import("../Pages/GIDisposal/DetailGIDisposal")
);

const ListSwapHandling = lazy(() =>
  import("../Pages/StockMutasi/SwapHandling/ListSwapHandling")
);

const CreateSwapHandling = lazy(() =>
  import("../Pages/StockMutasi/SwapHandling/CreateSwapHandling")
);

const DetailSwapHandling = lazy(() =>
  import("../Pages/StockMutasi/SwapHandling/DetailSwapHandling")
);

const ListCanvasToGS = lazy(() =>
  import("../Pages/StockMutasi/CanvasToGS/ListCanvasToGS")
);

const CreateCanvasToGS = lazy(() =>
  import("../Pages/StockMutasi/CanvasToGS/CreateCanvasToGS")
);

const DetailCanvasToGS = lazy(() =>
  import("../Pages/StockMutasi/CanvasToGS/DetailCanvasToGS")
);

const ListAlihStatus = lazy(() =>
  import("../Pages/StockMutasi/AlihStatus/ListAlihStatus")
);

const CreateAlihStatus = lazy(() =>
  import("../Pages/StockMutasi/AlihStatus/CreateAlihStatus")
);

const DetailAlihStatus = lazy(() =>
  import("../Pages/StockMutasi/AlihStatus/DetailAlihStatus")
);

const ListBSBackToUU = lazy(() =>
  import("../Pages/StockMutasi/BSBackToUU/ListBSBackToUU")
);

const CreateBSBackToUU = lazy(() =>
  import("../Pages/StockMutasi/BSBackToUU/CreateBSBackToUU")
);

const DetailBSBackToUU = lazy(() =>
  import("../Pages/StockMutasi/BSBackToUU/DetailBSBackToUU")
);

const ListGIFromStorage = lazy(() =>
  import("../Pages/StockMutasi/GIFromStorage/ListGIFromStorage")
);

const CreateGIFromStorage = lazy(() =>
  import("../Pages/StockMutasi/GIFromStorage/CreateGIFromStorage")
);

const DetailGIFromStorage = lazy(() =>
  import("../Pages/StockMutasi/GIFromStorage/DetailGIFromStorage")
);

const ListPlaceInStorage = lazy(() =>
  import("../Pages/StockMutasi/PlaceInStorage/ListPlaceInStorage")
);

const CreatePlaceInStorage = lazy(() =>
  import("../Pages/StockMutasi/PlaceInStorage/CreatePlaceInStorage")
);

const DetailPlaceInStorage = lazy(() =>
  import("../Pages/StockMutasi/PlaceInStorage/DetailPlaceInStorage")
);

const ListPO_IntraSloc = lazy(() =>
  import("../Pages/IntraSloc/PO_STO/ListPO_STO")
);

const CreatePO_IntraSloc = lazy(() =>
  import("../Pages/IntraSloc/PO_STO/CreatePO_STO")
);

const DetailPO_IntraSloc = lazy(() =>
  import("../Pages/IntraSloc/PO_STO/DetailPO_STO")
);

const ListGI_IntraSloc = lazy(() => import("../Pages/IntraSloc/GI/ListGI"));
const DetailGI_IntraSloc = lazy(() => import("../Pages/IntraSloc/GI/DetailGI"));

const ListGR_IntraSloc = lazy(() => import("../Pages/IntraSloc/GR/ListGR"));
const DetailGR_IntraSloc = lazy(() => import("../Pages/IntraSloc/GR/DetailGR"));

const ListReportSwapHandling = lazy(() =>
  import("../Pages/Inventory/SwapHandling/ListSwapHandling")
);

// Master Data
//Base
const listMasterCompany = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Company/ListCompany")
);

const listMasterCountry = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Country/ListCountry")
);

const listMasterSalesOrg = lazy(() =>
  import("../Pages/MasterData/BaseMaster/SalesOrg/ListSalesOrg")
);

const listMasterSalesOffice = lazy(() =>
  import("../Pages/MasterData/BaseMaster/SalesOffice/ListSalesOffice")
);

const listMasterSalesGroup = lazy(() =>
  import("../Pages/MasterData/BaseMaster/SalesGroup/ListSalesGroup")
);

const listMasterChannel = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Channel/ListChannel")
);

const listMasterCustomerGroup = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup/ListCustomerGroup")
);

const listMasterCustomerGroup1 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup1/ListCustomerGroup1")
);

const listMasterCustomerGroup2 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup2/ListCustomerGroup2")
);

const listMasterCustomerGroup3 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup3/ListCustomerGroup3")
);

const listMasterCustomerGroup4 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup4/ListCustomerGroup4")
);

const listMasterCustomerGroup5 = lazy(() =>
  import("../Pages/MasterData/BaseMaster/CustomerGroup5/ListCustomerGroup5")
);

const listMasterRegion = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Region/ListRegion")
);

const listMasterDistrict = lazy(() =>
  import("../Pages/MasterData/BaseMaster/District/ListDistrict")
);

const listMasterBranch = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Branch/ListBranch")
);

const listMasterCurrency = lazy(() =>
  import("../Pages/MasterData/BaseMaster/Currency/ListCurrency")
);

const listMasterTermOfPayment = lazy(() =>
  import("../Pages/MasterData/BaseMaster/TermOfPayment/ListTermOfPayment")
);

const listMasterPriceList = lazy(() =>
  import("../Pages/MasterData/BaseMaster/PriceList/ListPriceList")
);

const ListMasterConditionType = lazy(() =>
  import("../Pages/MasterData/BaseMaster/ConditionType/ListConditionType")
);

const listMasterSalesman = lazy(() =>
  import("../Pages/MasterData/Sales/Salesman/ListSalesman")
);

const listMasterSalesmanBranch = lazy(() =>
  import("../Pages/MasterData/Sales/SalesmanBranch/ListSalesmanBranch")
);

const listMasterSalesmanGroup = lazy(() =>
  import("../Pages/MasterData/Sales/SalesmanGroup/ListSalesmanGroup")
);

const listMasterCustomer = lazy(() =>
  import("../Pages/MasterData/Customer/Customer/ListCustomer")
);

const detailMasterCustomer = lazy(() =>
  import("../Pages/MasterData/Customer/Customer/DetailCustomer")
);

const editMasterCustomer = lazy(() =>
  import("../Pages/MasterData/Customer/Customer/EditCustomer")
);

const listMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/ListCustomer")
);

const createMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/CreateCustomer")
);

const editMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/EditCustomer")
);

const detailMasterCustomerNoo = lazy(() =>
  import("../Pages/MasterData/Customer/CustomerNoo/DetailCustomer")
);

const listMasterApprovalCreditLimit = lazy(() =>
  import(
    "../Pages/MasterData/Customer/ApprovalCreditLimit/ListApprovalCreditLimit"
  )
);

const listMasterIncoTerm = lazy(() =>
  import("../Pages/MasterData/Customer/IncoTerm/ListIncoTerm")
);

const listMasterRules = lazy(() =>
  import("../Pages/MasterData/Customer/Rules/ListRules")
);

const listMasterCheckRule = lazy(() =>
  import("../Pages/MasterData/Customer/CheckRule/ListCheckRule")
);

const listMasterRiskClass = lazy(() =>
  import("../Pages/MasterData/Customer/RiskClass/ListRiskClass")
);

const listMasterPriceGroup = lazy(() =>
  import("../Pages/MasterData/Pricing/PriceGroup/ListPriceGroup")
);

const listMasterCustomerSalesOrg = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByCustomer/ListSalesOrg")
);

const listMasterPricingCustomer = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByCustomer/ListCustomer")
);

const listMasterPricingByCustomer = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByCustomer/ListPricingByCustomer")
);

const listMasterPriceGroupSalesOrg = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByPriceGroup/ListSalesOrg")
);

const listMasterPricingPriceGroup = lazy(() =>
  import("../Pages/MasterData/Pricing/PricingByPriceGroup/ListPriceGroup")
);

const listMasterPricingByPriceGroup = lazy(() =>
  import(
    "../Pages/MasterData/Pricing/PricingByPriceGroup/ListPricingByPriceGroup"
  )
);

//Transportaion
const listMasterDriver = lazy(() =>
  import("../Pages/MasterData/Transportation/Driver/ListDriver")
);

const listMasterVehicle = lazy(() =>
  import("../Pages/MasterData/Transportation/Vehicle/ListVehicle")
);

const listMasterVehicleType = lazy(() =>
  import("../Pages/MasterData/Transportation/VehicleType/ListVehicleType")
);

const listMasterTransportationGroup = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/TransportationGroup/ListTransportationGroup"
  )
);

const listMasterShippingCondition = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/ShippingCondition/ListShippingCondition"
  )
);

const listMasterModeOfTransportation = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/ModeOfTransportation/ListModeOfTransportation"
  )
);

const listMasterShippingType = lazy(() =>
  import("../Pages/MasterData/Transportation/ShippingType/ListShippingType")
);

const listMasterTransportationZone = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/TransportationZone/ListTransportationZone"
  )
);

const listMasterBranchTzone = lazy(() =>
  import("../Pages/MasterData/Transportation/TzoneToBranch/ListBranch")
);
const listMasterTzoneToBranch = lazy(() =>
  import("../Pages/MasterData/Transportation/TzoneToBranch/ListTzone")
);

const listMasterProductUom = lazy(() =>
  import("../Pages/MasterData/Product/Uom/ListUom")
);

const listMasterProductCategory = lazy(() =>
  import("../Pages/MasterData/Product/ProductCategory/ListProductCategory")
);

const listMasterProductBrand = lazy(() =>
  import("../Pages/MasterData/Product/ProductBrand/ListProductBrand")
);

const listMasterProductPrincipal = lazy(() =>
  import("../Pages/MasterData/Product/ProductPrincipal/ListProductPrincipal")
);

const listMasterProductCategoryBrand = lazy(() =>
  import("../Pages/MasterData/Product/CategoryBrand/ListCategoryBrand")
);

const listMasterProductBranch = lazy(() =>
  import("../Pages/MasterData/Product/ProductBranch/ListBranch")
);

const listMasterProductToBranch = lazy(() =>
  import("../Pages/MasterData/Product/ProductBranch/ListProductToBranch")
);
const listMasterProductDivision = lazy(() =>
  import("../Pages/MasterData/Product/Division/ListDivision")
);

const listMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/ListProduct")
);

const detailMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/DetailProduct")
);

const editMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/EditProduct")
);

const createMasterProduct = lazy(() =>
  import("../Pages/MasterData/Product/Product/CreateProduct")
);

const listMasterGroupCategory = lazy(() =>
  import("../Pages/MasterData/Product/ProductToCategory/ListCategory")
);

const listMasterProductToGroupCategory = lazy(() =>
  import("../Pages/MasterData/Product/ProductToCategory/ListProduct")
);

const listMasterRoute = lazy(() =>
  import("../Pages/MasterData/Transportation/Route/ListRoute")
);

const listMasterRouteDetermination = lazy(() =>
  import(
    "../Pages/MasterData/Transportation/RouteDetermination/ListRouteDetermination"
  )
);

const SyncLogisticSAP = lazy(() =>
  import("../Pages/Config/SyncDataLogistic/MasterData")
);

const ExtractData = lazy(() => import("../Pages/Config/ExtractData/Branch"));
const ExtractDataDetailError = lazy(() =>
  import("../Pages/Config/ExtractData/DetailError")
);

const listAuthUser = lazy(() => import("../Pages/Authorization/User/ListUser"));

const createAuthUser = lazy(() =>
  import("../Pages/Authorization/User/CreateUser")
);

const detailAuthUser = lazy(() =>
  import("../Pages/Authorization/User/DetailUser")
);

const editAuthUser = lazy(() => import("../Pages/Authorization/User/EditUser"));

const routesUser = [
  {
    id: "6",
    exact: true,
    path: "/quotation",
    component: ListQuotation,
  },
  {
    id: "A229",
    path: "/quotation/create",
    component: CreateQuotation,
  },
  {
    id: "A230",
    path: "/quotation/edit/:id",
    component: EditQuotation,
  },
  {
    id: "A231",
    path: "/quotation/draft/:id",
    component: DraftQuotation,
  },
  {
    id: "6",
    path: "/quotation/:id",
    component: DetailQuotation,
  },
  {
    id: "7",
    exact: true,
    path: "/sales-order",
    component: ListSalesOrder,
  },
  {
    id: "7",
    path: "/sales-order/:id",
    component: DetailSalesOrder,
  },
  {
    id: "8",
    exact: true,
    path: "/delivery-order",
    component: ListDeliveryOrder,
  },
  {
    id: "8",
    path: "/delivery-order/edit/:id",
    component: EditDeliveryOrder,
  },
  {
    id: "8",
    path: "/delivery-order/:id",
    component: DetailDeliveryOrder,
  },
  {
    id: "9",
    exact: true,
    path: "/shipment",
    component: ListShipment,
  },
  {
    id: "A242",
    path: "/shipment/create",
    component: CreateShipment,
  },
  {
    id: "A243",
    path: "/shipment/edit/:id",
    component: UpdateShipment,
  },
  {
    id: "A246",
    path: "/shipment/confirm/:id",
    component: ConfirmDeliveredItem,
  },
  {
    id: "9",
    path: "/shipment/:id",
    component: DetailShipment,
  },
  {
    id: "10",
    exact: true,
    path: "/billing",
    component: ListBilling,
  },
  {
    id: "10",
    path: "/billing/:id",
    component: DetailBilling,
  },
  {
    id: "11",
    exact: true,
    path: "/collection",
    component: ListCollection,
  },
  {
    id: "11",
    path: "/collection/:id",
    component: DetailCollection,
  },
  {
    id: "12",
    exact: true,
    path: "/undelivered",
    component: ListUndelivered,
  },
  {
    id: "12",
    path: "/undelivered/:id",
    component: DetailUndelivered,
  },
  {
    id: "5",
    exact: true,
    path: "/approval-sales-order",
    component: ListApproval,
  },
  {
    id: "5",
    path: "/approval-sales-order/:id",
    component: DetailApproval,
  },
  {
    id: "13",
    path: "/marketing-new-promo-wizard",
    component: NewPromotionWizard,
  },
  {
    id: "14",
    exact: true,
    path: "/marketing-promotion",
    component: ListPromo,
  },
  {
    id: "14",
    exact: true,
    path: "/marketing-promotion/:id",
    component: DetailPromo,
  },
  {
    id: "14",
    path: "/marketing-promotion/edit/:id",
    component: EditPromotionWizard,
  },
  {
    id: "14",
    path: "/marketing-promotion/draft/:id",
    component: DraftPromotionWizard,
  },

  {
    id: "14",
    path: "/marketing-promotion/simulation/:id",
    component: SimulatePromo,
  },
  {
    id: "25",
    exact: true,
    path: "/inventory/stock-realtime",
    component: ListStockRealTime,
  },
  {
    id: "26",
    exact: true,
    path: "/inventory/material-in-transit",
    component: ListInTransit,
  },
  {
    id: "27",
    exact: true,
    path: "/inventory/material-doc",
    component: ListMaterialDoc,
  },
  {
    id: "28",
    exact: true,
    path: "/inventory/availability-overview",
    component: ListAvailabilityOverview,
  },
  {
    id: "103",
    exact: true,
    path: "/inventory/mutasi-stock-gudang",
    component: ListMutasiStockGudang,
  },
  {
    id: "29",
    exact: true,
    path: "/gr-principal/goods-receipt",
    component: ListGRprincipal,
  },
  {
    id: "A9",
    path: "/gr-principal/goods-receipt/create",
    component: CreateGRprincipal,
  },
  {
    id: "29",
    path: "/gr-principal/goods-receipt/:id",
    component: DetailGRprincipal,
  },
  {
    id: "30",
    exact: true,
    path: "/gr-principal/gr-return",
    component: ListGRreturn,
  },
  {
    id: "A14",
    path: "/gr-principal/gr-return/create",
    component: CreateGRreturn,
  },
  {
    id: "30",
    path: "/gr-principal/gr-return/:id",
    component: DetailGRreturn,
  },
  {
    id: "31",
    exact: true,
    path: "/intra-plant/po-sto-intra-plant",
    component: ListIntraPlant_PO_STO,
  },
  {
    id: "A19",
    path: "/intra-plant/po-sto-intra-plant/create",
    component: CreateIntraPlant_PO_STO,
  },
  {
    id: "A22",
    path: "/intra-plant/po-sto-intra-plant/edit/:id",
    component: EditIntraPlant_PO_STO,
  },
  {
    id: "31",
    path: "/intra-plant/po-sto-intra-plant/:id",
    component: DetailIntraPlant_PO_STO,
  },
  {
    id: "32",
    exact: true,
    path: "/intra-plant/approval-intra-plant",
    component: ListIntraPlant_Approval,
  },
  {
    id: "32",
    path: "/intra-plant/approval-intra-plant/:id",
    component: DetailIntraPlant_Approval,
  },
  {
    id: "33",
    exact: true,
    path: "/intra-plant/do-sto",
    component: ListIntraPlant_DO_STO,
  },
  {
    id: "A31",
    path: "/intra-plant/do-sto/create",
    component: CreateIntraPlant_DO_STO,
  },
  {
    id: "A33",
    path: "/intra-plant/do-sto/edit/:id",
    component: EditIntraPlant_DO_STO,
  },
  {
    id: "33",
    path: "/intra-plant/do-sto/:id",
    component: DetailIntraPlant_DO_STO,
  },
  {
    id: "34",
    exact: true,
    path: "/intra-plant/goods-issue",
    component: ListIntraPlant_GI,
  },
  {
    id: "34",
    path: "/intra-plant/goods-issue/:id",
    component: DetailIntraPlant_GI,
  },
  {
    id: "35",
    exact: true,
    path: "/intra-plant/gr-intra-plant",
    component: ListIntraPlant_GR,
  },
  {
    id: "A43",
    path: "/intra-plant/gr-intra-plant/create",
    component: CreateIntraPlant_GR,
  },
  {
    id: "35",
    path: "/intra-plant/gr-intra-plant/:id",
    component: DetailIntraPlant_GR,
  },
  {
    id: "36",
    path: "/intra-plant/sync-gr-sap",
    component: SyncGRIntraPlant,
  },

  {
    id: "37",
    exact: true,
    path: "/intra-channel/po-sto-intra-channel",
    component: ListPO_IntraChannel,
  },
  {
    id: "A49",
    path: "/intra-channel/po-sto-intra-channel/create",
    component: CreatePO_IntraChannel,
  },
  {
    id: "37",
    path: "/intra-channel/po-sto-intra-channel/:id",
    component: DetailPO_IntraChannel,
  },
  {
    id: "38",
    exact: true,
    path: "/intra-channel/approval-intra-channel",
    component: ListApproval_IntraChannel,
  },
  {
    id: "38",
    path: "/intra-channel/approval-intra-channel/:id",
    component: DetailApproval_IntraChannel,
  },
  {
    id: "39",
    exact: true,
    path: "/intra-channel/gi-intra-channel",
    component: ListGI_IntraChannel,
  },
  {
    id: "39",
    path: "/intra-channel/gi-intra-channel/:id",
    component: DetailGI_IntraChannel,
  },
  {
    id: "40",
    exact: true,
    path: "/intra-channel/gr-intra-channel",
    component: ListGR_IntraChannel,
  },
  {
    id: "40",
    path: "/intra-channel/gr-intra-channel/:id",
    component: DetailGR_IntraChannel,
  },
  {
    id: "41",
    exact: true,
    path: "/intra-sloc/po-sto-intra-sloc",
    component: ListPO_IntraSloc,
  },
  {
    id: "A61",
    path: "/intra-sloc/po-sto-intra-sloc/create",
    component: CreatePO_IntraSloc,
  },
  {
    id: "41",
    path: "/intra-sloc/po-sto-intra-sloc/:id",
    component: DetailPO_IntraSloc,
  },
  {
    id: "42",
    exact: true,
    path: "/intra-sloc/gi-intra-sloc",
    component: ListGI_IntraSloc,
  },
  {
    id: "42",
    path: "/intra-sloc/gi-intra-sloc/:id",
    component: DetailGI_IntraSloc,
  },
  {
    id: "43",
    exact: true,
    path: "/intra-sloc/gr-intra-sloc",
    component: ListGR_IntraSloc,
  },
  {
    id: "43",
    path: "/intra-sloc/gr-intra-sloc/:id",
    component: DetailGR_IntraSloc,
  },
  {
    id: "44",
    exact: true,
    path: "/mutasi-swap-handling",
    component: ListSwapHandling,
  },
  {
    id: "A70",
    path: "/mutasi-swap-handling/create",
    component: CreateSwapHandling,
  },
  {
    id: "44",
    path: "/mutasi-swap-handling/:id",
    component: DetailSwapHandling,
  },
  {
    id: "44",
    exact: true,
    path: "/logistic-canvas/transfer-to-gs",
    component: ListCanvasToGS,
  },
  {
    id: "A70",
    path: "/logistic-canvas/transfer-to-gs/create",
    component: CreateCanvasToGS,
  },
  {
    id: "44",
    path: "/logistic-canvas/transfer-to-gs/:id",
    component: DetailCanvasToGS,
  },
  {
    id: "126",
    exact: true,
    path: "/logistic-bs/transfer-to-bs",
    component: ListAlihStatus,
  },
  {
    id: "A466",
    path: "/logistic-bs/transfer-to-bs/create",
    component: CreateAlihStatus,
  },
  {
    id: "126",
    path: "/logistic-bs/transfer-to-bs/:id",
    component: DetailAlihStatus,
  },
  {
    id: "127",
    exact: true,
    path: "/logistic-bs/bs-back-to-uu",
    component: ListBSBackToUU,
  },
  {
    id: "A472",
    path: "/logistic-bs/bs-back-to-uu/create",
    component: CreateBSBackToUU,
  },
  {
    id: "127",
    path: "/logistic-bs/bs-back-to-uu/:id",
    component: DetailBSBackToUU,
  },
  {
    id: "123",
    exact: true,
    path: "/logistic-canvas/reserve-to-transit",
    component: ListGIFromStorage,
  },
  {
    id: "A456",
    path: "/logistic-canvas/reserve-to-transit/create",
    component: CreateGIFromStorage,
  },
  {
    id: "123",
    path: "/logistic-canvas/reserve-to-transit/:id",
    component: DetailGIFromStorage,
  },
  {
    id: "124",
    exact: true,
    path: "/logistic-canvas/transit-to-uu",
    component: ListPlaceInStorage,
  },
  {
    id: "A460",
    path: "/logistic-canvas/transit-to-uu/create",
    component: CreatePlaceInStorage,
  },
  {
    id: "124",
    path: "/logistic-canvas/transit-to-uu/:id",
    component: DetailPlaceInStorage,
  },
  {
    id: "21",
    exact: true,
    path: "/stock-adjustment",
    component: ListStockOpname,
  },
  {
    id: "A86",
    path: "/stock-adjustment/create",
    component: CreateStockOpname,
  },
  {
    id: "A87",
    path: "/stock-adjustment/edit/:id",
    component: EditStockOpname,
  },
  {
    id: "21",
    path: "/stock-adjustment/:id",
    component: DetailStockOpname,
  },
  {
    id: "22",
    exact: true,
    path: "/logistic-canvas/stock-reservation",
    component: ListStockReservation,
  },
  {
    id: "A91",
    path: "/logistic-canvas/stock-reservation/create",
    component: CreateStockReservation,
  },
  {
    id: "A92",
    path: "/logistic-canvas/stock-reservation/edit/:id",
    component: EditStockReservation,
  },
  {
    id: "22",
    path: "/logistic-canvas/stock-reservation/:id",
    component: DetailStockReservation,
  },

  {
    id: "128",
    exact: true,
    path: "/logistic-bs/bs-reservation",
    component: ListBSReservation,
  },
  {
    id: "A476",
    path: "/logistic-bs/bs-reservation/create",
    component: CreateBSReservation,
  },
  {
    id: "A477",
    path: "/logistic-bs/bs-reservation/edit/:id",
    component: EditBSReservation,
  },
  {
    id: "128",
    path: "/logistic-bs/bs-reservation/:id",
    component: DetailBSReservation,
  },

  {
    id: "23",
    exact: true,
    path: "/logistic-bs/gi-disposal",
    component: ListGIDisposal,
  },
  {
    id: "A97",
    path: "/logistic-bs/gi-disposal/create",
    component: CreateGIDisposal,
  },
  {
    id: "23",
    path: "/logistic-bs/gi-disposal/:id",
    component: DetailGIDisposal,
  },
  {
    id: "24",
    exact: true,
    path: "/inventory/report-swap-handling",
    component: ListReportSwapHandling,
  },

  {
    id: "59",
    path: "/master-base/master-country",
    component: listMasterCountry,
  },

  {
    id: "60",
    path: "/master-base/master-company",
    component: listMasterCompany,
  },

  {
    id: "61",
    path: "/master-base/master-sales-org",
    component: listMasterSalesOrg,
  },

  {
    id: "62",
    path: "/master-base/master-sales-office",
    component: listMasterSalesOffice,
  },

  {
    id: "63",
    path: "/master-base/master-sales-group",
    component: listMasterSalesGroup,
  },

  {
    id: "64",
    path: "/master-base/master-channel",
    component: listMasterChannel,
  },

  {
    id: "65",
    path: "/master-base/master-customer-group-0",
    component: listMasterCustomerGroup,
  },

  {
    id: "66",
    path: "/master-base/master-customer-group-1",
    component: listMasterCustomerGroup1,
  },

  {
    id: "67",
    path: "/master-base/master-customer-group-2",
    component: listMasterCustomerGroup2,
  },

  {
    id: "68",
    path: "/master-base/master-customer-group-3",
    component: listMasterCustomerGroup3,
  },

  {
    id: "69",
    path: "/master-base/master-customer-group-4",
    component: listMasterCustomerGroup4,
  },

  {
    id: "70",
    path: "/master-base/master-customer-group-5",
    component: listMasterCustomerGroup5,
  },

  {
    id: "71",
    path: "/master-base/master-region",
    component: listMasterRegion,
  },

  {
    id: "72",
    path: "/master-base/master-district",
    component: listMasterDistrict,
  },

  {
    id: "73",
    path: "/master-base/master-branch",
    component: listMasterBranch,
  },

  {
    id: "74",
    path: "/master-base/master-term-of-payment",
    component: listMasterTermOfPayment,
  },

  {
    id: "102",
    path: "/master-base/master-price-list",
    component: listMasterPriceList,
  },

  {
    id: "108",
    path: "/master-base/master-condition-type",
    component: ListMasterConditionType,
  },

  {
    id: "104",
    path: "/master-base/master-currency",
    component: listMasterCurrency,
  },

  {
    id: "87",
    path: "/master-salesman/master-salesman-id",
    component: listMasterSalesman,
  },

  {
    id: "",
    path: "/master-salesman/master-salesman-branch",
    component: listMasterSalesmanBranch,
  },

  {
    id: "88",
    path: "/master-salesman/master-salesman-group",
    component: listMasterSalesmanGroup,
  },

  {
    id: "75",
    exact: true,
    path: "/master-customer/master-customer-id",
    component: listMasterCustomer,
  },
  {
    id: "A144",
    path: "/master-customer/master-customer-id/edit/:id",
    component: editMasterCustomer,
  },
  {
    id: "75",
    path: "/master-customer/master-customer-id/:id",
    component: detailMasterCustomer,
  },
  {
    id: "98",
    exact: true,
    path: "/master-customer/master-customer-noo",
    component: listMasterCustomerNoo,
  },
  {
    id: "A226",
    path: "/master-customer/master-customer-noo/create",
    component: createMasterCustomerNoo,
  },
  {
    id: "A101",
    path: "/master-customer/master-customer-noo/edit/:id",
    component: editMasterCustomerNoo,
  },
  {
    id: "98",
    path: "/master-customer/master-customer-noo/:id",
    component: detailMasterCustomerNoo,
  },
  {
    id: "106",
    exact: true,
    path: "/master-customer/master-customer-approval-credit-limit",
    component: listMasterApprovalCreditLimit,
  },

  {
    id: "109",
    exact: true,
    path: "/master-customer/master-rules",
    component: listMasterRules,
  },

  {
    id: "110",
    exact: true,
    path: "/master-customer/master-check-rule",
    component: listMasterCheckRule,
  },

  {
    id: "111",
    exact: true,
    path: "/master-customer/master-risk-class",
    component: listMasterRiskClass,
  },

  {
    id: "112",
    exact: true,
    path: "/master-customer/master-inco-term",
    component: listMasterIncoTerm,
  },

  {
    id: "107",
    path: "/master-pricing/master-price-group",
    component: listMasterPriceGroup,
  },
  {
    id: "94",
    exact: true,
    path: "/master-pricing/pricing-by-customer",
    component: listMasterCustomerSalesOrg,
  },
  {
    id: "94",
    path: "/master-pricing/pricing-by-customer/:id/:company/:branch/:customer",
    component: listMasterPricingByCustomer,
  },
  {
    id: "94",
    path: "/master-pricing/pricing-by-customer/:id/:company/",
    component: listMasterPricingCustomer,
  },

  {
    id: "95",
    exact: true,
    path: "/master-pricing/pricing-by-price-group",
    component: listMasterPriceGroupSalesOrg,
  },
  {
    id: "95",
    path: "/master-pricing/pricing-by-price-group/:id/:company/:pricegroup",
    component: listMasterPricingByPriceGroup,
  },
  {
    id: "95",
    path: "/master-pricing/pricing-by-price-group/:id/:company/",
    component: listMasterPricingPriceGroup,
  },

  {
    id: "79",
    exact: true,
    path: "/master-product/master-product-id",
    component: listMasterProduct,
  },

  {
    id: "360",
    path: "/master-product/master-product-id/edit/:id",
    component: editMasterProduct,
  },

  {
    id: "349",
    path: "/master-product/master-product-id/create",
    component: createMasterProduct,
  },

  {
    id: "79",
    path: "/master-product/master-product-id/:id",
    component: detailMasterProduct,
  },

  {
    id: "54",
    exact: true,
    path: "/product-grouping/:group/",
    component: listMasterGroupCategory,
  },

  {
    id: "54",
    path: "/product-grouping/:group/:id/:name",
    component: listMasterProductToGroupCategory,
  },

  {
    id: "80",
    exact: true,
    path: "/master-product/master-product-branch",
    component: listMasterProductBranch,
  },

  {
    id: "80",
    path: "/master-product/master-product-branch/:id/:company",
    component: listMasterProductToBranch,
  },

  {
    id: "77",
    path: "/master-product/master-product-categorybrand",
    component: listMasterProductCategoryBrand,
  },

  {
    id: "78",
    path: "/master-product/master-product-division",
    component: listMasterProductDivision,
  },

  {
    id: "76",
    path: "/master-product/master-product-uom",
    component: listMasterProductUom,
  },

  {
    id: "76",
    path: "/master-product/master-product-category",
    component: listMasterProductCategory,
  },

  {
    id: "76",
    path: "/master-product/master-product-brand",
    component: listMasterProductBrand,
  },

  {
    id: "76",
    path: "/master-product/master-product-principal",
    component: listMasterProductPrincipal,
  },

  {
    id: "89",
    path: "/master-transportation/master-driver",
    component: listMasterDriver,
  },

  {
    id: "90",
    path: "/master-transportation/master-vehicle-id",
    component: listMasterVehicle,
  },

  {
    id: "91",
    path: "/master-transportation/master-vehicle-type",
    component: listMasterVehicleType,
  },

  {
    id: "116",
    path: "/master-transportation/master-transportation-group",
    component: listMasterTransportationGroup,
  },

  {
    id: "115",
    path: "/master-transportation/master-shipping-condition",
    component: listMasterShippingCondition,
  },

  {
    id: "114",
    path: "/master-transportation/master-mode-of-transportation",
    component: listMasterModeOfTransportation,
  },

  {
    id: "113",
    path: "/master-transportation/master-shipping-type",
    component: listMasterShippingType,
  },

  {
    id: "92",
    path: "/master-transportation/master-transport-zone",
    component: listMasterTransportationZone,
  },

  {
    id: "93",
    path: "/master-transportation/master-route-id",
    component: listMasterRoute,
  },

  {
    id: "120",
    path: "/master-transportation/master-route-determination",
    component: listMasterRouteDetermination,
  },

  {
    id: "105",
    exact: true,
    path: "/master-transportation/master-tzone-to-branch",
    component: listMasterBranchTzone,
  },

  {
    id: "105",
    path: "/master-transportation/master-tzone-to-branch/:id/:company",
    component: listMasterTzoneToBranch,
  },

  {
    id: "100",
    path: "/sync-data-sap",
    component: SyncLogisticSAP,
  },

  {
    id: "101",
    exact: true,
    path: "/extract-data",
    component: ExtractData,
  },
  {
    id: "101",
    path: "/extract-data/:id",
    component: ExtractDataDetailError,
  },

  {
    id: "58",
    exact: true,
    path: "/auth-user",
    component: listAuthUser,
  },
  {
    id: "58",
    path: "/auth-user/create",
    component: createAuthUser,
  },
  {
    id: "58",
    path: "/auth-user/edit/:id",
    component: editAuthUser,
  },
  {
    id: "58",
    path: "/auth-user/:id",
    component: detailAuthUser,
  },
];

export default routesUser;
