export const ALLOWED_TO_ALL_MENUS = [
  {
    name: "Report",
    icon: "fas fa-chart-bar",
    path: "",
    // label: { value: 3, color: 'success' },
    submenu: [
      {
        name: "Compare Data",
        path: "/compare-data",
        submenu: [
          // {
          //   name: "Sales",
          //   path: "/sales",
          // },
          // {
          //   name: "Logistic",
          //   path: "/logistic",
          // },
          {
            name: "SO DO",
            path: "/so-do",
          },
        ],
      },
    ],
  },
];

export const Menu = [
  {
    name: "Master Data",
    icon: "fas fa-database",
    path: "",
    // label: { value: 3, color: 'success' },
    submenu: [
      {
        name: "Base Master",
        path: "/master-base",
        submenu: [
          {
            name: "Country",
            path: "/master-country",
          },
          {
            name: "Company",
            path: "/master-company",
          },
          {
            name: "Sales Organization",
            path: "/master-sales-org",
          },
          {
            name: "Sales Office",
            path: "/master-sales-office",
          },
          {
            name: "Sales Group",
            path: "/master-sales-group",
          },
          {
            name: "Channel",
            path: "/master-channel",
          },
          {
            name: "Customer Group",
            path: "/master-customer-group-0",
          },
          {
            name: "Customer Group 1",
            path: "/master-customer-group-1",
          },
          {
            name: "Customer Group 2",
            path: "/master-customer-group-2",
          },
          {
            name: "Customer Group 3",
            path: "/master-customer-group-3",
          },
          {
            name: "Customer Group 4",
            path: "/master-customer-group-4",
          },
          {
            name: "Customer Group 5",
            path: "/master-customer-group-5",
          },
          {
            name: "Region",
            path: "/master-region",
          },
          {
            name: "District",
            path: "/master-district",
          },
          {
            name: "Branch",
            path: "/master-branch",
          },
          {
            name: "Term of Payment",
            path: "/master-term-of-payment",
          },
          {
            name: "Price List",
            path: "/master-price-list",
          },
          {
            name: "Currency",
            path: "/master-currency",
          },
          {
            name: "Condition Type",
            path: "/master-condition-type",
          },
        ],
      },
      {
        name: "Customer",
        path: "/master-customer",
        submenu: [
          {
            name: "Customer",
            path: "/master-customer-id",
          },
          {
            name: "Customer NOO",
            path: "/master-customer-noo",
          },
          {
            name: "Approval Credit Limit",
            path: "/master-customer-approval-credit-limit",
          },
          {
            name: "Rules",
            path: "/master-rules",
          },
          {
            name: "Check Rule",
            path: "/master-check-rule",
          },
          {
            name: "Risk Class",
            path: "/master-risk-class",
          },
          {
            name: "Inco Term",
            path: "/master-inco-term",
          },
        ],
      },
      {
        name: "Product",
        path: "/master-product",
        submenu: [
          {
            name: "UoM",
            path: "/master-product-uom",
          },
          {
            name: "Category",
            path: "/master-product-category",
          },

          {
            name: "Brand",
            path: "/master-product-brand",
          },

          {
            name: "Principal",
            path: "/master-product-principal",
          },
          {
            name: "Division",
            path: "/master-product-division",
          },
          {
            name: "Product",
            path: "/master-product-id",
          },
          {
            name: "Product Branch",
            path: "/master-product-branch",
          },
        ],
      },
      {
        name: "Product Grouping",
        path: "/product-grouping",
        submenu: [
          {
            name: "By Principal",
            path: "/product-by-principal",
          },
          {
            name: "By Brand",
            path: "/product-by-brand",
          },
          {
            name: "By Category",
            path: "/product-by-category",
          },
          {
            name: "NPL",
            path: "/product-by-npl",
          },
          {
            name: "By Retail Price",
            path: "/product-by-retail-price",
          },
          {
            name: "By Weight",
            path: "/product-by-gramation",
          },
          {
            name: "By Division",
            path: "/product-by-division",
          },
          {
            name: "By Others",
            path: "/product-by-others",
          },
        ],
      },
      {
        name: "Salesman",
        path: "/master-salesman",
        submenu: [
          {
            name: "Salesman ID",
            path: "/master-salesman-id",
          },
          {
            name: "Salesman Group",
            path: "/master-salesman-group",
          },
        ],
      },
      {
        name: "Transportation",
        path: "/master-transportation",
        submenu: [
          {
            name: "Driver",
            path: "/master-driver",
          },
          {
            name: "Vehicle",
            path: "/master-vehicle-id",
          },
          {
            name: "Vehicle Type",
            path: "/master-vehicle-type",
          },
          {
            name: "Shipping Type",
            path: "/master-shipping-type",
          },
          {
            name: "Mode of Transportation",
            path: "/master-mode-of-transportation",
          },

          {
            name: "Shipping Condition",
            path: "/master-shipping-condition",
          },
          {
            name: "Transportation Group",
            path: "/master-transportation-group",
          },
          {
            name: "Transportation Zone",
            path: "/master-transport-zone",
          },

          {
            name: "Route",
            path: "/master-route-id",
          },
          {
            name: "Route Determination",
            path: "/master-route-determination",
          },
          {
            name: "T. Zone to Branch",
            path: "/master-tzone-to-branch",
          },
        ],
      },
      {
        name: "Pricing",
        path: "/master-pricing",
        submenu: [
          {
            name: "Price Group",
            path: "/master-price-group",
          },
          {
            name: "By Customer",
            path: "/pricing-by-customer",
          },
          {
            name: "By Price Group",
            path: "/pricing-by-price-group",
          },
        ],
      },
    ],
  },
  ...ALLOWED_TO_ALL_MENUS,
  // {
  //   name: "Report",
  //   icon: "fas fa-chart-bar",
  //   path: "",
  //   // label: { value: 3, color: 'success' },
  //   submenu: [
  //     {
  //       name: "Compare Data",
  //       path: "/compare-data",
  //       submenu: [
  //         {
  //           name: "Sales",
  //           path: "/sales",
  //         },
  //         {
  //           name: "Logistic",
  //           path: "/logistic",
  //         },
  //         {
  //           name: "SO DO",
  //           path: "/so-do",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Config",
    icon: "fas fa-cog",
    path: "",
    // label: { value: 3, color: 'success' },
    submenu: [
      {
        name: "Company",
        path: "/config-company",
      },

      {
        name: "SLoc",
        path: "/config-sloc-master",
      },

      {
        name: "SLoc Company",
        path: "/config-sloc-company",
      },

      {
        name: "SLoc Customer Group",
        path: "/config-sloc-customergroup",
      },

      {
        name: "SLoc Salesman",
        path: "/config-sloc-salesman",
      },

      {
        name: "Auto So to DO",
        path: "/config-auto-so-to-do",
      },

      {
        name: "Batch Product",
        path: "/config-batch-product",
      },

      {
        name: "Sales Order Block",
        path: "/so-block",
        submenu: [
          {
            name: "Sales Organization, Customer Group, Material",
            path: "/so-block-sales-order-product",
          },
          {
            name: "Sales Organization, Channel, Customer Group, Salesman Group",
            path: "/so-block-sales-order-channel",
          },
          {
            name: "Sales Organization, Customer",
            path: "/so-block-sales-order-customer",
          },
        ],
      },

      {
        name: "Tax Regulator",
        path: "/config-tax-regulator",
      },

      {
        name: "Mapping Item Category",
        path: "/config-mapping-item-category",
      },
      {
        name: "Branch Location",
        path: "/config-branch-location",
      },
      {
        name: "Mapping Product Intra",
        path: "/config-mapping-product-intra",
      },

      {
        name: "Order Type to Sloc",
        path: "/config-order-type-to-sloc",
      },

      // {
      //   name: "Order Type to Nadine",
      //   path: "/config-order-type-to-nadine",
      // },

      // {
      //   name: "Document Print",
      //   path: "/config-document-print",
      // },
      {
        name: "Check Inconsistency",
        path: "/check-inconsistency",
      },
      {
        name: "Sync Data",
        path: "/sync-data-sap",
      },
      {
        name: "Sync SAP Schedule",
        path: "/schedule-sync-sap",
      },
      {
        name: "Extract Data",
        path: "/extract-data",
      },
      {
        name: "Monitoring Upload SAP",
        path: "/monitoring-upload-sap",
      },
      // {
      //   name: "Password Extract Data",
      //   path: "/password-extract",
      // },
    ],
  },
  {
    name: "Authorization",
    icon: "fas fa-user",
    path: "",
    // label: { value: 3, color: 'success' },
    submenu: [
      {
        name: "Users",
        path: "/auth-user",
      },
      {
        name: "Request Reset Password",
        path: "/auth-reset-password",
      },
    ],
  },
];

export const listMenuData = [
  {
    id: 1,
    name: "Dashboard",
    icon: "icon-speedometer",
    path: "/dashboard",
  },
  {
    id: 2,
    name: "Sales Module",
    icon: "icon-note",
    path: "",
  },
  {
    id: 6,
    name: "Quotation",
    icon: "",
    path: "/quotation",
  },
  {
    id: 7,
    name: "Sales Order",
    icon: "",
    path: "/sales-order",
  },
  {
    id: 8,
    name: "Delivery Order",
    icon: "",
    path: "/delivery-order",
  },
  {
    id: 9,
    name: "Shipment",
    icon: "",
    path: "/shipment",
  },
  {
    id: 10,
    name: "Billing",
    icon: "",
    path: "/billing",
  },
  {
    id: 11,
    name: "Collection",
    icon: "",
    path: "/collection",
  },
  {
    id: 12,
    name: "Undelivered",
    icon: "",
    path: "/undelivered",
  },
  {
    id: 5,
    name: "Approval",
    icon: "",
    path: "/approval-sales-order",
  },
  {
    id: 3,
    name: "Marketing",
    icon: "fas fa-percentage",
    path: "",
  },
  {
    id: 13,
    name: "New Promotion Wizard",
    icon: "",
    path: "/marketing-new-promo-wizard",
  },
  {
    id: 14,
    name: "Promotion",
    icon: "",
    path: "/marketing-promotion",
  },

  {
    id: 4,
    name: "Logistic Module",
    icon: "fa fa-truck-moving",
    path: "",
  },
  { id: 15, name: "Inventory", icon: "", path: "/inventory" },
  {
    id: 25,
    name: "Stock Realtime",
    icon: "",
    path: "/stock-realtime",
  },
  {
    id: 26,
    name: "Material In Transit",
    icon: "",
    path: "/material-in-transit",
  },
  {
    id: 27,
    name: "Material Doc",
    icon: "",
    path: "/material-doc",
  },
  {
    id: 103,
    name: "Mutasi Stock Gudang Harian",
    icon: "",
    path: "/mutasi-stock-gudang",
  },
  {
    id: 28,
    name: "Stock Availability Overview",
    icon: "",
    path: "/availability-overview",
  },
  {
    id: 24,
    name: "List Swap Handling",
    icon: "",
    path: "/report-swap-handling",
  },

  {
    id: 16,
    name: "GR From Principal",
    icon: "",
    path: "/gr-principal",
  },
  {
    id: 29,
    name: "Goods Receipt",
    icon: "",
    path: "/goods-receipt",
  },
  {
    id: 30,
    name: "GR Return",
    icon: "",
    path: "/gr-return",
  },

  {
    id: 17,
    name: "Intra Plant",
    icon: "",
    path: "/intra-plant",
  },
  {
    id: 31,
    name: "PO STO",
    icon: "",
    path: "/po-sto-intra-plant",
  },
  {
    id: 32,
    name: "Approval",
    icon: "",
    path: "/approval-intra-plant",
  },
  {
    id: 33,
    name: "DO STO",
    icon: "",
    path: "/do-sto",
  },
  {
    id: 34,
    name: "Goods Issue",
    icon: "",
    path: "/goods-issue",
  },
  {
    id: 35,
    name: "Goods Receipt",
    icon: "",
    path: "/gr-intra-plant",
  },
  {
    id: 36,
    name: "Sync GR from SAP",
    icon: "",
    path: "/sync-gr-sap",
  },

  {
    id: 18,
    name: "Intra Channel",
    icon: "",
    path: "/intra-channel",
  },
  {
    id: 37,
    name: "Request",
    icon: "",
    path: "/po-sto-intra-channel",
  },
  {
    id: 38,
    name: "Approval",
    icon: "",
    path: "/approval-intra-channel",
  },
  {
    id: 39,
    name: "Goods Issue",
    icon: "",
    path: "/gi-intra-channel",
  },
  {
    id: 40,
    name: "Goods Receipt",
    icon: "",
    path: "/gr-intra-channel",
  },

  {
    id: 19,
    name: "Intra Sloc",
    icon: "",
    path: "/intra-sloc",
  },
  {
    id: 41,
    name: "Request",
    icon: "",
    path: "/po-sto-intra-sloc",
  },
  {
    id: 42,
    name: "Goods Issue",
    icon: "",
    path: "/gi-intra-sloc",
  },
  {
    id: 43,
    name: "Goods Receipt",
    icon: "",
    path: "/gr-intra-sloc",
  },

  {
    id: 20,
    name: "Stock Mutasi",
    icon: "",
    path: "/stock-mutasi",
  },
  {
    id: 44,
    name: "Swap Handling",
    icon: "",
    path: "/mutasi-swap-handling",
  },
  {
    id: 125,
    name: "Transfer to GS",
    icon: "",
    path: "/transfer-to-gs",
  },
  {
    id: 126,
    name: "Transfer to BS",
    icon: "",
    path: "/transfer-to-bs",
  },
  {
    id: 127,
    name: "BS Back to UU",
    icon: "",
    path: "/bs-back-to-uu",
  },
  {
    id: 123,
    name: "Reserve to Transit",
    icon: "",
    path: "/reserve-to-transit",
  },
  {
    id: 124,
    name: "Transit to UU",
    icon: "",
    path: "/transit-to-uu",
  },

  {
    id: 21,
    name: "Stock Adjustment",
    icon: "",
    path: "/stock-adjustment",
  },
  {
    id: 121,
    name: "Canvas",
    icon: "",
    path: "/logistic-canvas",
  },
  {
    id: 22,
    name: "Stock Reservation",
    icon: "",
    path: "/stock-reservation",
  },
  {
    id: 122,
    name: "Bad Stock",
    icon: "",
    path: "/logistic-bs",
  },
  {
    id: 128,
    name: "BS Reservation",
    icon: "",
    path: "/bs-reservation",
  },
  {
    id: 23,
    name: "GI Disposal",
    icon: "",
    path: "/gi-disposal",
  },
  {
    id: 49,
    name: "Master Data",
    icon: "fas fa-database",
    path: "",
  },
  {
    id: 51,
    name: "Base Master",
    icon: "",
    path: "/master-base",
  },
  {
    id: 59,
    name: "Country",
    icon: "",
    path: "/master-country",
  },
  {
    id: 60,
    name: "Company",
    icon: "",
    path: "/master-company",
  },
  {
    id: 61,
    name: "Sales Organization",
    icon: "",
    path: "/master-sales-org",
  },
  {
    id: 62,
    name: "Sales Office",
    icon: "",
    path: "/master-sales-office",
  },
  {
    id: 63,
    name: "Sales Group",
    icon: "",
    path: "/master-sales-group",
  },
  {
    id: 64,
    name: "Channel",
    icon: "",
    path: "/master-channel",
  },
  {
    id: 65,
    name: "Customer Group",
    icon: "",
    path: "/master-customer-group-0",
  },
  {
    id: 66,
    name: "Customer Group 1",
    icon: "",
    path: "/master-customer-group-1",
  },
  {
    id: 67,
    name: "Customer Group 2",
    icon: "",
    path: "/master-customer-group-2",
  },
  {
    id: 68,
    name: "Customer Group 3",
    icon: "",
    path: "/master-customer-group-3",
  },
  {
    id: 69,
    name: "Customer Group 4",
    icon: "",
    path: "/master-customer-group-4",
  },
  {
    id: 70,
    name: "Customer Group 5",
    icon: "",
    path: "/master-customer-group-5",
  },
  {
    id: 71,
    name: "Region",
    icon: "",
    path: "/master-region",
  },
  {
    id: 72,
    name: "District",
    icon: "",
    path: "/master-district",
  },
  {
    id: 73,
    name: "Branch",
    icon: "",
    path: "/master-branch",
  },
  {
    id: 74,
    name: "Term of Payment",
    icon: "",
    path: "/master-term-of-payment",
  },
  {
    id: 102,
    name: "Price List",
    icon: "",
    path: "/master-price-list",
  },
  {
    id: 104,
    name: "Currency",
    icon: "",
    path: "/master-currency",
  },
  {
    id: 108,
    name: "Condition Type",
    icon: "",
    path: "/master-condition-type",
  },
  {
    id: 52,
    name: "Customer",
    icon: "",
    path: "/master-customer",
  },
  {
    id: 75,
    name: "Customer",
    icon: "",
    path: "/master-customer-id",
  },
  {
    id: 98,
    name: "Customer NOO",
    icon: "",
    path: "/master-customer-noo",
  },
  {
    id: 106,
    name: "Approval Credit Limit",
    icon: "",
    path: "/master-customer-approval-credit-limit",
  },
  {
    id: 109,
    name: "Rules",
    icon: "",
    path: "/master-rules",
  },
  {
    id: 110,
    name: "Check Rule",
    icon: "",
    path: "/master-check-rule",
  },
  {
    id: 111,
    name: "Risk Class",
    icon: "",
    path: "/master-risk-class",
  },
  {
    id: 112,
    name: "Inco Term",
    icon: "",
    path: "/master-inco-term",
  },
  {
    id: 53,
    name: "Product",
    icon: "",
    path: "/master-product",
  },
  {
    id: 76,
    name: "UoM",
    icon: "",
    path: "/master-product-uom",
  },
  {
    id: 117,
    name: "Category",
    icon: "",
    path: "/master-product-category",
  },

  {
    id: 118,
    name: "Brand",
    icon: "",
    path: "/master-product-brand",
  },

  {
    id: 119,
    name: "Principal",
    icon: "",
    path: "/master-product-principal",
  },
  {
    id: 78,
    name: "Division",
    icon: "",
    path: "/master-product-division",
  },
  {
    id: 79,
    name: "Product",
    icon: "",
    path: "/master-product-id",
  },
  {
    id: 80,
    name: "Product Branch",
    icon: "",
    path: "/master-product-branch",
  },

  {
    id: 54,
    name: "Product Grouping",
    icon: "",
    path: "/product-grouping",
  },
  {
    id: 81,
    name: "By Principal",
    icon: "",
    path: "/product-by-principal",
  },
  {
    id: 82,
    name: "By Brand",
    icon: "",
    path: "/product-by-brand",
  },
  {
    id: 83,
    name: "By Category",
    icon: "",
    path: "/product-by-category",
  },
  {
    id: 84,
    name: "By NPL",
    icon: "",
    path: "/product-by-npl",
  },
  {
    id: 85,
    name: "By Retail Price",
    icon: "",
    path: "/product-by-retail-price",
  },
  {
    id: 86,
    name: "By Weight",
    icon: "",
    path: "/product-by-gramation",
  },
  {
    id: 96,
    name: "By Division",
    icon: "",
    path: "/product-by-division",
  },
  {
    id: 97,
    name: "By Others",
    icon: "",
    path: "/product-by-others",
  },

  {
    id: 55,
    name: "Salesman",
    icon: "",
    path: "/master-salesman",
  },
  {
    id: 87,
    name: "Salesman ID",
    icon: "",
    path: "/master-salesman-id",
  },
  {
    id: 88,
    name: "Salesman Group",
    icon: "",
    path: "/master-salesman-group",
  },

  {
    id: 56,
    name: "Transportation",
    icon: "",
    path: "/master-transportation",
  },
  {
    id: 89,
    name: "Driver",
    icon: "",
    path: "/master-driver",
  },
  {
    id: 90,
    name: "Vehicle",
    icon: "",
    path: "/master-vehicle-id",
  },
  {
    id: 91,
    name: "Vehicle Type",
    icon: "",
    path: "/master-vehicle-type",
  },
  {
    id: 113,
    name: "Shipping Type",
    icon: "",
    path: "/master-shipping-type",
  },
  {
    id: 114,
    name: "Mode of Transportation",
    icon: "",
    path: "/master-mode-of-transportation",
  },
  {
    id: 115,
    name: "Shipping Condition",
    icon: "",
    path: "/master-shipping-condition",
  },

  {
    id: 116,
    name: "Transportation Group",
    icon: "",
    path: "/master-transportation-group",
  },
  {
    id: 93,
    name: "Route",
    icon: "",
    path: "/master-route-id",
  },
  {
    id: 120,
    name: "Route Determination",
    icon: "",
    path: "/master-route-determination",
  },
  {
    id: 92,
    name: "Transportation Zone",
    icon: "",
    path: "/master-transport-zone",
  },
  {
    id: 105,
    name: "T. Zone to branch ",
    icon: "",
    path: "/master-tzone-to-branch",
  },

  {
    id: 57,
    name: "Pricing",
    icon: "",
    path: "/master-pricing",
  },
  {
    id: 107,
    name: "Price Group",
    icon: "",
    path: "/master-price-group",
  },
  {
    id: 94,
    name: "By Customer",
    icon: "",
    path: "/pricing-by-customer",
  },
  {
    id: 95,
    name: "By Price Group",
    icon: "",
    path: "/pricing-by-price-group",
  },
  {
    id: 99,
    name: "Config",
    icon: "fas fa-cog",
    path: "",
  },
  {
    id: 100,
    name: "Sync Data",
    icon: "",
    path: "/sync-data-sap",
  },
  {
    id: 101,
    name: "Extract Data",
    icon: "",
    path: "/extract-data",
  },
  {
    id: 50,
    name: "Authorization",
    icon: "fas fa-user",
    path: "",
  },
  {
    id: 58,
    name: "Users",
    icon: "",
    path: "/auth-user",
  },
  // {
  //   id: 129,
  //   name: "Report",
  //   icon: "fas fa-chart-bar",
  //   path: "",
  // },
  // {
  //   id: 130,
  //   name: "Compare",
  //   icon: "",
  //   path: "",
  // },
  // {
  //   id: 131,
  //   name: "Sales",
  //   icon: "",
  //   path: "/sales",
  // },
  // {
  //   id: 132,
  //   name: "Logistic",
  //   icon: "",
  //   path: "/logistic",
  // },
  // {
  //   id: 133,
  //   name: "SO DO",
  //   icon: "",
  //   path: "/so-do",
  // },
];

export default Menu;
