import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import routesUser from "./RouteListUser";
import routesIT from "./RouteListIT";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";

/* loader component for Suspense*/
import PageLoader from "../components/Common/PageLoader";

import Base from "../components/Layout/Base";
import BasePage from "../components/Layout/BasePage";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

import QzPrinterContainer from "components/QzPrinterContainer";

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const DashboardV1 = lazy(() => import("../components/Dashboard/DashboardV1"));
const Profile = lazy(() => import("../Pages/Authorization/Profile/DetailUser"));

const Login = lazy(() => import("../Pages/Login/Login"));
const ResetPassword = lazy(() => import("../Pages/ResetPassword"));
const NotFound = lazy(() => import("../Pages/Notfound/NotFound"));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = ["/", "/login", "/reset-password", "/notfound"];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  let userData = false;
  let isIT = false;
  let permissionAccessData = "";

  userData = Cookies.get("userData");
  if (userData) {
    userData = JSON.parse(userData);
    isIT = userData.role === 1 || userData.role === 2 || userData.role === 3;
    const getPermissionAccessData = Cookies.get("pad");
    if (getPermissionAccessData) {
      permissionAccessData = JSON.parse(getPermissionAccessData).split(",");
    }
  }
  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";
  if (location.pathname === "/") {
    window.location.href = "/login";
  } else if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/reset-password" component={waitFor(ResetPassword)} />
            <Route path="/notfound" component={waitFor(NotFound)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <ToastContainer />
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <QzPrinterContainer />
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <Route path="/dashboard" component={waitFor(DashboardV1)} />
                  <Route path="/profile" component={waitFor(Profile)} />
                  {routesUser &&
                    routesUser.map((item, index) => {
                      let isRenderRouteUser = true;
                      if (isIT) isRenderRouteUser = true;
                      else if (userData && permissionAccessData) {
                        if (permissionAccessData.includes(item.id)) {
                          isRenderRouteUser = true;
                        } else {
                          isRenderRouteUser = false;
                        }
                      }
                      return isRenderRouteUser ? (
                        <Route
                          key={"menu" + index}
                          exact={item && item.exact}
                          path={item.path}
                          component={waitFor(item.component)}
                        />
                      ) : (
                        <Route
                          key={"menu" + index}
                          exact={item && item.exact}
                          path={item.path}
                          component={waitFor(NotFound)}
                        />
                      );
                    })}

                  {userData &&
                    isIT &&
                    routesIT.map((item, index) => {
                      let isRender = false;
                      if (isIT) isRender = true;

                      return (
                        isRender && (
                          <Route
                            key={"menu" + index}
                            exact={item && item.exact}
                            path={item.path}
                            component={waitFor(item.component)}
                          />
                        )
                      );
                    })}

                  <Redirect to="/notfound" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
